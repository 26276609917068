import React, { useEffect } from "react";
import CreditForm from "../components/organisms/credits/CreditForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreditInfo } from "../redux/slices/creditSlice";
import { Link, useNavigate } from "react-router-dom";
import Icons from "../assets/icons";

const BuyCredits = () => {
  const { creditInfo, loading, error } = useSelector((state) => state.credit);
  const { userDetail } = useSelector((state) => state.userDetail);
  const isActiveSubscription = userDetail?.data?.isActiveSubscription;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePurchaseClick = () => {
    // Navigate to the billing page
    navigate("/billing");
  };

  useEffect(() => {
    dispatch(fetchCreditInfo());
  }, []);

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb]">
      <h3 className="font-bold text-xl text-text-color mb-2">Buy Credits</h3>
      <p className="font-normal text-base text-dark-green">
        Our pricing is the most competitive on the market.
      </p>
      {isActiveSubscription ? (
        <div className="mt-8">
          <CreditForm creditInfo={creditInfo} loading={loading} />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-[60vh]">
          <div className="mb-4">
            <Icons.CompanyInvite />
          </div>
          <p className="text-lg text-[#313D31] text-center mb-6 px-[18rem]">
            You don't have an active subscription. Start enjoying premium
            features by purchasing a subscription.
          </p>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded"
            onClick={handlePurchaseClick}
          >
            Purchase subscription
          </button>
        </div>
      )}
    </div>
  );
};

export default BuyCredits;
