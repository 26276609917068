import React from "react";
import animationData from "../../assets/animations/Animation - 1729600138499.json";
import Lottie from "lottie-react";

const LoaderAnimation = () => {
  return (
    <div>
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: 35, width: 35 }}
      />
    </div>
  );
};

export default LoaderAnimation;
