import React, { useState } from "react";
import FormLabel from "../components/atoms/FormLabel";
import { Input } from "../components/atoms/input";
import { Button } from "../components/atoms/buttons";
import { useParams } from "react-router-dom";
import { POST } from "../services/apiServices";
import toast from "react-hot-toast";

const CreateCustomePurchase = () => {
  const { id: customerId } = useParams();
  const [price, setPrice] = useState("");
  const [credits, setCredits] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateCustomPaymentLink = async () => {
    // Clear previous error
    setError("");

    // Validation
    if (!price || isNaN(price) || price <= 0) {
      setError("Please enter a valid price greater than 0.");
      return;
    }

    if (!credits || isNaN(credits) || credits <= 0) {
      setError("Please enter a valid number of credits greater than 0.");
      return;
    }

    // Prepare API payload
    const payload = {
      customerId: parseInt(customerId),
      price: parseFloat(price),
      credits: parseInt(credits, 10),
    };
    setIsLoading(true);
    try {
      const response = await POST("/payment/create/custom-link", payload);

      setPrice("");
      setCredits("");
      toast.success("Successfully created custom payment link");
    } catch (err) {
      console.error("Error creating custom purchase link:", err);
      setError("An error occurred while creating the purchase link.");
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold leading-7">
          Create custom purchase link
        </h1>
      </div>
      <form
        className="max-w-4xl"
        onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission
          handleCreateCustomPaymentLink();
        }}
      >
        <div className="flex gap-6 mt-4">
          <div className="w-full">
            <FormLabel htmlFor="price">Price in dollar</FormLabel>
            <Input
              type="number"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="$480"
              className="mb-5 !w-full"
            />
          </div>
          <div className="w-full">
            <FormLabel htmlFor="credits">No. of credits</FormLabel>
            <Input
              type="number"
              id="credits"
              value={credits}
              onChange={(e) => setCredits(e.target.value)}
              placeholder="900"
              className="mb-5 !w-full"
            />
          </div>
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <div>
          <Button
            type="submit"
            className={`bg-green-500 hover:bg-green-600 text-xl font-medium text-white px-10 py-2 rounded-md mt-5 ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? "Creating..." : "Create custom purchase link"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateCustomePurchase;
