import { useDispatch, useSelector } from "react-redux";
import CustomTable from "./Table";
import { useEffect } from "react";
import { fetchCompanyUser } from "../../redux/slices/getCompanyUserSlice";
import TableSkeleton from "./TableSkeleton";

export default function CompanyUser() {
  const dispatch = useDispatch();
  const { getCompanyUser, loading, error } = useSelector(
    (state) => state.getCompanyUser
  );

  const companyUser = getCompanyUser?.data?.customers || [];
  console.log("companyUser", companyUser);

  useEffect(() => {
    dispatch(fetchCompanyUser());
  }, []);

  const headers = ["#", "Name", "Email", "Permissions"];

  const data = companyUser?.map((search, index) => [
    index + 1,
    search?.name,
    search?.email,
    <div>
      Admin Permissions
      <span className="ml-2 text-lime-green font-bold text-sm cursor-pointer">
        Change to Standard
      </span>
    </div>,
  ]);

  if (error) {
    return <div>Error: {error}</div>; // Error state
  }

  return (
    <div className=" bg-[#fbfdfb] min-h-screen">
      <div className="">
        <div class="text-[#1d201d] text-xl font-bold my-3 leading-7">
          Your company’s users
        </div>
      </div>
      {loading ? (
        <div className="px-4">
          <TableSkeleton rows={10} columns={6} header={false} />
        </div>
      ) : data?.length === 0 ? (
        <div className="flex justify-center items-center h-[50vh]">
          <p className="text-2xl text-gray-500">No data found</p>
        </div>
      ) : (
        <CustomTable headers={headers} data={data} length={data?.length} />
      )}
    </div>
  );
}
