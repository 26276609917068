import React from "react";
import Icons from "../../assets/icons";
import CustomSelect from "../atoms/CustomSelect";

const FilterItem = ({
  label,
  options,
  placeholder,
  excludeLabel,
  excludeField,
  setOpenExcludeField,
  openExcludeField,
  onChange,
  onExcludeChange,
  value,
  excludeValue,
}) => {
  return (
    <div className="mb-4">
      <div className="mb-2">
        <label className="block text-sm font-medium text-[#1D201D]">
          {label}
        </label>
        <CustomSelect
          options={options}
          isMultiple={true}
          placeholder={placeholder}
          value={value}
          onChange={(selected) => onChange(selected)}
        />
      </div>
      {excludeLabel && (
        <div
          className="text-[#50c878] text-[0.8rem] font-medium flex items-center gap-2 leading-none cursor-pointer"
          onClick={() =>
            setOpenExcludeField(
              openExcludeField === excludeField ? null : excludeField
            )
          }
        >
          Exclude {excludeLabel} <Icons.DownArrow color="#50c878" size="13" />
        </div>
      )}
      {openExcludeField === excludeField && excludeField && (
        <div className="mt-2">
          <label className="block text-sm font-medium text-[#1D201D]">
            {excludeLabel}
          </label>
          <CustomSelect
            options={options}
            isMultiple={true}
            value={excludeValue}
            placeholder={`Exclude specific ${excludeLabel}...`}
            onChange={(selected) => onExcludeChange(selected)} // You can handle exclusions here similarly
          />
        </div>
      )}
    </div>
  );
};
export default FilterItem;
