export const IndustriesList = [
  {
    label: "Metal Mining",
  },
  {
    label: "Coal Mining",
  },
  {
    label: "Oil and Gas Extraction",
  },
  {
    label: "Mining and Quarrying of Nonmetallic Minerals: except Fuels",
  },
  {
    label: "Building Construction-general Contractors and Operative Builders",
  },
  {
    label: "Heavy Construction Other Than Building Construction-contractors",
  },
  {
    label: "Construction-special Trade Contractors",
  },
  {
    label: "Food and Kindred Products",
  },
  {
    label: "Tobacco Products",
  },
  {
    label: "Textile Mill Products",
  },
  {
    label:
      "Apparel and Other Finished Products Made From Fabrics and Similar Material",
  },
  {
    label: "Lumber and Wood Products: except Furniture",
  },
  {
    label: "Furniture and Fixtures",
  },
  {
    label: "Paper and Allied Products",
  },
  {
    label: "Printing: Publishing: and Allied Industries",
  },
  {
    label: "Chemicals and Allied Products",
  },
  {
    label: "Petroleum Refining and Related Industries",
  },
  {
    label: "Rubber and Miscellaneous Plastics Products",
  },
  {
    label: "Leather and Leather Products",
  },
  {
    label: "Stone: Clay: Glass: and Concrete Products",
  },
  {
    label: "Primary Metal Industries",
  },
  {
    label:
      "Fabricated Metal Products: except Machinery and Transportation Equipment",
  },
  {
    label: "Industrial and Commercial Machinery and Computer Equipment",
  },
  {
    label:
      "Electronic and Other Electrical Equipment and Components: except Computer",
  },
  {
    label: "Transportation Equipment",
  },
  {
    label:
      "Measuring: Analyzing and Controlling Instruments; Photographic: Medical An",
  },
  {
    label: "Miscellaneous Manufacturing Industries",
  },
  {
    label: "Railroad Transportation",
  },
  {
    label:
      "Local and Suburban Transit and Interurban Highway Passenger Transportation",
  },
  {
    label: "Motor Freight Transportation and Warehousing",
  },
  {
    label: "United States Postal Service",
  },
  {
    label: "Water Transportation",
  },
  {
    label: "Transportation By Air",
  },
  {
    label: "Pipelines: except Natural Gas",
  },
  {
    label: "Transportation Services",
  },
  {
    label: "Communications",
  },
  {
    label: "Electric: Gas: and Sanitary Services",
  },
  {
    label: "Wholesale Trade&die;durable Goods",
  },
  {
    label: "Wholesale Trade&die;nondurable Goods",
  },
  {
    label:
      "Building Materials: Hardware: Garden Supply: and Mobile Home Dealers",
  },
  {
    label: "General Merchandise Stores",
  },
  {
    label: "Food Stores",
  },
  {
    label: "Automotive Dealers and Gasoline Service Stations",
  },
  {
    label: "Apparel and Accessory Stores",
  },
  {
    label: "Home Furniture: Furnishings: and Equipment Stores",
  },
  {
    label: "Eating and Drinking Places",
  },
  {
    label: "Miscellaneous Retail",
  },
  {
    label: "Depository Institutions",
  },
  {
    label: "Nondepository Credit Institutions",
  },
  {
    label: "Security and Commodity Brokers: Dealers: Exchanges: and Services",
  },
  {
    label: "Insurance Carriers",
  },
  {
    label: "Insurance Agents: Brokers: and Service",
  },
  {
    label: "Real Estate",
  },
  {
    label: "Holding and Other Investment Offices",
  },
  {
    label: "Hotels: Rooming Houses: Camps: and Other Lodging Places",
  },
  {
    label: "Personal Services",
  },
  {
    label: "Business Services",
  },
  {
    label: "Automotive Repair: Services: and Parking",
  },
  {
    label: "Miscellaneous Repair Services",
  },
  {
    label: "Motion Pictures",
  },
  {
    label: "Amusement and Recreation Services",
  },
  {
    label: "Health Services",
  },
  {
    label: "Legal Services",
  },
  {
    label: "Educational Services",
  },
  {
    label: "Social Services",
  },
  {
    label: "Museums: Art Galleries: and Botanical and Zoological Gardens",
  },
  {
    label: "Membership Organizations",
  },
  {
    label:
      "Engineering: Accounting: Research: Management: and Related Services",
  },
  {
    label: "Private Households",
  },
  {
    label: "Services: Not Elsewhere Classified",
  },
  {
    label: "Executive: Legislative: and General Government: except Finance",
  },
  {
    label: "Justice: Public Order: and Safety",
  },
  {
    label: "Public Finance: Taxation: and Monetary Policy",
  },
  {
    label: "Administration of Human Resource Programs",
  },
  {
    label: "Administration of Environmental Quality and Housing Programs",
  },
  {
    label: "Administration of Economic Programs",
  },
  {
    label: "National Security and International Affairs",
  },
  {
    label: "Nonclassifiable Establishments",
  },
  {
    label: "Iron Ores",
  },
  {
    label: "Copper Ores",
  },
  {
    label: "Lead and Zinc Ores",
  },
  {
    label: "Gold and Silver Ores",
  },
  {
    label: "Ferroalloy Ores: except Vanadium",
  },
  {
    label: "Metal Mining Services",
  },
  {
    label: "Miscellaneous Metal Ores",
  },
  {
    label: "Bituminous Coal and Lignite Mining",
  },
  {
    label: "Anthracite Mining",
  },
  {
    label: "Coal Mining Services",
  },
  {
    label: "Crude Petroleum and Natural Gas",
  },
  {
    label: "Natural Gas Liquids",
  },
  {
    label: "Oil and Gas Field Services",
  },
  {
    label: "Dimension Stone",
  },
  {
    label: "Crushed and Broken Stone: Including Riprap",
  },
  {
    label: "Sand and Gravel",
  },
  {
    label: "Clay: Ceramic: and Refractory Minerals",
  },
  {
    label: "Chemical and Fertilizer Mineral Mining",
  },
  {
    label: "Nonmetallic Minerals Services: except Fuels",
  },
  {
    label: "Miscellaneous Nonmetallic Minerals: except Fuels",
  },
  {
    label: "General Building Contractors-residential Buildings",
  },
  {
    label: "Operative Builders",
  },
  {
    label: "General Building Contractors-nonresidential Buildings",
  },
  {
    label: "Highway and Street Construction: except Elevated Highways",
  },
  {
    label: "Heavy Construction: except Highway and Street Construction",
  },
  {
    label: "Plumbing: Heating and Air-conditioning",
  },
  {
    label: "Painting and Paper Hanging",
  },
  {
    label: "Electrical Work",
  },
  {
    label: "Masonry: Stonework: Tile Setting: and Plastering",
  },
  {
    label: "Carpentry and Floor Work",
  },
  {
    label: "Roofing: Siding: and Sheet Metal Work",
  },
  {
    label: "Concrete Work",
  },
  {
    label: "Water Well Drilling",
  },
  {
    label: "Miscellaneous Special Trade Contractors",
  },
  {
    label: "Meat Products",
  },
  {
    label: "Dairy Products",
  },
  {
    label: "Canned: Frozen: and Preserved Fruits: Vegetables: and Food Special",
  },
  {
    label: "Grain Mill Products",
  },
  {
    label: "Bakery Products",
  },
  {
    label: "Sugar and Confectionery Products",
  },
  {
    label: "Fats and Oils",
  },
  {
    label: "Beverages",
  },
  {
    label: "Miscellaneous Food Preparations and Kindred Products",
  },
  {
    label: "Cigarettes",
  },
  {
    label: "Cigars",
  },
  {
    label: "Chewing and Smoking Tobacco and Snuff",
  },
  {
    label: "Tobacco Stemming and Redrying",
  },
  {
    label: "Broadwoven Fabric Mills: Cotton",
  },
  {
    label: "Broadwoven Fabric Mills: Manmade Fiber and Silk",
  },
  {
    label: "Broadwoven Fabric Mills: Wool (including Dyeing and Finishing)",
  },
  {
    label: "Narrow Fabric and Other Smallwares Mills: Cotton: Wool: Silk: And",
  },
  {
    label: "Knitting Mills",
  },
  {
    label: "Dyeing and Finishing Textiles: except Wool Fabrics and Knit Goods",
  },
  {
    label: "Carpets and Rugs",
  },
  {
    label: "Yarn and Thread Mills",
  },
  {
    label: "Miscellaneous Textile Goods",
  },
  {
    label: "Men's and Boys' Suits: Coats: and Overcoats",
  },
  {
    label: "Men's and Boys' Furnishings: Work Clothing: and Allied Garments",
  },
  {
    label: "Women's: Misses': and Juniors' Outerwear",
  },
  {
    label: "Women's: Misses': Children's: and Infants' Undergarments",
  },
  {
    label: "Hats: Caps: and Millinery",
  },
  {
    label: "Girls': Children's: and Infants' Outerwear",
  },
  {
    label: "Fur Goods",
  },
  {
    label: "Miscellaneous Apparel and Accessories",
  },
  {
    label: "Miscellaneous Fabricated Textile Products",
  },
  {
    label: "Logging",
  },
  {
    label: "Sawmills and Planing Mills",
  },
  {
    label: "Millwork: Veneer: Plywood: and Structural Wood Members",
  },
  {
    label: "Wood Containers",
  },
  {
    label: "Wood Buildings and Mobile Homes",
  },
  {
    label: "Miscellaneous Wood Products",
  },
  {
    label: "Household Furniture",
  },
  {
    label: "Office Furniture",
  },
  {
    label: "Public Building and Related Furniture",
  },
  {
    label: "Partitions: Shelving: Lockers: and Office and Store Fixtures",
  },
  {
    label: "Miscellaneous Furniture and Fixtures",
  },
  {
    label: "Pulp Mills",
  },
  {
    label: "Paper Mills",
  },
  {
    label: "Paperboard Mills",
  },
  {
    label: "Paperboard Containers and Boxes",
  },
  {
    label: "Converted Paper and Paperboard Products: except Containers and Box",
  },
  {
    label: "Newspapers: Publishing: Or Publishing and Printing",
  },
  {
    label: "Periodicals: Publishing: Or Publishing and Printing",
  },
  {
    label: "Books",
  },
  {
    label: "Miscellaneous Publishing",
  },
  {
    label: "Commercial Printing",
  },
  {
    label: "Manifold Business Forms",
  },
  {
    label: "Greeting Cards",
  },
  {
    label: "Blankbooks: Looseleaf Binders: and Bookbinding and Related Work",
  },
  {
    label: "Service Industries For The Printing Trade",
  },
  {
    label: "Industrial Inorganic Chemicals",
  },
  {
    label: "Plastics Materials and Synthetic Resins: Synthetic Rubber: Cellulo",
  },
  {
    label: "Drugs",
  },
  {
    label: "Soap: Detergents: and Cleaning Preparations; Perfumes: Cosmetics:",
  },
  {
    label: "Paints: Varnishes: Lacquers: Enamels: and Allied Products",
  },
  {
    label: "Industrial Organic Chemicals",
  },
  {
    label: "Agricultural Chemicals",
  },
  {
    label: "Miscellaneous Chemical Products",
  },
  {
    label: "Petroleum Refining",
  },
  {
    label: "Asphalt Paving and Roofing Materials",
  },
  {
    label: "Miscellaneous Products of Petroleum and Coal",
  },
  {
    label: "Tires and Inner Tubes",
  },
  {
    label: "Rubber and Plastics Footwear",
  },
  {
    label: "Gaskets: Packing: and Sealing Devices and Rubber and Plastics Hose",
  },
  {
    label: "Fabricated Rubber Products: Not Elsewhere Classified",
  },
  {
    label: "Miscellaneous Plastics Products",
  },
  {
    label: "Leather Tanning and Finishing",
  },
  {
    label: "Boot and Shoe Cut Stock and Findings",
  },
  {
    label: "Footwear: except Rubber",
  },
  {
    label: "Leather Gloves and Mittens",
  },
  {
    label: "Luggage",
  },
  {
    label: "Handbags and Other Personal Leather Goods",
  },
  {
    label: "Leather Goods: Not Elsewhere Classified",
  },
  {
    label: "Flat Glass",
  },
  {
    label: "Glass and Glassware: Pressed Or Blown",
  },
  {
    label: "Glass Products: Made of Purchased Glass",
  },
  {
    label: "Cement: Hydraulic",
  },
  {
    label: "Structural Clay Products",
  },
  {
    label: "Pottery and Related Products",
  },
  {
    label: "Concrete: Gypsum: and Plaster Products",
  },
  {
    label: "Cut Stone and Stone Products",
  },
  {
    label: "Abrasive: Asbestos: and Miscellaneous Nonmetallic Mineral Products",
  },
  {
    label: "Steel Works: Blast Furnaces: and Rolling and Finishing Mills",
  },
  {
    label: "Iron and Steel Foundries",
  },
  {
    label: "Primary Smelting and Refining of Nonferrous Metals",
  },
  {
    label: "Secondary Smelting and Refining of Nonferrous Metals",
  },
  {
    label: "Rolling: Drawing: and Extruding of Nonferrous Metals",
  },
  {
    label: "Nonferrous Foundries (castings)",
  },
  {
    label: "Miscellaneous Primary Metal Products",
  },
  {
    label: "Metal Cans and Shipping Containers",
  },
  {
    label: "Cutlery: Handtools: and General Hardware",
  },
  {
    label: "Heating Equipment: except Electric and Warm Air; and Plumbing Fixt",
  },
  {
    label: "Fabricated Structural Metal Products",
  },
  {
    label: "Screw Machine Products: and Bolts: Nuts: Screws: Rivets: and Washe",
  },
  {
    label: "Metal Forgings and Stampings",
  },
  {
    label: "Coating: Engraving: and Allied Services",
  },
  {
    label: "Ordnance and Accessories: except Vehicles and Guided Missiles",
  },
  {
    label: "Miscellaneous Fabricated Metal Products",
  },
  {
    label: "Engines and Turbines",
  },
  {
    label: "Farm and Garden Machinery and Equipment",
  },
  {
    label: "Construction: Mining: and Materials Handling Machinery and Equipme",
  },
  {
    label: "Metalworking Machinery and Equipment",
  },
  {
    label: "Special Industry Machinery: except Metalworking Machinery",
  },
  {
    label: "General Industrial Machinery and Equipment",
  },
  {
    label: "Computer and Office Equipment",
  },
  {
    label: "Refrigeration and Service Industry Machinery",
  },
  {
    label: "Miscellaneous Industrial and Commercial Machinery and Equipment",
  },
  {
    label: "Electric Transmission and Distribution Equipment",
  },
  {
    label: "Electrical Industrial Apparatus",
  },
  {
    label: "Household Appliances",
  },
  {
    label: "Electric Lighting and Wiring Equipment",
  },
  {
    label: "Household Audio and Video Equipment: and Audio Recordings",
  },
  {
    label: "Communications Equipment",
  },
  {
    label: "Electronic Components and Accessories",
  },
  {
    label: "Miscellaneous Electrical Machinery: Equipment: and Supplies",
  },
  {
    label: "Motor Vehicles and Motor Vehicle Equipment",
  },
  {
    label: "Aircraft and Parts",
  },
  {
    label: "Ship and Boat Building and Repairing",
  },
  {
    label: "Railroad Equipment",
  },
  {
    label: "Motorcycles: Bicycles: and Parts",
  },
  {
    label: "Guided Missiles and Space Vehicles and Parts",
  },
  {
    label: "Miscellaneous Transportation Equipment",
  },
  {
    label: "Search: Detection: Navigation: Guidance: Aeronautical: and Nautica",
  },
  {
    label: "Laboratory Apparatus and Analytical: Optical: Measuring: and Contr",
  },
  {
    label: "Surgical: Medical: and Dental Instruments and Supplies",
  },
  {
    label: "Ophthalmic Goods",
  },
  {
    label: "Photographic Equipment and Supplies",
  },
  {
    label: "Watches: Clocks: Clockwork Operated Devices: and Parts",
  },
  {
    label: "Jewelry: Silverware: and Plated Ware",
  },
  {
    label: "Musical Instruments",
  },
  {
    label: "Dolls: Toys: Games and Sporting and Athletic Goods",
  },
  {
    label: "Pens: Pencils: and Other Artists' Materials",
  },
  {
    label: "Costume Jewelry: Costume Novelties: Buttons: and Miscellaneous Not",
  },
  {
    label: "Railroads",
  },
  {
    label: "Local and Suburban Passenger Transportation",
  },
  {
    label: "Taxicabs",
  },
  {
    label: "Intercity and Rural Bus Transportation",
  },
  {
    label: "Bus Charter Service",
  },
  {
    label: "School Buses",
  },
  {
    label: "Terminal and Service Facilities For Motor Vehicle Passenger Transp",
  },
  {
    label: "Trucking and Courier Services: except Air",
  },
  {
    label: "Public Warehousing and Storage",
  },
  {
    label: "Terminal and Joint Terminal Maintenance Facilities For Motor Freig",
  },
  {
    label: "Deep Sea Foreign Transportation of Freight",
  },
  {
    label: "Deep Sea Domestic Transportation of Freight",
  },
  {
    label: "Freight Transportation On The Great Lakes&die;st. Lawrence Seaway",
  },
  {
    label: "Water Transportation of Freight: Not Elsewhere Classified",
  },
  {
    label: "Water Transportation of Passengers",
  },
  {
    label: "Services Incidental To Water Transportation",
  },
  {
    label: "Air Transportation: Scheduled: and Air Courier Services",
  },
  {
    label: "Air Transportation: Nonscheduled",
  },
  {
    label: "Airports: Flying Fields: and Airport Terminal Services",
  },
  {
    label: "Arrangement of Passenger Transportation",
  },
  {
    label: "Arrangement of Transportation of Freight and Cargo",
  },
  {
    label: "Rental of Railroad Cars",
  },
  {
    label: "Miscellaneous Services Incidental To Transportation",
  },
  {
    label: "Telephone Communications",
  },
  {
    label: "Telegraph and Other Message Communications",
  },
  {
    label: "Radio and Television Broadcasting Stations",
  },
  {
    label: "Cable and Other Pay Television Services",
  },
  {
    label: "Communications Services: Not Elsewhere Classified",
  },
  {
    label: "Electric Services",
  },
  {
    label: "Gas Production and Distribution",
  },
  {
    label: "Combination Electric and Gas: and Other Utility Services",
  },
  {
    label: "Water Supply",
  },
  {
    label: "Sanitary Services",
  },
  {
    label: "Steam and Air-conditioning Supply",
  },
  {
    label: "Irrigation Systems",
  },
  {
    label: "Motor Vehicles and Motor Vehicle Parts and Supplies",
  },
  {
    label: "Furniture and Homefurnishings",
  },
  {
    label: "Lumber and Other Construction Materials",
  },
  {
    label: "Professional and Commercial Equipment and Supplies",
  },
  {
    label: "Metals and Minerals: except Petroleum",
  },
  {
    label: "Electrical Goods",
  },
  {
    label: "Hardware: and Plumbing and Heating Equipment and Supplies",
  },
  {
    label: "Machinery: Equipment: and Supplies",
  },
  {
    label: "Miscellaneous Durable Goods",
  },
  {
    label: "Paper and Paper Products",
  },
  {
    label: "Drugs: Drug Proprietaries: and Druggists' Sundries",
  },
  {
    label: "Apparel: Piece Goods: and Notions",
  },
  {
    label: "Groceries and Related Products",
  },
  {
    label: "Farm-product Raw Materials",
  },
  {
    label: "Petroleum and Petroleum Products",
  },
  {
    label: "Beer: Wine: and Distilled Alcoholic Beverages",
  },
  {
    label: "Miscellaneous Nondurable Goods",
  },
  {
    label: "Lumber and Other Building Materials Dealers",
  },
  {
    label: "Paint: Glass: and Wallpaper Stores",
  },
  {
    label: "Hardware Stores",
  },
  {
    label: "Retail Nurseries: Lawn and Garden Supply Stores",
  },
  {
    label: "Mobile Home Dealers",
  },
  {
    label: "Department Stores",
  },
  {
    label: "Variety Stores",
  },
  {
    label: "Miscellaneous General Merchandise Stores",
  },
  {
    label: "Grocery Stores",
  },
  {
    label: "Meat and Fish (seafood) Markets: Including Freezer Provisioners",
  },
  {
    label: "Fruit and Vegetable Markets",
  },
  {
    label: "Candy: Nut: and Confectionery Stores",
  },
  {
    label: "Dairy Products Stores",
  },
  {
    label: "Retail Bakeries",
  },
  {
    label: "Miscellaneous Food Stores",
  },
  {
    label: "Motor Vehicle Dealers (new and Used)",
  },
  {
    label: "Motor Vehicle Dealers (used Only)",
  },
  {
    label: "Auto and Home Supply Stores",
  },
  {
    label: "Gasoline Service Stations",
  },
  {
    label: "Boat Dealers",
  },
  {
    label: "Recreational Vehicle Dealers",
  },
  {
    label: "Motorcycle Dealers",
  },
  {
    label: "Automotive Dealers: Not Elsewhere Classified",
  },
  {
    label: "Men's and Boys' Clothing and Accessory Stores",
  },
  {
    label: "Women's Clothing Stores",
  },
  {
    label: "Women's Accessory and Specialty Stores",
  },
  {
    label: "Children's and Infants' Wear Stores",
  },
  {
    label: "Family Clothing Stores",
  },
  {
    label: "Shoe Stores",
  },
  {
    label: "Miscellaneous Apparel and Accessory Stores",
  },
  {
    label: "Home Furniture and Furnishings Stores",
  },
  {
    label: "Household Appliance Stores",
  },
  {
    label: "Radio: Television: Consumer Electronics: and Music Stores",
  },
  {
    label: "Drug Stores and Proprietary Stores",
  },
  {
    label: "Liquor Stores",
  },
  {
    label: "Used Merchandise Stores",
  },
  {
    label: "Miscellaneous Shopping Goods Stores",
  },
  {
    label: "Nonstore Retailers",
  },
  {
    label: "Fuel Dealers",
  },
  {
    label: "Retail Stores: Not Elsewhere Classified",
  },
  {
    label: "Central Reserve Depository Institutions",
  },
  {
    label: "Commercial Banks",
  },
  {
    label: "Savings Institutions",
  },
  {
    label: "Credit Unions",
  },
  {
    label: "Foreign Banking and Branches and Agencies of Foreign Banks",
  },
  {
    label: "Functions Related To Depository Banking",
  },
  {
    label: "Federal and Federally-sponsored Credit Agencies",
  },
  {
    label: "Personal Credit Institutions",
  },
  {
    label: "Business Credit Institutions",
  },
  {
    label: "Mortgage Bankers and Brokers",
  },
  {
    label: "Security Brokers: Dealers: and Flotation Companies",
  },
  {
    label: "Commodity Contracts Brokers and Dealers",
  },
  {
    label: "Security and Commodity Exchanges",
  },
  {
    label: "Services Allied With The Exchange of Securities Or Commodities",
  },
  {
    label: "Life Insurance",
  },
  {
    label: "Accident and Health Insurance and Medical Service Plans",
  },
  {
    label: "Fire: Marine: and Casualty Insurance",
  },
  {
    label: "Surety Insurance",
  },
  {
    label: "Title Insurance",
  },
  {
    label: "Pension: Health: and Welfare Funds",
  },
  {
    label: "Insurance Carriers: Not Elsewhere Classified",
  },
  {
    label: "Real Estate Operators (except Developers) and Lessors",
  },
  {
    label: "Real Estate Agents and Managers",
  },
  {
    label: "Title Abstract Offices",
  },
  {
    label: "Land Subdividers and Developers",
  },
  {
    label: "Holding Offices",
  },
  {
    label: "Investment Offices",
  },
  {
    label: "Trusts",
  },
  {
    label: "Miscellaneous Investing",
  },
  {
    label: "Hotels and Motels",
  },
  {
    label: "Rooming and Boarding Houses",
  },
  {
    label: "Camps and Recreational Vehicle Parks",
  },
  {
    label: "Organization Hotels and Lodging Houses: On Membership Basis",
  },
  {
    label: "Laundry: Cleaning: and Garment Services",
  },
  {
    label: "Photographic Studios: Portrait",
  },
  {
    label: "Beauty Shops",
  },
  {
    label: "Barber Shops",
  },
  {
    label: "Shoe Repair Shops and Shoeshine Parlors",
  },
  {
    label: "Funeral Service and Crematories",
  },
  {
    label: "Miscellaneous Personal Services",
  },
  {
    label: "Advertising",
  },
  {
    label: "Consumer Credit Reporting Agencies: Mercantile Reporting Agencies:",
  },
  {
    label: "Mailing: Reproduction: Commercial Art and Photography: and Stenogr",
  },
  {
    label: "Services To Dwellings and Other Buildings",
  },
  {
    label: "Miscellaneous Equipment Rental and Leasing",
  },
  {
    label: "Personnel Supply Services",
  },
  {
    label: "Computer Programming: Data Processing: and Other Computer Related",
  },
  {
    label: "Miscellaneous Business Services",
  },
  {
    label: "Automotive Rental and Leasing: Without Drivers",
  },
  {
    label: "Automobile Parking",
  },
  {
    label: "Automotive Repair Shops",
  },
  {
    label: "Automotive Services: except Repair",
  },
  {
    label: "Electrical Repair Shops",
  },
  {
    label: "Watch: Clock: and Jewelry Repair",
  },
  {
    label: "Reupholstery and Furniture Repair",
  },
  {
    label: "Miscellaneous Repair Shops and Related Services",
  },
  {
    label: "Motion Picture Production and Allied Services",
  },
  {
    label: "Motion Picture Distribution and Allied Services",
  },
  {
    label: "Motion Picture Theaters",
  },
  {
    label: "Video Tape Rental",
  },
  {
    label: "Dance Studios: Schools: and Halls",
  },
  {
    label: "Theatrical Producers (except Motion Picture): Bands: Orchestras: A",
  },
  {
    label: "Bowling Centers",
  },
  {
    label: "Commercial Sports",
  },
  {
    label: "Miscellaneous Amusement and Recreation Services",
  },
  {
    label: "Offices and Clinics of Doctors of Medicine",
  },
  {
    label: "Offices and Clinics of Dentists",
  },
  {
    label: "Offices and Clinics of Doctors of Osteopathy",
  },
  {
    label: "Offices and Clinics of Other Health Practitioners",
  },
  {
    label: "Nursing and Personal Care Facilities",
  },
  {
    label: "Hospitals",
  },
  {
    label: "Medical and Dental Laboratories",
  },
  {
    label: "Home Health Care Services",
  },
  {
    label: "Miscellaneous Health and Allied Services: Not Elsewhere Classified",
  },
  {
    label: "Elementary and Secondary Schools",
  },
  {
    label: "Colleges: Universities: Professional Schools: and Junior Colleges",
  },
  {
    label: "Libraries",
  },
  {
    label: "Vocational Schools",
  },
  {
    label: "Schools and Educational Services: Not Elsewhere Classified",
  },
  {
    label: "Individual and Family Social Services",
  },
  {
    label: "Job Training and Vocational Rehabilitation Services",
  },
  {
    label: "Child Day Care Services",
  },
  {
    label: "Residential Care",
  },
  {
    label: "Social Services: Not Elsewhere Classified",
  },
  {
    label: "Museums and Art Galleries",
  },
  {
    label: "Arboreta and Botanical Or Zoological Gardens",
  },
  {
    label: "Business Associations",
  },
  {
    label: "Professional Membership Organizations",
  },
  {
    label: "Labor Unions and Similar Labor Organizations",
  },
  {
    label: "Civic: Social: and Fraternal Associations",
  },
  {
    label: "Political Organizations",
  },
  {
    label: "Religious Organizations",
  },
  {
    label: "Membership Organizations: Not Elsewhere Classified",
  },
  {
    label: "Engineering: Architectural: and Surveying Services",
  },
  {
    label: "Accounting: Auditing: and Bookkeeping Services",
  },
  {
    label: "Research: Development: and Testing Services",
  },
  {
    label: "Management and Public Relations Services",
  },
  {
    label: "Executive Offices",
  },
  {
    label: "Legislative Bodies",
  },
  {
    label: "Executive and Legislative Offices Combined",
  },
  {
    label: "General Government: Not Elsewhere Classified",
  },
  {
    label: "Courts",
  },
  {
    label: "Public Order and Safety",
  },
  {
    label: "Administration of Educational Programs",
  },
  {
    label: "Administration of Public Health Programs",
  },
  {
    label: "Administration of Social: Human Resource and Income Maintenance Pr",
  },
  {
    label: "Administration of Veterans' Affairs: except Health and Insurance",
  },
  {
    label: "Administration of Environmental Quality Programs",
  },
  {
    label: "Administration of Housing and Urban Development Programs",
  },
  {
    label: "Administration of General Economic Programs",
  },
  {
    label: "Regulation and Administration of Transportation Programs",
  },
  {
    label: "Regulation and Administration of Communications: Electric: Gas: An",
  },
  {
    label: "Regulation of Agricultural Marketing and Commodities",
  },
  {
    label: "Regulation: Licensing: and Inspection of Miscellaneous Commercial",
  },
  {
    label: "Space Research and Technology",
  },
  {
    label: "National Security",
  },
  {
    label: "International Affairs",
  },
  {
    label: "Gold Ores",
  },
  {
    label: "Silver Ores",
  },
  {
    label: "Uranium-radium-vanadium Ores",
  },
  {
    label: "Miscellaneous Metal Ores: Not Elsewhere Classified",
  },
  {
    label: "Bituminous Coal and Lignite Surface Mining",
  },
  {
    label: "Bituminous Coal Underground Mining",
  },
  {
    label: "Drilling Oil and Gas Wells",
  },
  {
    label: "Oil and Gas Field Exploration Services",
  },
  {
    label: "Oil and Gas Field Services: Not Elsewhere Classified",
  },
  {
    label: "Crushed and Broken Limestone",
  },
  {
    label: "Crushed and Broken Granite",
  },
  {
    label: "Crushed and Broken Stone: Not Elsewhere Classified",
  },
  {
    label: "Construction Sand and Gravel",
  },
  {
    label: "Industrial Sand",
  },
  {
    label: "Kaolin and Ball Clay",
  },
  {
    label: "Clay: Ceramic: and Refractory Minerals: Not Elsewhere Classified",
  },
  {
    label: "Potash: Soda: and Borate Minerals",
  },
  {
    label: "Phosphate Rock",
  },
  {
    label: "Chemical and Fertilizer Mineral Mining: Not Elsewhere Classified",
  },
  {
    label: "General Contractors-single-family Houses",
  },
  {
    label: "General Contractors-residential Buildings: Other Than Single-fami",
  },
  {
    label: "General Contractors-industrial Buildings and Warehouses",
  },
  {
    label: "General Contractors-nonresidential Buildings: Other Than Industri",
  },
  {
    label: "Bridge: Tunnel: and Elevated Highway Construction",
  },
  {
    label: "Water: Sewer: Pipeline: and Communications and Power Line Constru",
  },
  {
    label: "Heavy Construction: Not Elsewhere Classified",
  },
  {
    label: "Masonry: Stone Setting: and Other Stone Work",
  },
  {
    label: "Plastering: Drywall: Acoustical: and Insulation Work",
  },
  {
    label: "Terrazzo: Tile: Marble: and Mosaic Work",
  },
  {
    label: "Carpentry Work",
  },
  {
    label: "Floor Laying and Other Floor Work: Not Elsewhere Classified",
  },
  {
    label: "Structural Steel Erection",
  },
  {
    label: "Glass Installation: except Automotive-contractors",
  },
  {
    label: "Excavation Work",
  },
  {
    label: "Wrecking and Demolition Work",
  },
  {
    label: "Installation Or Erection of Building Equipment: Not Elsewhere Cla",
  },
  {
    label: "Special Trade Contractors: Not Elsewhere Classified",
  },
  {
    label: "Meat Packing Plants",
  },
  {
    label: "Sausages and Other Prepared Meat Products",
  },
  {
    label: "Poultry Slaughtering and Processing",
  },
  {
    label: "Creamery Butter",
  },
  {
    label: "Natural: Processed: and Imitation Cheese",
  },
  {
    label: "Dry: Condensed: and Evaporated Dairy Products",
  },
  {
    label: "Ice Cream and Frozen Desserts",
  },
  {
    label: "Fluid Milk",
  },
  {
    label: "Canned Specialties",
  },
  {
    label: "Canned Fruits: Vegetables: Preserves: Jams: and Jellies",
  },
  {
    label: "Dried and Dehydrated Fruits: Vegetables: and Soup Mixes",
  },
  {
    label: "Pickled Fruits and Vegetables: Vegetable Sauces and Seasonings: A",
  },
  {
    label: "Frozen Fruits: Fruit Juices: and Vegetables",
  },
  {
    label: "Frozen Specialties: Not Elsewhere Classified",
  },
  {
    label: "Flour and Other Grain Mill Products",
  },
  {
    label: "Cereal Breakfast Foods",
  },
  {
    label: "Rice Milling",
  },
  {
    label: "Prepared Flour Mixes and Doughs",
  },
  {
    label: "Wet Corn Milling",
  },
  {
    label: "Dog and Cat Food",
  },
  {
    label: "Prepared Feeds and Feed Ingredients For Animals and Fowls: Except",
  },
  {
    label: "Bread and Other Bakery Products: except Cookies and Crackers",
  },
  {
    label: "Cookies and Crackers",
  },
  {
    label: "Frozen Bakery Products: except Bread",
  },
  {
    label: "Cane Sugar: except Refining",
  },
  {
    label: "Cane Sugar Refining",
  },
  {
    label: "Beet Sugar",
  },
  {
    label: "Candy and Other Confectionery Products",
  },
  {
    label: "Chocolate and Cocoa Products",
  },
  {
    label: "Chewing Gum",
  },
  {
    label: "Salted and Roasted Nuts and Seeds",
  },
  {
    label: "Cottonseed Oil Mills",
  },
  {
    label: "Soybean Oil Mills",
  },
  {
    label: "Vegetable Oil Mills: except Corn: Cottonseed: and Soybean",
  },
  {
    label: "Animal and Marine Fats and Oils",
  },
  {
    label: "Shortening: Table Oils: Margarine: and Other Edible Fats and Oils",
  },
  {
    label: "Malt Beverages",
  },
  {
    label: "Malt",
  },
  {
    label: "Wines: Brandy: and Brandy Spirits",
  },
  {
    label: "Distilled and Blended Liquors",
  },
  {
    label: "Bottled and Canned Soft Drinks and Carbonated Waters",
  },
  {
    label: "Flavoring Extracts and Flavoring Syrups: Not Elsewhere Classified",
  },
  {
    label: "Canned and Cured Fish and Seafoods",
  },
  {
    label: "Prepared Fresh Or Frozen Fish and Seafoods",
  },
  {
    label: "Roasted Coffee",
  },
  {
    label: "Potato Chips: Corn Chips: and Similar Snacks",
  },
  {
    label: "Manufactured Ice",
  },
  {
    label: "Macaroni: Spaghetti: Vermicelli: and Noodles",
  },
  {
    label: "Food Preparations: Not Elsewhere Classified",
  },
  {
    label: "Women's Full-length and Knee-length Hosiery: except Socks",
  },
  {
    label: "Hosiery: Not Elsewhere Classified",
  },
  {
    label: "Knit Outerwear Mills",
  },
  {
    label: "Knit Underwear and Nightwear Mills",
  },
  {
    label: "Weft Knit Fabric Mills",
  },
  {
    label: "Lace and Warp Knit Fabric Mills",
  },
  {
    label: "Knitting Mills: Not Elsewhere Classified",
  },
  {
    label: "Finishers of Broadwoven Fabrics of Cotton",
  },
  {
    label: "Finishers of Broadwoven Fabrics of Manmade Fiber and Silk",
  },
  {
    label: "Finishers of Textiles: Not Elsewhere Classified",
  },
  {
    label: "Yarn Spinning Mills",
  },
  {
    label: "Acetate Filament Yarn: Throwing: Twisting: Winding: Or Spooling",
  },
  {
    label: "Thread Mills",
  },
  {
    label: "Coated Fabrics: Not Rubberized",
  },
  {
    label: "Tire Cord and Fabrics",
  },
  {
    label: "Nonwoven Fabrics",
  },
  {
    label: "Cordage and Twine",
  },
  {
    label: "Textile Goods: Not Elsewhere Classified",
  },
  {
    label: "Men's and Boys' Shirts: except Work Shirts",
  },
  {
    label: "Men's and Boys' Underwear and Nightwear",
  },
  {
    label: "Men's and Boys' Neckwear",
  },
  {
    label: "Men's and Boys' Separate Trousers and Slacks",
  },
  {
    label: "Men's and Boys' Work Clothing",
  },
  {
    label: "Men's and Boys' Clothing: Not Elsewhere Classified",
  },
  {
    label: "Women's: Misses': and Juniors' Blouses and Shirts",
  },
  {
    label: "Women's: Misses': and Juniors' Dresses",
  },
  {
    label: "Women's: Misses': and Juniors' Suits: Skirts: and Coats",
  },
  {
    label: "Women's: Misses': and Juniors' Outerwear: Not Elsewhere Classifie",
  },
  {
    label: "Women's: Misses': Children's: and Infants' Underwear and Nightwea",
  },
  {
    label: "Brassieres: Girdles: and Allied Garments",
  },
  {
    label: "Girls': Children's: and Infants' Dresses: Blouses: and Shirts",
  },
  {
    label: "Girls': Children's: and Infants' Outerwear: Not Elsewhere Classif",
  },
  {
    label: "Dress and Work Gloves: except Knit and All-leather",
  },
  {
    label: "Robes and Dressing Gowns",
  },
  {
    label: "Waterproof Outerwear",
  },
  {
    label: "Leather and Sheep-lined Clothing",
  },
  {
    label: "Apparel Belts",
  },
  {
    label: "Apparel and Accessories: Not Elsewhere Classified",
  },
  {
    label: "Curtains and Draperies",
  },
  {
    label: "Housefurnishings: except Curtains and Draperies",
  },
  {
    label: "Textile Bags",
  },
  {
    label: "Canvas and Related Products",
  },
  {
    label: "Pleating: Decorative and Novelty Stitching: and Tucking For The T",
  },
  {
    label: "Automotive Trimmings: Apparel Findings: and Related Products",
  },
  {
    label: "Schiffli Machine Embroideries",
  },
  {
    label: "Fabricated Textile Products: Not Elsewhere Classified",
  },
  {
    label: "Sawmills and Planing Mills: General",
  },
  {
    label: "Hardwood Dimension and Flooring Mills",
  },
  {
    label: "Special Product Sawmills: Not Elsewhere Classified",
  },
  {
    label: "Millwork",
  },
  {
    label: "Wood Kitchen Cabinets",
  },
  {
    label: "Hardwood Veneer and Plywood",
  },
  {
    label: "Softwood Veneer and Plywood",
  },
  {
    label: "Structural Wood Members: Not Elsewhere Classified",
  },
  {
    label: "Nailed and Lock Corner Wood Boxes and Shook",
  },
  {
    label: "Wood Pallets and Skids",
  },
  {
    label: "Wood Containers: Not Elsewhere Classified",
  },
  {
    label: "Mobile Homes",
  },
  {
    label: "Prefabricated Wood Buildings and Components",
  },
  {
    label: "Wood Preserving",
  },
  {
    label: "Reconstituted Wood Products",
  },
  {
    label: "Wood Products: Not Elsewhere Classified",
  },
  {
    label: "Wood Household Furniture: except Upholstered",
  },
  {
    label: "Wood Household Furniture: Upholstered",
  },
  {
    label: "Metal Household Furniture",
  },
  {
    label: "Mattresses: Foundations: and Convertible Beds",
  },
  {
    label: "Wood Television: Radio: Phonograph: and Sewing Machine Cabinets",
  },
  {
    label: "Household Furniture: Not Elsewhere Classified",
  },
  {
    label: "Wood Office Furniture",
  },
  {
    label: "Office Furniture: except Wood",
  },
  {
    label: "Wood Office and Store Fixtures: Partitions: Shelving: and Lockers",
  },
  {
    label: "Office and Store Fixtures: Partitions: Shelving: and Lockers: Exc",
  },
  {
    label: "Drapery Hardware and Window Blinds and Shades",
  },
  {
    label: "Furniture and Fixtures: Not Elsewhere Classified",
  },
  {
    label: "Setup Paperboard Boxes",
  },
  {
    label: "Corrugated and Solid Fiber Boxes",
  },
  {
    label: "Fiber Cans: Tubes: Drums: and Similar Products",
  },
  {
    label: "Sanitary Food Containers: except Folding",
  },
  {
    label: "Folding Paperboard Boxes: Including Sanitary",
  },
  {
    label: "Packaging Paper and Plastics Film: Coated and Laminated",
  },
  {
    label: "Coated and Laminated Paper: Not Elsewhere Classified",
  },
  {
    label: "Plastics: Foil: and Coated Paper Bags",
  },
  {
    label: "Uncoated Paper and Multiwall Bags",
  },
  {
    label: "Die-cut Paper and Paperboard and Cardboard",
  },
  {
    label: "Sanitary Paper Products",
  },
  {
    label: "Envelopes",
  },
  {
    label: "Stationery: Tablets: and Related Products",
  },
  {
    label: "Converted Paper and Paperboard Products: Not Elsewhere Classified",
  },
  {
    label: "Books: Publishing: Or Publishing and Printing",
  },
  {
    label: "Book Printing",
  },
  {
    label: "Commercial Printing: Lithographic",
  },
  {
    label: "Commercial Printing: Gravure",
  },
  {
    label: "Commercial Printing: Not Elsewhere Classified",
  },
  {
    label: "Blankbooks: Looseleaf Binders and Devices",
  },
  {
    label: "Bookbinding and Related Work",
  },
  {
    label: "Typesetting",
  },
  {
    label: "Platemaking and Related Services",
  },
  {
    label: "Alkalies and Chlorine",
  },
  {
    label: "Industrial Gases",
  },
  {
    label: "Inorganic Pigments",
  },
  {
    label: "Industrial Inorganic Chemicals: Not Elsewhere Classified",
  },
  {
    label: "Plastics Materials: Synthetic Resins: and Nonvulcanizable Elastom",
  },
  {
    label: "Synthetic Rubber (vulcanizable Elastomers)",
  },
  {
    label: "Cellulosic Manmade Fibers",
  },
  {
    label: "Manmade Organic Fibers: except Cellulosic",
  },
  {
    label: "Medicinal Chemicals and Botanical Products",
  },
  {
    label: "Pharmaceutical Preparations",
  },
  {
    label: "In Vitro and In Vivo Diagnostic Substances",
  },
  {
    label: "Biological Products: except Diagnostic Substances",
  },
  {
    label: "Soap and Other Detergents: except Specialty Cleaners",
  },
  {
    label: "Specialty Cleaning: Polishing: and Sanitation Preparations",
  },
  {
    label: "Surface Active Agents: Finishing Agents: Sulfonated Oils: and Ass",
  },
  {
    label: "Perfumes: Cosmetics: and Other Toilet Preparations",
  },
  {
    label: "Gum and Wood Chemicals",
  },
  {
    label: "Cyclic Organic Crudes and Intermediates: and Organic Dyes and Pig",
  },
  {
    label: "Industrial Organic Chemicals: Not Elsewhere Classified",
  },
  {
    label: "Nitrogenous Fertilizers",
  },
  {
    label: "Phosphatic Fertilizers",
  },
  {
    label: "Fertilizers: Mixing Only",
  },
  {
    label: "Pesticides and Agricultural Chemicals: Not Elsewhere Classified",
  },
  {
    label: "Adhesives and Sealants",
  },
  {
    label: "Explosives",
  },
  {
    label: "Printing Ink",
  },
  {
    label: "Carbon Black",
  },
  {
    label: "Chemicals and Chemical Preparations: Not Elsewhere Classified",
  },
  {
    label: "Asphalt Paving Mixtures and Blocks",
  },
  {
    label: "Asphalt Felts and Coatings",
  },
  {
    label: "Lubricating Oils and Greases",
  },
  {
    label: "Products of Petroleum and Coal: Not Elsewhere Classified",
  },
  {
    label: "Rubber and Plastics Hose and Belting",
  },
  {
    label: "Gaskets: Packing: and Sealing Devices",
  },
  {
    label: "Molded: Extruded: and Lathe-cut Mechanical Rubber Goods",
  },
  {
    label: "Unsupported Plastics Film and Sheet",
  },
  {
    label: "Unsupported Plastics Profile Shapes",
  },
  {
    label: "Laminated Plastics Plate: Sheet: and Profile Shapes",
  },
  {
    label: "Plastics Pipe",
  },
  {
    label: "Plastics Bottles",
  },
  {
    label: "Plastics Foam Products",
  },
  {
    label: "Custom Compounding of Purchased Plastics Resins",
  },
  {
    label: "Plastics Plumbing Fixtures",
  },
  {
    label: "Plastics Products: Not Elsewhere Classified",
  },
  {
    label: "House Slippers",
  },
  {
    label: "Men's Footwear: except Athletic",
  },
  {
    label: "Women's Footwear: except Athletic",
  },
  {
    label: "Footwear: except Rubber: Not Elsewhere Classified",
  },
  {
    label: "Women's Handbags and Purses",
  },
  {
    label: "Personal Leather Goods: except Women's Handbags and Purses",
  },
  {
    label: "Glass Containers",
  },
  {
    label: "Pressed and Blown Glass and Glassware: Not Elsewhere Classified",
  },
  {
    label: "Brick and Structural Clay Tile",
  },
  {
    label: "Ceramic Wall and Floor Tile",
  },
  {
    label: "Clay Refractories",
  },
  {
    label: "Structural Clay Products: Not Elsewhere Classified",
  },
  {
    label: "Vitreous China Plumbing Fixtures and China and Earthenware Fittin",
  },
  {
    label: "Vitreous China Table and Kitchen Articles",
  },
  {
    label: "Fine Earthenware (whiteware) Table and Kitchen Articles",
  },
  {
    label: "Porcelain Electrical Supplies",
  },
  {
    label: "Pottery Products: Not Elsewhere Classified",
  },
  {
    label: "Concrete Block and Brick",
  },
  {
    label: "Concrete Products: except Block and Brick",
  },
  {
    label: "Ready-mixed Concrete",
  },
  {
    label: "Lime",
  },
  {
    label: "Gypsum Products",
  },
  {
    label: "Abrasive Products",
  },
  {
    label: "Asbestos Products",
  },
  {
    label: "Minerals and Earths: Ground Or Otherwise Treated",
  },
  {
    label: "Mineral Wool",
  },
  {
    label: "Nonclay Refractories",
  },
  {
    label: "Nonmetallic Mineral Products: Not Elsewhere Classified",
  },
  {
    label: "Steel Works: Blast Furnaces (including Coke Ovens): and Rolling M",
  },
  {
    label: "Electrometallurgical Products: except Steel",
  },
  {
    label: "Steel Wiredrawing and Steel Nails and Spikes",
  },
  {
    label: "Cold-rolled Steel Sheet: Strip: and Bars",
  },
  {
    label: "Steel Pipe and Tubes",
  },
  {
    label: "Gray and Ductile Iron Foundries",
  },
  {
    label: "Malleable Iron Foundries",
  },
  {
    label: "Steel Investment Foundries",
  },
  {
    label: "Steel Foundries: Not Elsewhere Classified",
  },
  {
    label: "Primary Smelting and Refining of Copper",
  },
  {
    label: "Primary Production of Aluminum",
  },
  {
    label: "Primary Smelting and Refining of Nonferrous Metals: except Copper",
  },
  {
    label: "Rolling: Drawing: and Extruding of Copper",
  },
  {
    label: "Aluminum Sheet: Plate: and Foil",
  },
  {
    label: "Aluminum Extruded Products",
  },
  {
    label: "Aluminum Rolling and Drawing: Not Elsewhere Classified",
  },
  {
    label: "Rolling: Drawing: and Extruding of Nonferrous Metals: except Copp",
  },
  {
    label: "Drawing and Insulating of Nonferrous Wire",
  },
  {
    label: "Aluminum Die-castings",
  },
  {
    label: "Nonferrous Die-castings: except Aluminum",
  },
  {
    label: "Aluminum Foundries",
  },
  {
    label: "Copper Foundries",
  },
  {
    label: "Nonferrous Foundries: except Aluminum and Copper",
  },
  {
    label: "Metal Heat Treating",
  },
  {
    label: "Primary Metal Products: Not Elsewhere Classified",
  },
  {
    label: "Metal Cans",
  },
  {
    label: "Metal Shipping Barrels: Drums: Kegs: and Pails",
  },
  {
    label: "Cutlery",
  },
  {
    label: "Hand and Edge Tools: except Machine Tools and Handsaws",
  },
  {
    label: "Saw Blades and Handsaws",
  },
  {
    label: "Hardware: Not Elsewhere Classified",
  },
  {
    label: "Enameled Iron and Metal Sanitary Ware",
  },
  {
    label: "Plumbing Fixture Fittings and Trim",
  },
  {
    label: "Heating Equipment: except Electric and Warm Air Furnaces",
  },
  {
    label: "Fabricated Structural Metal",
  },
  {
    label: "Metal Doors: Sash: Frames: Molding: and Trim",
  },
  {
    label: "Fabricated Plate Work (boiler Shops)",
  },
  {
    label: "Sheet Metalwork",
  },
  {
    label: "Architectural and Ornamental Metalwork",
  },
  {
    label: "Prefabricated Metal Buildings and Components",
  },
  {
    label: "Miscellaneous Structural Metalwork",
  },
  {
    label: "Screw Machine Products",
  },
  {
    label: "Bolts: Nuts: Screws: Rivets: and Washers",
  },
  {
    label: "Iron and Steel Forgings",
  },
  {
    label: "Nonferrous Forgings",
  },
  {
    label: "Automotive Stampings",
  },
  {
    label: "Crowns and Closures",
  },
  {
    label: "Metal Stampings: Not Elsewhere Classified",
  },
  {
    label: "Electroplating: Plating: Polishing: Anodizing: and Coloring",
  },
  {
    label: "Coating: Engraving: and Allied Services: Not Elsewhere Classified",
  },
  {
    label: "Small Arms Ammunition",
  },
  {
    label: "Ammunition: except For Small Arms",
  },
  {
    label: "Small Arms",
  },
  {
    label: "Ordnance and Accessories: Not Elsewhere Classified",
  },
  {
    label: "Industrial Valves",
  },
  {
    label: "Fluid Power Valves and Hose Fittings",
  },
  {
    label: "Steel Springs: except Wire",
  },
  {
    label: "Valves and Pipe Fittings: Not Elsewhere Classified",
  },
  {
    label: "Wire Springs",
  },
  {
    label: "Miscellaneous Fabricated Wire Products",
  },
  {
    label: "Metal Foil and Leaf",
  },
  {
    label: "Fabricated Pipe and Pipe Fittings",
  },
  {
    label: "Fabricated Metal Products: Not Elsewhere Classified",
  },
  {
    label: "Steam: Gas: and Hydraulic Turbines: and Turbine Generator Set Uni",
  },
  {
    label: "Internal Combustion Engines: Not Elsewhere Classified",
  },
  {
    label: "Farm Machinery and Equipment",
  },
  {
    label: "Blowers: Residential Lawn",
  },
  {
    label: "Construction Machinery and Equipment",
  },
  {
    label: "Mining Machinery and Equipment: except Oil and Gas Field Machiner",
  },
  {
    label: "Oil and Gas Field Machinery and Equipment",
  },
  {
    label: "Elevators and Moving Stairways",
  },
  {
    label: "Conveyors and Conveying Equipment",
  },
  {
    label: "Overhead Traveling Cranes: Hoists: and Monorail Systems",
  },
  {
    label: "Industrial Trucks: Tractors: Trailers: and Stackers",
  },
  {
    label: "Machine Tools: Metal Cutting Types",
  },
  {
    label: "Machine Tools: Metal Forming Types",
  },
  {
    label: "Industrial Patterns",
  },
  {
    label: "Special Dies and Tools: Die Sets: Jigs and Fixtures: and Industri",
  },
  {
    label: "Cutting Tools: Machine Tool Accessories: and Machinists' Precisio",
  },
  {
    label: "Power-driven Handtools",
  },
  {
    label: "Rolling Mill Machinery and Equipment",
  },
  {
    label: "Electric and Gas Welding and Soldering Equipment",
  },
  {
    label: "Metalworking Machinery: Not Elsewhere Classified",
  },
  {
    label: "Textile Machinery",
  },
  {
    label: "Woodworking Machinery",
  },
  {
    label: "Paper Industries Machinery",
  },
  {
    label: "Printing Trades Machinery and Equipment",
  },
  {
    label: "Food Products Machinery",
  },
  {
    label: "Special Industry Machinery: Not Elsewhere Classified",
  },
  {
    label: "Pumps and Pumping Equipment",
  },
  {
    label: "Ball and Roller Bearings",
  },
  {
    label: "Air and Gas Compressors",
  },
  {
    label: "Industrial and Commercial Fans and Blowers and Air Purification E",
  },
  {
    label: "Packaging Machinery",
  },
  {
    label: "Speed Changers: Industrial High-speed Drives: and Gears",
  },
  {
    label: "Industrial Process Furnaces and Ovens",
  },
  {
    label: "Mechanical Power Transmission Equipment: Not Elsewhere Classified",
  },
  {
    label: "General Industrial Machinery and Equipment: Not Elsewhere Classif",
  },
  {
    label: "Electronic Computers",
  },
  {
    label: "Computer Storage Devices",
  },
  {
    label: "Computer Terminals",
  },
  {
    label: "Computer Peripheral Equipment: Not Elsewhere Classified",
  },
  {
    label: "Calculating and Accounting Machines: except Electronic Computers",
  },
  {
    label: "Office Machines: Not Elsewhere Classified",
  },
  {
    label: "Automatic Vending Machines",
  },
  {
    label: "Commercial Laundry: Drycleaning: and Pressing Machines",
  },
  {
    label: "Air-conditioning and Warm Air Heating Equipment and Commercial An",
  },
  {
    label: "Measuring and Dispensing Pumps",
  },
  {
    label: "Service Industry Machinery: Not Elsewhere Classified",
  },
  {
    label: "Carburetors: Pistons: Piston Rings: and Valves",
  },
  {
    label: "Fluid Power Cylinders and Actuators",
  },
  {
    label: "Fluid Power Pumps and Motors",
  },
  {
    label: "Scales and Balances: except Laboratory",
  },
  {
    label: "Industrial and Commercial Machinery and Equipment: Not Elsewhere",
  },
  {
    label: "Power: Distribution: and Specialty Transformers",
  },
  {
    label: "Switchgear and Switchboard Apparatus",
  },
  {
    label: "Motors and Generators",
  },
  {
    label: "Carbon and Graphite Products",
  },
  {
    label: "Relays and Industrial Controls",
  },
  {
    label: "Electrical Industrial Apparatus: Not Elsewhere Classified",
  },
  {
    label: "Household Cooking Equipment",
  },
  {
    label: "Household Refrigerators and Home and Farm Freezers",
  },
  {
    label: "Household Laundry Equipment",
  },
  {
    label: "Electric Housewares and Fans",
  },
  {
    label: "Household Vacuum Cleaners",
  },
  {
    label: "Household Appliances: Not Elsewhere Classified",
  },
  {
    label: "Electric Lamp Bulbs and Tubes",
  },
  {
    label: "Current-carrying Wiring Devices",
  },
  {
    label: "Noncurrent-carrying Wiring Devices",
  },
  {
    label: "Residential Electric Lighting Fixtures",
  },
  {
    label: "Commercial: Industrial: and Institutional Electric Lighting Fixtu",
  },
  {
    label: "Vehicular Lighting Equipment",
  },
  {
    label: "Lighting Equipment: Not Elsewhere Classified",
  },
  {
    label: "Household Audio and Video Equipment",
  },
  {
    label: "Phonograph Records and Prerecorded Audio Tapes and Disks",
  },
  {
    label: "Telephone and Telegraph Apparatus",
  },
  {
    label: "Radio and Television Broadcasting and Communications Equipment",
  },
  {
    label: "Communications Equipment: Not Elsewhere Classified",
  },
  {
    label: "Electron Tubes",
  },
  {
    label: "Printed Circuit Boards",
  },
  {
    label: "Semiconductors and Related Devices",
  },
  {
    label: "Electronic Capacitors",
  },
  {
    label: "Electronic Resistors",
  },
  {
    label: "Electronic Coils: Transformers: and Other Inductors",
  },
  {
    label: "Electronic Connectors",
  },
  {
    label: "Electronic Components: Not Elsewhere Classified",
  },
  {
    label: "Storage Batteries",
  },
  {
    label: "Primary Batteries: Dry and Wet",
  },
  {
    label: "Electrical Equipment For Internal Combustion Engines",
  },
  {
    label: "Magnetic and Optical Recording Media",
  },
  {
    label: "Electrical Machinery: Equipment: and Supplies: Not Elsewhere Clas",
  },
  {
    label: "Motor Vehicles and Passenger Car Bodies",
  },
  {
    label: "Truck and Bus Bodies",
  },
  {
    label: "Motor Vehicle Parts and Accessories",
  },
  {
    label: "Truck Trailers",
  },
  {
    label: "Motor Homes",
  },
  {
    label: "Aircraft",
  },
  {
    label: "Aircraft Engines and Engine Parts",
  },
  {
    label: "Aircraft Parts and Auxiliary Equipment: Not Elsewhere Classified",
  },
  {
    label: "Ship Building and Repairing",
  },
  {
    label: "Boat Building and Repairing",
  },
  {
    label: "Guided Missiles and Space Vehicles",
  },
  {
    label: "Guided Missile and Space Vehicle Propulsion Units and Propulsion",
  },
  {
    label: "Guided Missile and Space Vehicle Parts and Auxiliary Equipment: N",
  },
  {
    label: "Travel Trailers and Campers",
  },
  {
    label: "Tanks and Tank Components",
  },
  {
    label: "Transportation Equipment: Not Elsewhere Classified",
  },
  {
    label: "Search: Detection: Navigation: Guidance: Aeronautical: and Nautic",
  },
  {
    label: "Laboratory Apparatus and Furniture",
  },
  {
    label: "Automatic Controls For Regulating Residential and Commercial Envi",
  },
  {
    label: "Industrial Instruments For Measurement: Display: and Control of P",
  },
  {
    label: "Totalizing Fluid Meters and Counting Devices",
  },
  {
    label: "Instruments For Measuring and Testing of Electricity and Electric",
  },
  {
    label: "Laboratory Analytical Instruments",
  },
  {
    label: "Optical Instruments and Lenses",
  },
  {
    label: "Measuring and Controlling Devices: Not Elsewhere Classified",
  },
  {
    label: "Surgical and Medical Instruments and Apparatus",
  },
  {
    label: "Orthopedic: Prosthetic: and Surgical Appliances and Supplies",
  },
  {
    label: "Dental Equipment and Supplies",
  },
  {
    label: "X-ray Apparatus and Tubes and Related Irradiation Apparatus",
  },
  {
    label: "Electromedical and Electrotherapeutic Apparatus",
  },
  {
    label: "Jewelry: Precious Metal",
  },
  {
    label: "Silverware: Plated Ware: and Stainless Steel Ware",
  },
  {
    label: "Jewelers' Findings and Materials: and Lapidary Work",
  },
  {
    label: "Dolls and Stuffed Toys",
  },
  {
    label: "Games: Toys: and Children's Vehicles: except Dolls and Bicycles",
  },
  {
    label: "Sporting and Athletic Goods: Not Elsewhere Classified",
  },
  {
    label: "Pens: Mechanical Pencils: and Parts",
  },
  {
    label: "Lead Pencils: Crayons: and Artists' Materials",
  },
  {
    label: "Marking Devices",
  },
  {
    label: "Carbon Paper and Inked Ribbons",
  },
  {
    label: "Costume Jewelry and Costume Novelties: except Precious Metal",
  },
  {
    label: "Fasteners: Buttons: Needles: and Pins",
  },
  {
    label: "Brooms and Brushes",
  },
  {
    label: "Signs and Advertising Specialties",
  },
  {
    label: "Burial Caskets",
  },
  {
    label: "Linoleum: Asphalted-felt-base: and Other Hard Surface Floor Cover",
  },
  {
    label: "Manufacturing Industries: Not Elsewhere Classified",
  },
  {
    label: "Railroads: Line-haul Operating",
  },
  {
    label: "Railroad Switching and Terminal Establishments",
  },
  {
    label: "Local and Suburban Transit",
  },
  {
    label: "Local Passenger Transportation: Not Elsewhere Classified",
  },
  {
    label: "Local Bus Charter Service",
  },
  {
    label: "Bus Charter Service: except Local",
  },
  {
    label: "Terminal and Service Facilities For Motor Vehicle Passenger Trans",
  },
  {
    label: "Local Trucking Without Storage",
  },
  {
    label: "Trucking: except Local",
  },
  {
    label: "Local Trucking With Storage",
  },
  {
    label: "Courier Services: except By Air",
  },
  {
    label: "Farm Product Warehousing and Storage",
  },
  {
    label: "Refrigerated Warehousing and Storage",
  },
  {
    label: "General Warehousing and Storage",
  },
  {
    label: "Special Warehousing and Storage: Not Elsewhere Classified",
  },
  {
    label: "Terminal and Joint Terminal Maintenance Facilities For Motor Frei",
  },
  {
    label: "Deep Sea Transportation of Passengers: except By Ferry",
  },
  {
    label: "Ferries",
  },
  {
    label: "Water Transportation of Passengers: Not Elsewhere Classified",
  },
  {
    label: "Marine Cargo Handling",
  },
  {
    label: "Towing and Tugboat Services",
  },
  {
    label: "Marinas",
  },
  {
    label: "Water Transportation Services: Not Elsewhere Classified",
  },
  {
    label: "Air Transportation: Scheduled",
  },
  {
    label: "Air Courier Services",
  },
  {
    label: "Crude Petroleum Pipelines",
  },
  {
    label: "Refined Petroleum Pipelines",
  },
  {
    label: "Pipelines: Not Elsewhere Classified",
  },
  {
    label: "Travel Agencies",
  },
  {
    label: "Tour Operators",
  },
  {
    label: "Arrangement of Passenger Transportation: Not Elsewhere Classified",
  },
  {
    label: "Packing and Crating",
  },
  {
    label: "Cargo Checkers and Surveyors: Marine",
  },
  {
    label: "Transportation Services: Not Elsewhere Classified",
  },
  {
    label: "Radiotelephone Communications",
  },
  {
    label: "Telephone Communications: except Radiotelephone",
  },
  {
    label: "Radio Broadcasting Stations",
  },
  {
    label: "Television Broadcasting Stations",
  },
  {
    label: "Natural Gas Transmission",
  },
  {
    label: "Natural Gas Transmission and Distribution",
  },
  {
    label: "Natural Gas Distribution",
  },
  {
    label: "Mixed: Manufactured: Or Liquefied Petroleum Gas Production And/or",
  },
  {
    label: "Electric and Other Services Combined",
  },
  {
    label: "Gas and Other Services Combined",
  },
  {
    label: "Combination Utilities: Not Elsewhere Classified",
  },
  {
    label: "Sewerage Systems",
  },
  {
    label: "Refuse Systems",
  },
  {
    label: "Sanitary Services: Not Elsewhere Classified",
  },
  {
    label: "Automobiles and Other Motor Vehicles",
  },
  {
    label: "Motor Vehicle Supplies and New Parts",
  },
  {
    label: "Tires and Tubes",
  },
  {
    label: "Motor Vehicle Parts: Used",
  },
  {
    label: "Furniture",
  },
  {
    label: "Homefurnishings",
  },
  {
    label: "Lumber: Plywood: Millwork: and Wood Panels",
  },
  {
    label: "Brick: Stone: and Related Construction Materials",
  },
  {
    label: "Roofing: Siding: and Insulation Materials",
  },
  {
    label: "Construction Materials: Not Elsewhere Classified",
  },
  {
    label: "Office Equipment",
  },
  {
    label: "Computers and Computer Peripheral Equipment and Software",
  },
  {
    label: "Commercial Equipment: Not Elsewhere Classified",
  },
  {
    label: "Medical: Dental: and Hospital Equipment and Supplies",
  },
  {
    label: "Professional Equipment and Supplies: Not Elsewhere Classified",
  },
  {
    label: "Metals Service Centers and Offices",
  },
  {
    label: "Coal and Other Minerals and Ores",
  },
  {
    label: "Electrical Apparatus and Equipment: Wiring Supplies: and Construc",
  },
  {
    label: "Electrical Appliances: Television and Radio Sets",
  },
  {
    label: "Electronic Parts and Equipment: Not Elsewhere Classified",
  },
  {
    label: "Hardware",
  },
  {
    label: "Plumbing and Heating Equipment and Supplies (hydronics)",
  },
  {
    label: "Warm Air Heating and Air-conditioning Equipment and Supplies",
  },
  {
    label: "Refrigeration Equipment and Supplies",
  },
  {
    label: "Construction and Mining (except Petroleum) Machinery and Equipmen",
  },
  {
    label: "Industrial Machinery and Equipment",
  },
  {
    label: "Industrial Supplies",
  },
  {
    label: "Service Establishment Equipment and Supplies",
  },
  {
    label: "Transportation Equipment and Supplies: except Motor Vehicles",
  },
  {
    label: "Sporting and Recreational Goods and Supplies",
  },
  {
    label: "Toys and Hobby Goods and Supplies",
  },
  {
    label: "Scrap and Waste Materials",
  },
  {
    label: "Jewelry: Watches: Precious Stones: and Precious Metals",
  },
  {
    label: "Durable Goods: Not Elsewhere Classified",
  },
  {
    label: "Printing and Writing Paper",
  },
  {
    label: "Stationery and Office Supplies",
  },
  {
    label: "Industrial and Personal Service Paper",
  },
  {
    label: "Piece Goods: Notions: and Other Dry Goods",
  },
  {
    label: "Men's and Boys' Clothing and Furnishings",
  },
  {
    label: "Women's: Children's: and Infants' Clothing and Accessories",
  },
  {
    label: "Footwear",
  },
  {
    label: "Groceries: General Line",
  },
  {
    label: "Packaged Frozen Foods",
  },
  {
    label: "Dairy Products: except Dried Or Canned",
  },
  {
    label: "Poultry and Poultry Products",
  },
  {
    label: "Confectionery",
  },
  {
    label: "Fish and Seafoods",
  },
  {
    label: "Meats and Meat Products",
  },
  {
    label: "Fresh Fruits and Vegetables",
  },
  {
    label: "Groceries and Related Products: Not Elsewhere Classified",
  },
  {
    label: "Grain and Field Beans",
  },
  {
    label: "Livestock",
  },
  {
    label: "Farm-product Raw Materials: Not Elsewhere Classified",
  },
  {
    label: "Plastics Materials and Basic Forms and Shapes",
  },
  {
    label: "Chemicals and Allied Products: Not Elsewhere Classified",
  },
  {
    label: "Petroleum Bulk Stations and Terminals",
  },
  {
    label: "Petroleum and Petroleum Products Wholesalers: except Bulk Station",
  },
  {
    label: "Beer and Ale",
  },
  {
    label: "Wine and Distilled Alcoholic Beverages",
  },
  {
    label: "Farm Supplies",
  },
  {
    label: "Books: Periodicals: and Newspapers",
  },
  {
    label: "Flowers: Nursery Stock: and Florists' Supplies",
  },
  {
    label: "Tobacco and Tobacco Products",
  },
  {
    label: "Paints: Varnishes: and Supplies",
  },
  {
    label: "Nondurable Goods: Not Elsewhere Classified",
  },
  {
    label: "Furniture Stores",
  },
  {
    label: "Floor Covering Stores",
  },
  {
    label: "Drapery: Curtain: and Upholstery Stores",
  },
  {
    label: "Miscellaneous Homefurnishings Stores",
  },
  {
    label: "Radio: Television: and Consumer Electronics Stores",
  },
  {
    label: "Computer and Computer Software Stores",
  },
  {
    label: "Record and Prerecorded Tape Stores",
  },
  {
    label: "Musical Instrument Stores",
  },
  {
    label: "Eating Places",
  },
  {
    label: "Drinking Places (alcoholic Beverages)",
  },
  {
    label: "Sporting Goods Stores and Bicycle Shops",
  },
  {
    label: "Book Stores",
  },
  {
    label: "Stationery Stores",
  },
  {
    label: "Jewelry Stores",
  },
  {
    label: "Hobby: Toy: and Game Shops",
  },
  {
    label: "Camera and Photographic Supply Stores",
  },
  {
    label: "Gift: Novelty: and Souvenir Shops",
  },
  {
    label: "Luggage and Leather Goods Stores",
  },
  {
    label: "Sewing: Needlework: and Piece Goods Stores",
  },
  {
    label: "Catalog and Mail-order Houses",
  },
  {
    label: "Automatic Merchandising Machine Operators",
  },
  {
    label: "Direct Selling Establishments",
  },
  {
    label: "Fuel Oil Dealers",
  },
  {
    label: "Liquefied Petroleum Gas (bottled Gas) Dealers",
  },
  {
    label: "Fuel Dealers: Not Elsewhere Classified",
  },
  {
    label: "Florists",
  },
  {
    label: "Tobacco Stores and Stands",
  },
  {
    label: "News Dealers and Newsstands",
  },
  {
    label: "Optical Goods Stores",
  },
  {
    label: "Miscellaneous Retail Stores: Not Elsewhere Classified",
  },
  {
    label: "Federal Reserve Banks",
  },
  {
    label: "Central Reserve Depository Institutions: Not Elsewhere Classified",
  },
  {
    label: "National Commercial Banks",
  },
  {
    label: "State Commercial Banks",
  },
  {
    label: "Commercial Banks: Not Elsewhere Classified",
  },
  {
    label: "Savings Institutions: Federally Chartered",
  },
  {
    label: "Savings Institutions: Not Federally Chartered",
  },
  {
    label: "Credit Unions: Federally Chartered",
  },
  {
    label: "Credit Unions: Not Federally Chartered",
  },
  {
    label: "Branches and Agencies of Foreign Banks",
  },
  {
    label: "Foreign Trade and International Banking Institutions",
  },
  {
    label: "Nondeposit Trust Facilities",
  },
  {
    label: "Functions Related To Depository Banking: Not Elsewhere Classified",
  },
  {
    label: "Short-term Business Credit Institutions: except Agricultural",
  },
  {
    label: "Miscellaneous Business Credit Institutions",
  },
  {
    label: "Mortgage Bankers and Loan Correspondents",
  },
  {
    label: "Loan Brokers",
  },
  {
    label: "Investment Advice",
  },
  {
    label: "Services Allied With The Exchange of Securities Or Commodities: N",
  },
  {
    label: "Accident and Health Insurance",
  },
  {
    label: "Hospital and Medical Service Plans",
  },
  {
    label: "Operators of Nonresidential Buildings",
  },
  {
    label: "Operators of Apartment Buildings",
  },
  {
    label: "Operators of Dwellings Other Than Apartment Buildings",
  },
  {
    label: "Operators of Residential Mobile Home Sites",
  },
  {
    label: "Lessors of Railroad Property",
  },
  {
    label: "Lessors of Real Property: Not Elsewhere Classified",
  },
  {
    label: "Land Subdividers and Developers: except Cemeteries",
  },
  {
    label: "Cemetery Subdividers and Developers",
  },
  {
    label: "Offices of Bank Holding Companies",
  },
  {
    label: "Offices of Holding Companies: Not Elsewhere Classified",
  },
  {
    label: "Management Investment Offices: Open-end",
  },
  {
    label: "Unit Investment Trusts: Face-amount Certificate Offices: and Clos",
  },
  {
    label: "Educational: Religious: and Charitable Trusts",
  },
  {
    label: "Trusts: except Educational: Religious: and Charitable",
  },
  {
    label: "Oil Royalty Traders",
  },
  {
    label: "Patent Owners and Lessors",
  },
  {
    label: "Real Estate Investment Trusts",
  },
  {
    label: "Investors: Not Elsewhere Classified",
  },
  {
    label: "Sporting and Recreational Camps",
  },
  {
    label: "Recreational Vehicle Parks and Campsites",
  },
  {
    label: "Power Laundries: Family and Commercial",
  },
  {
    label: "Garment Pressing: and Agents For Laundries and Drycleaners",
  },
  {
    label: "Linen Supply",
  },
  {
    label: "Coin-operated Laundries and Drycleaning",
  },
  {
    label: "Drycleaning Plants: except Rug Cleaning",
  },
  {
    label: "Carpet and Upholstery Cleaning",
  },
  {
    label: "Industrial Launderers",
  },
  {
    label: "Laundry and Garment Services: Not Elsewhere Classified",
  },
  {
    label: "Tax Return Preparation Services",
  },
  {
    label: "Miscellaneous Personal Services: Not Elsewhere Classified",
  },
  {
    label: "Advertising Agencies",
  },
  {
    label: "Outdoor Advertising Services",
  },
  {
    label: "Radio: Television: and Publishers' Advertising Representatives",
  },
  {
    label: "Advertising: Not Elsewhere Classified",
  },
  {
    label: "Adjustment and Collection Services",
  },
  {
    label: "Credit Reporting Services",
  },
  {
    label: "Direct Mail Advertising Services",
  },
  {
    label: "Photocopying and Duplicating Services",
  },
  {
    label: "Commercial Photography",
  },
  {
    label: "Commercial Art and Graphic Design",
  },
  {
    label: "Secretarial and Court Reporting Services",
  },
  {
    label: "Disinfecting and Pest Control Services",
  },
  {
    label: "Building Cleaning and Maintenance Services: Not Elsewhere Classif",
  },
  {
    label: "Medical Equipment Rental and Leasing",
  },
  {
    label: "Heavy Construction Equipment Rental and Leasing",
  },
  {
    label: "Equipment Rental and Leasing: Not Elsewhere Classified",
  },
  {
    label: "Employment Agencies",
  },
  {
    label: "Help Supply Services",
  },
  {
    label: "Computer Programming Services",
  },
  {
    label: "Prepackaged Software",
  },
  {
    label: "Computer Integrated Systems Design",
  },
  {
    label: "Computer Processing and Data Preparation and Processing Services",
  },
  {
    label: "Information Retrieval Services",
  },
  {
    label: "Computer Facilities Management Services",
  },
  {
    label: "Computer Rental and Leasing",
  },
  {
    label: "Computer Maintenance and Repair",
  },
  {
    label: "Computer Related Services: Not Elsewhere Classified",
  },
  {
    label: "Detective: Guard: and Armored Car Services",
  },
  {
    label: "Security Systems Services",
  },
  {
    label: "News Syndicates",
  },
  {
    label: "Photofinishing Laboratories",
  },
  {
    label: "Business Services: Not Elsewhere Classified",
  },
  {
    label: "Truck Rental and Leasing: Without Drivers",
  },
  {
    label: "Passenger Car Rental",
  },
  {
    label: "Passenger Car Leasing",
  },
  {
    label: "Utility Trailer and Recreational Vehicle Rental",
  },
  {
    label: "Top: Body: and Upholstery Repair Shops and Paint Shops",
  },
  {
    label: "Automotive Exhaust System Repair Shops",
  },
  {
    label: "Tire Retreading and Repair Shops",
  },
  {
    label: "Automotive Glass Replacement Shops",
  },
  {
    label: "Automotive Transmission Repair Shops",
  },
  {
    label: "General Automotive Repair Shops",
  },
  {
    label: "Automotive Repair Shops: Not Elsewhere Classified",
  },
  {
    label: "Carwashes",
  },
  {
    label: "Automotive Services: except Repair and Carwashes",
  },
  {
    label: "Radio and Television Repair Shops",
  },
  {
    label: "Refrigeration and Air-conditioning Service and Repair Shops",
  },
  {
    label: "Electrical and Electronic Repair Shops: Not Elsewhere Classified",
  },
  {
    label: "Welding Repair",
  },
  {
    label: "Armature Rewinding Shops",
  },
  {
    label: "Repair Shops and Related Services: Not Elsewhere Classified",
  },
  {
    label: "Motion Picture and Video Tape Production",
  },
  {
    label: "Services Allied To Motion Picture Production",
  },
  {
    label: "Motion Picture and Video Tape Distribution",
  },
  {
    label: "Services Allied To Motion Picture Distribution",
  },
  {
    label: "Motion Picture Theaters: except Drive-in",
  },
  {
    label: "Drive-in Motion Picture Theaters",
  },
  {
    label: "Theatrical Producers (except Motion Picture) and Miscellaneous Th",
  },
  {
    label: "Bands: Orchestras: Actors: and Other Entertainers and Entertainme",
  },
  {
    label: "Professional Sports Clubs and Promoters",
  },
  {
    label: "Racing: Including Track Operation",
  },
  {
    label: "Physical Fitness Facilities",
  },
  {
    label: "Public Golf Courses",
  },
  {
    label: "Coin-operated Amusement Devices",
  },
  {
    label: "Amusement Parks",
  },
  {
    label: "Membership Sports and Recreation Clubs",
  },
  {
    label: "Amusement and Recreation Services: Not Elsewhere Classified",
  },
  {
    label: "Offices and Clinics of Chiropractors",
  },
  {
    label: "Offices and Clinics of Optometrists",
  },
  {
    label: "Offices and Clinics of Podiatrists",
  },
  {
    label: "Offices and Clinics of Health Practitioners: Not Elsewhere Classi",
  },
  {
    label: "Skilled Nursing Care Facilities",
  },
  {
    label: "Intermediate Care Facilities",
  },
  {
    label: "Nursing and Personal Care Facilities: Not Elsewhere Classified",
  },
  {
    label: "General Medical and Surgical Hospitals",
  },
  {
    label: "Psychiatric Hospitals",
  },
  {
    label: "Specialty Hospitals: except Psychiatric",
  },
  {
    label: "Medical Laboratories",
  },
  {
    label: "Dental Laboratories",
  },
  {
    label: "Kidney Dialysis Centers",
  },
  {
    label: "Specialty Outpatient Facilities: Not Elsewhere Classified",
  },
  {
    label: "Health and Allied Services: Not Elsewhere Classified",
  },
  {
    label: "Colleges: Universities: and Professional Schools",
  },
  {
    label: "Junior Colleges and Technical Institutes",
  },
  {
    label: "Data Processing Schools",
  },
  {
    label: "Business and Secretarial Schools",
  },
  {
    label: "Vocational Schools: Not Elsewhere Classified",
  },
  {
    label: "Engineering Services",
  },
  {
    label: "Architectural Services",
  },
  {
    label: "Surveying Services",
  },
  {
    label: "Commercial Physical and Biological Research",
  },
  {
    label: "Commercial Economic: Sociological: and Educational Research",
  },
  {
    label: "Noncommercial Research Organizations",
  },
  {
    label: "Testing Laboratories",
  },
  {
    label: "Management Services",
  },
  {
    label: "Management Consulting Services",
  },
  {
    label: "Public Relations Services",
  },
  {
    label: "Facilities Support Management Services",
  },
  {
    label: "Business Consulting Services: Not Elsewhere Classified",
  },
  {
    label: "Police Protection",
  },
  {
    label: "Legal Counsel and Prosecution",
  },
  {
    label: "Correctional Institutions",
  },
  {
    label: "Fire Protection",
  },
  {
    label: "Public Order and Safety: Not Elsewhere Classified",
  },
  {
    label: "Administration of Social: Human Resource and Income Maintenance P",
  },
  {
    label: "Air and Water Resource and Solid Waste Management",
  },
  {
    label: "Land: Mineral: Wildlife: and Forest Conservation",
  },
  {
    label: "Administration of Housing Programs",
  },
  {
    label: "Administration of Urban Planning and Community and Rural Developm",
  },
  {
    label: "Regulation and Administration of Communications: Electric: Gas: A",
  },
  {
    label: "Agricultural Production-crops",
  },
  {
    label: "Cash Grains",
  },
  {
    label: "Wheat",
  },
  {
    label: "Rice",
  },
  {
    label: "Corn",
  },
  {
    label: "Soybeans",
  },
  {
    label: "Cash Grains: Not Elsewhere Classified",
  },
  {
    label: "Field Crops: except Cash Grains",
  },
  {
    label: "Cotton",
  },
  {
    label: "Tobacco",
  },
  {
    label: "Sugarcane and Sugar Beets",
  },
  {
    label: "Irish Potatoes",
  },
  {
    label: "Field Crops: except Cash Grains: Not Elsewhere Classified",
  },
  {
    label: "Vegetables and Melons",
  },
  {
    label: "Fruits and Tree Nuts",
  },
  {
    label: "Berry Crops",
  },
  {
    label: "Grapes",
  },
  {
    label: "Tree Nuts",
  },
  {
    label: "Citrus Fruits",
  },
  {
    label: "Deciduous Tree Fruits",
  },
  {
    label: "Fruits and Tree Nuts: Not Elsewhere Classified",
  },
  {
    label: "Horticultural Specialties",
  },
  {
    label: "Ornamental Floriculture and Nursery Products",
  },
  {
    label: "Food Crops Grown Under Cover",
  },
  {
    label: "General Farms: Primarily Crop",
  },
  {
    label: "Agricultural Production-livestock and Animal Specialties",
  },
  {
    label: "Livestock: except Dairy and Poultry",
  },
  {
    label: "Beef Cattle Feedlots",
  },
  {
    label: "Beef Cattle: except Feedlots",
  },
  {
    label: "Hogs",
  },
  {
    label: "Sheep and Goats",
  },
  {
    label: "General Livestock: except Dairy and Poultry",
  },
  {
    label: "Dairy Farms",
  },
  {
    label: "Poultry and Eggs",
  },
  {
    label: "Broiler: Fryer: and Roaster Chickens",
  },
  {
    label: "Chicken Eggs",
  },
  {
    label: "Turkeys and Turkey Eggs",
  },
  {
    label: "Poultry Hatcheries",
  },
  {
    label: "Poultry and Eggs: Not Elsewhere Classified",
  },
  {
    label: "Restaurants",
  },
  {
    label: "Animal Specialties",
  },
  {
    label: "Fur-bearing Animals and Rabbits",
  },
  {
    label: "Horses and Other Equines",
  },
  {
    label: "Animal Aquaculture",
  },
  {
    label: "Animal Specialties: Not Elsewhere Classified",
  },
  {
    label: "General Farms: Primarily Livestock and Animal Specialties",
  },
  {
    label: "Agricultural Services",
  },
  {
    label: "Soil Preparation Services",
  },
  {
    label: "Crop Services",
  },
  {
    label: "Crop Planting: Cultivating: and Protecting",
  },
  {
    label: "Crop Harvesting: Primarily By Machine",
  },
  {
    label: "Crop Preparation Services For Market: except Cotton Ginning",
  },
  {
    label: "Cotton Ginning",
  },
  {
    label: "Veterinary Services",
  },
  {
    label: "Veterinary Services For Livestock",
  },
  {
    label: "Veterinary Services For Animal Specialties",
  },
  {
    label: "Animal Services: except Veterinary",
  },
  {
    label: "Livestock Services: except Veterinary",
  },
  {
    label: "Animal Specialty Services: except Veterinary",
  },
  {
    label: "Farm Labor and Management Services",
  },
  {
    label: "Farm Labor Contractors and Crew Leaders",
  },
  {
    label: "Farm Management Services",
  },
  {
    label: "Landscape and Horticultural Services",
  },
  {
    label: "Landscape Counseling and Planning",
  },
  {
    label: "Lawn and Garden Services",
  },
  {
    label: "Ornamental Shrub and Tree Services",
  },
  {
    label: "-forestry",
  },
  {
    label: "Timber Tracts",
  },
  {
    label: "Forest Nurseries and Gathering of Forest Products",
  },
  {
    label: "Forestry Services",
  },
  {
    label: "Fishing: Hunting: and Trapping",
  },
  {
    label: "Commercial Fishing",
  },
  {
    label: "Finfish",
  },
  {
    label: "Shellfish",
  },
  {
    label: "Miscellaneous Marine Products",
  },
  {
    label: "Fish Hatcheries and Preserves",
  },
  {
    label: "Hunting and Trapping: and Game Propagation",
  },
];
