import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/apiServices";

export const fetchPastExports = createAsyncThunk(
  "pastExports/fetchPastExports",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await GET(`/export?page=${page}&limit=${limit}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postExport = createAsyncThunk(
  "postExports/postExport",
  async (exportData, { rejectWithValue }) => {
    try {
      const response = await POST("/export", exportData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteLead = createAsyncThunk(
  "deleteLead/deleteLead",
  async (leadData, { rejectWithValue }) => {
    try {
      const response = await POST("/customer/delete-lead", leadData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const pastExportsSlice = createSlice({
  name: "pastExports",
  initialState: {
    exports: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPastExports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPastExports.fulfilled, (state, action) => {
        state.loading = false;
        state.exports = action.payload;
      })
      .addCase(fetchPastExports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default pastExportsSlice.reducer;
