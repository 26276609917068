import React, { useState, useEffect } from "react";
import Checkbox from "../atoms/Checkbox";
import Pagination from "./Pagination";
import { Tooltip } from "react-tooltip";

const Table = ({
  headers,
  data,
  showChecked,
  itemsPerPage = 10,
  length,
  loading,
  searchData,
  handleSelectedId,
  hidePagination,
  selectedIds,
}) => {
  const [selectedRows, setSelectedRows] = useState(new Set(selectedIds));
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAllOnCurrentPage, setSelectAllOnCurrentPage] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const rowsToShow =
    Array.isArray(data) && data.length > 0
      ? length
        ? data.slice(0, length)
        : data
      : [];

  const currentItems =
    Array.isArray(rowsToShow) && rowsToShow.length > 0
      ? rowsToShow.slice(indexOfFirstItem, indexOfLastItem)
      : [];

  const totalPages = Math.ceil(rowsToShow.length / itemsPerPage);

  useEffect(() => {
    if (currentItems.length > 0) {
      const visibleRowIds = currentItems.map((_, rowIndex) => {
        const adjustedRowIndex = rowIndex + indexOfFirstItem;
        return searchData?.[adjustedRowIndex]?.id;
      });

      const allSelectedOnCurrentPage = visibleRowIds.every((id) =>
        selectedRows.has(id)
      );
      setSelectAllOnCurrentPage(allSelectedOnCurrentPage);
    }
  }, [currentPage, currentItems, selectedRows, indexOfFirstItem, searchData]);
  console.log("selectedRows", selectedRows);
  const handleSelectAll = () => {
    const newSelectAll = !selectAllOnCurrentPage;
    setSelectAllOnCurrentPage(newSelectAll);

    const visibleRowIds = currentItems
      .map((_, rowIndex) => {
        const adjustedRowIndex = rowIndex + indexOfFirstItem;
        return searchData?.[adjustedRowIndex]?.id;
      })
      .filter((id) => id !== undefined);

    const updatedSelectedRows = new Set(selectedRows);

    if (newSelectAll) {
      visibleRowIds.forEach((id) => updatedSelectedRows.add(id));
    } else {
      visibleRowIds.forEach((id) => updatedSelectedRows.delete(id));
    }

    setSelectedRows(updatedSelectedRows);
    handleSelectedId(Array.from(updatedSelectedRows));
  };

  const handleRowSelect = (rowIndex) => {
    const adjustedRowIndex = rowIndex + indexOfFirstItem;
    const rowId = searchData?.[adjustedRowIndex]?.id;

    if (!rowId) return;

    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(rowId)) {
      updatedSelectedRows.delete(rowId);
    } else {
      updatedSelectedRows.add(rowId);
    }

    setSelectedRows(updatedSelectedRows);
    handleSelectedId(Array.from(updatedSelectedRows));

    const visibleRowIds = currentItems.map((_, rowIndex) => {
      const adjustedRowIndex = rowIndex + indexOfFirstItem;
      return searchData?.[adjustedRowIndex]?.id;
    });

    const allSelectedOnCurrentPage = visibleRowIds.every((id) =>
      updatedSelectedRows.has(id)
    );
    setSelectAllOnCurrentPage(allSelectedOnCurrentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const extractTextContent = (element) => {
    if (typeof element === "string") return element;
    if (typeof element === "number") return element.toString();
    if (Array.isArray(element))
      return element.map(extractTextContent)?.join(" ");
    if (React.isValidElement(element)) {
      const children = React.Children.toArray(element.props.children);
      return children?.map(extractTextContent).join(" ");
    }
    if (typeof element === "object" && element !== null) {
      return Object.values(element)?.map(extractTextContent).join(" ");
    }
    return "";
  };

  const sanitizeId = (content, rowIndex, cellIndex) => {
    const textContent = extractTextContent(content);
    return `tooltip-${rowIndex}-${cellIndex}-${textContent
      .replace(/\s+/g, "-")
      .toLowerCase()}`;
  };

  const CellContent = ({ content, rowIndex, cellIndex }) => {
    const tooltipId = sanitizeId(content, rowIndex, cellIndex);
    const tooltipContent = extractTextContent(content);
    const displayedContent =
      content?.length > 20 ? content.substring(0, 20) + "..." : content;

    return (
      <div
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltipContent}
        className="max-w-full w-fit"
      >
        <Tooltip id={tooltipId} place="top" />
        {React.isValidElement(content) ? content : displayedContent}
      </div>
    );
  };

  return (
    <>
      <div className="shadow-card-shadow rounded-lg">
        <div className="overflow-x-auto p-1.5">
          <table className="w-full rounded 2xl:table-fixed table-auto">
            <thead>
              <tr className="border-b w-full">
                {showChecked && (
                  <th className="w-[2%] pt-[6px] pl-[5px]">
                    <Checkbox
                      checked={selectAllOnCurrentPage}
                      onChange={handleSelectAll}
                    />
                  </th>
                )}
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={`py-4 ${
                      index === headers.length - 1 ? "text-left" : "text-left"
                    } whitespace-nowrap text-sm text-[#313D31] font-medium leading-tight ${
                      index === 0 ? "pl-5" : ""
                    }`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.length ? (
                currentItems.map((row, rowIndex) => {
                  const adjustedRowIndex = rowIndex + indexOfFirstItem;
                  const rowId = searchData?.[adjustedRowIndex]?.id;

                  return (
                    <tr key={rowId} className="border-b">
                      {showChecked && (
                        <td className="w-[2%] pt-[6px] pl-[5px]">
                          <Checkbox
                            checked={selectedRows.has(rowId)}
                            onChange={() => handleRowSelect(rowIndex)}
                          />
                        </td>
                      )}
                      {Array.isArray(row)
                        ? row.map((cell, cellIndex) => (
                            <td
                              key={cellIndex}
                              className={`w-full py-4 ${
                                cellIndex === row.length - 1
                                  ? "text-left pr-4"
                                  : "text-left"
                              } whitespace-nowrap text-sm text-[#313D31] font-normal  ${
                                cellIndex === 0 ? "pl-5" : "custom_ellipses"
                              }`}
                            >
                              <CellContent
                                content={cell}
                                rowIndex={rowIndex + indexOfFirstItem}
                                cellIndex={cellIndex}
                              />
                            </td>
                          ))
                        : null}
                    </tr>
                  );
                })
              ) : (
                <tr className="border-b last:border-b-0 px-5">
                  <td colSpan={headers.length}>{""} &nbsp; </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {hidePagination ? null : (
        <div className="mb-10">
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Table;
