import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerStats } from "../redux/slices/customerStatsSlice";
import { Card } from "../components/atoms/Card";
import Spinner from "../components/atoms/Spinner";
import Select from "react-select";
import { Link } from "react-router-dom";

export default function ViewStats() {
  const dispatch = useDispatch();
  const { stats, loading, error } = useSelector((state) => state.customerStats);

  const filterOptions = [
    { label: "Last 24 Hours", value: "1" },
    { label: "Last Week", value: "7" },
    { label: "Last Month", value: "30" },
  ];

  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);

  useEffect(() => {
    if (selectedFilter) {
      dispatch(fetchCustomerStats({ duration: selectedFilter.value }));
    }
  }, [dispatch, selectedFilter]);

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="w-full flex justify-between items-center mb-6">
        <h1 className="text-[#1d201d] text-xl font-bold leading-7 ">
          Customer Stats (
          {selectedFilter.label === "Last 24 Hours"
            ? "Last 24 Hours"
            : selectedFilter.label === "Last Week"
            ? "Last 7 Days"
            : "Last 30 Days"}
          )
        </h1>
        <Select
          options={filterOptions}
          className="w-[200px] h-[44px] focus:outline-none cursor-pointer"
          placeholder="Select Duration"
          value={selectedFilter}
          onChange={(option) => setSelectedFilter(option)}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card title="New Signups" value={stats?.data?.newSignups} />
        <Card title="Number of Logins" value={stats?.data?.numLogins} />
        <Card title="Number of Exports" value={stats?.data?.numDbV2Orders} />
        <Link to={`/admin/customers?isPaying=true`}>
          <Card
            title="Number of Paying Customer"
            value={stats?.data?.payingCustomers}
          />
        </Link>
        <Link to={`/admin/customers?isPaying=false`}>
          <Card
            title="Number of nonPaying Customer"
            value={stats?.data?.nonPayingCustomers}
          />
        </Link>
      </div>
    </div>
  );
}
