import React from "react";

const TabButton = ({ label, isActive, onClick }) => {
  return (
    <button
      className={`pb-2 font-medium text-lg whitespace-nowrap outline-none ${
        isActive
          ? "text-lime-green border-b-2 border-lime-green"
          : "text-[#1D201D99]"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default TabButton;
