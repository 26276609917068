import React, { useState } from "react";
import Icons from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import toast from "react-hot-toast";
import { POST } from "../../services/apiServices";
import { Button } from "../atoms/buttons";

const Header = () => {
  const { userDetail, credit: numOfCredits } = useSelector(
    (state) => state.userDetail
  );
  const isAdmin = userDetail?.data?.customer?.is_admin;
  const customerToken = sessionStorage.getItem("customerToken");

  const navigate = useNavigate(); // Initialize history
  const dispatch = useDispatch();

  const userData = userDetail?.data?.customer;
  const isActiveSubscription = userDetail?.data?.isActiveSubscription;
  const checkCustomer = userDetail?.data?.customer?.is_admin;
  // const numOfCredits = userDetail?.data?.customer?.company?.num_credits;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = (e) => {
    e.stopPropagation();
    toast.dismiss();
    const billingPlanType = localStorage.getItem("billingPlanType");

    if (billingPlanType) {
      localStorage.setItem("billingPlanType", billingPlanType);
    }

    dispatch(logout());
    toast.success("Successfully logged out!");

    if (customerToken) {
      sessionStorage.removeItem("customerToken");
      navigate("/database");
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  const resendEmail = async () => {
    try {
      toast.dismiss(); // Dismiss any previous toasts
      const response = await POST("customer/resend-email-confirmation");
      console.log(response);
      toast.success("Email resent successfully");
    } catch (error) {
      console.log(error);
      // toast.error('Failed to resend email');
    }
  };

  const handleUpgradeClick = () => {
    navigate("/billing"); // Navigate to the billing page (or whatever route you have for billing)
  };
  // if not cred then set to  numOfCredits

  const cred = localStorage.getItem("credits") || numOfCredits;
  return (
    <div>
      {/* Please confirm your email */}
      {!userData?.is_email_confirmed && (
        <div className="flex justify-center items-center bg-[#f4fbf6] py-3">
          <div className="rounded-lg">
            <p className="text-sm font-semibold flex items-center gap-2">
              <Icons.InfoIcon />
              Please check your email to verify your account.{" "}
              <u className="cursor-pointer" onClick={() => resendEmail()}>
                Resend email
              </u>
            </p>
          </div>
        </div>
      )}
      <header className="flex justify-between p-2 bg-white border-b border-gray-200 items-center pr-6">
        {customerToken ? (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              sessionStorage.removeItem("customerToken");
              window.location.href = "/admin";
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${"arrow-close.png"}`}
              className="h-6 w-6"
              alt="toggle"
            />
            <p>Back To Admin</p>
          </div>
        ) : (
          <div></div>
        )}
        <div className="flex gap-5 items-center">
          {!isActiveSubscription && (
            <div
              className="mr-4 text-red-500 font-medium cursor-pointer"
              onClick={handleUpgradeClick}
            >
              Upgrade Plan
            </div>
          )}
          <div className="font-normal text-sm bg-[#F3FBF6] py-1 px-3 rounded flex items-center justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/dollor.png`}
              alt="User Avatar"
              className="h-6 w-6 mr-1"
            />

            <span className="text-text-color text-sm font-normal">
              <span id="custom-credit">
                {cred < numOfCredits ? cred : numOfCredits}{" "}
              </span>{" "}
              Credits Remaining
            </span>
          </div>
          <div className="relative flex items-center ml-4">
            <img
              src={
                userData?.profile_image
                  ? `${process.env.REACT_APP_IMAGE_URL}/${userData.profile_image}`
                  : `https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg`
              }
              alt="User Avatar"
              className="h-10 w-10 rounded-full ml-2 object-cover"
            />
            <div
              className="flex flex-col items-start ml-2 cursor-pointer"
              onClick={customerToken ? null : toggleDropdown}
            >
              <span className="text-sm font-bold leading-5 text-text-color hidden md:block">
                {userData?.name}
              </span>
              <span className="text-sm font-normal text-dark-green hidden md:block">
                {checkCustomer === true ? "Admin" : "User"}
              </span>
            </div>
            {!customerToken && (
              <div className="ml-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/down-arrow.png`}
                  className="h-6 w-6 cursor-pointer"
                  alt="toggle"
                  onClick={customerToken ? null : toggleDropdown}
                />
              </div>
            )}

            {/* Dropdown */}
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-24 bg-white border border-gray-200 rounded-lg shadow-lg w-36 z-50"
                onClick={handleLogout}
              >
                <ul className="py-2 ">
                  <li className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 gap-2">
                    <Icons.LogOut />
                    <span className="text-sm text-gray-700">Logout</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
