"use client";

import { useState } from "react";
import { Button } from "./buttons";

export default function FileUploader() {
  const [file, setFile] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      // In a real application, you would handle the file upload here
      // For this example, we'll just set the file size as the uploaded size
      setFile(file);
    }
  };

  return (
    <div className="flex items-center space-x-4 border rounded-sm py-1 px-3">
      <div className="relative">
        <input
          type="file"
          onChange={handleFileChange}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          accept=".csv"
        />
        <Button className="bg-emerald-500 hover:bg-emerald-600 text-white">
          Upload File
        </Button>
      </div>
      {<span className="">{file?.name}</span>}
    </div>
  );
}
