import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/apiServices";

// Create an async thunk for fetching credit information
export const fetchCreditInfo = createAsyncThunk(
  "credit/fetchCreditInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GET("payment/credit-prices"); // Call the API to fetch credit information
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

// Create an async thunk for creating a credit entry
export const createCredit = createAsyncThunk(
  "credit/createCredit",
  async (creditData, { rejectWithValue }) => {
    try {
      const response = await POST("payment/buy-credits", creditData); // Call the API to create a credit entry
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

// Create the credit slice
const creditSlice = createSlice({
  name: "credit",
  initialState: {
    creditInfo: [], // Define an array to store credit information
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreditInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.creditInfo = action.payload; // Store the credit info
      })
      .addCase(fetchCreditInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle the error state
      });
  },
});

// Export the reducer
export default creditSlice.reducer;
