import { Link } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";

export default function CreditHistory() {
  const headers = ["Date", "Amount", "Balance"];
  const data = [];

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} length={1} />
    </div>
  );
}
