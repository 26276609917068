import { Link } from "react-router-dom";
import Icons from "../../assets/icons";

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300 pt-[92px] pb-4">
      <div className="container w-full flex justify-center items-center mb-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:text-left text-center w-[80%]">
          <div className="px-[9px]">
            <h2 className="text-lime-400 text-xl font-bold mb-4 flex items-center lg:justify-start justify-center">
              <Icons.FooterLogo />
            </h2>
            <p className="mb-4">
              Excellent B2B Lead Database With Real Time Verification
              Technologies.
            </p>
            <div className="flex space-x-4 lg:justify-start justify-center">
              <a
                href="https://www.facebook.com/limeleads"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons.FBIcon />
              </a>
              <a
                href="https://x.com/LimeLeads"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons.XIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/limeleads"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons.LinkdinIcon />
              </a>
              <a
                href="https://join.slack.com/t/limeleadsco/shared_invite/zt-2s4f6ho11-JfbNlf7dkBVBMzHos~zYJQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/images/Slack.svg"
                  alt="Slack"
                  className="w-6 h-6"
                />
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-white text-xl font-medium mb-4">Pages</h3>
            <ul className="space-y-4">
              <li>
                <Link to="https://www.limeleads.com/about/">
                  <div className="footer-link">About us</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/pricing/">
                  <div className="footer-link">Pricing</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/contact-us/">
                  <div className="footer-link">Contact us</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/travel-agencies/">
                  <div className="footer-link">Travel Agencies</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/restaurants/">
                  <div className="footer-link">Restaurants</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/photography-videography/">
                  <div className="footer-link">Photography and Videography</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/gas-station-owners/">
                  <div className="footer-link">Gas Station</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/privacy-center/california-privacy-statement/">
                  <div className="footer-link">
                    California Privacy Policy Statement
                  </div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/do-not-sell-my-data-request/">
                  <div className="footer-link">Do Not Sell My Data Request</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/limeleads-vs-apollo-io/">
                  <div className="footer-link">Limeleads vs Apollo.io</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/cold-email-playbook/">
                  <div className="footer-link">Cold email playbook</div>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-white text-xl font-medium mb-4">Legal</h3>
            <ul className="space-y-4">
              <li>
                <Link to="https://www.limeleads.com/privacy-policy/">
                  <div className="footer-link">Privacy Policy</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/data-privacy/">
                  <div className="footer-link">Data Privacy</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/spam-policy/">
                  <div className="footer-link">Spam Policy</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/terms-of-service/">
                  <div className="footer-link">Terms of Service</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/privacy-center/">
                  <div className="footer-link">Privacy Center</div>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-white text-xl font-medium mb-4">Resources</h3>
            <ul className="space-y-4">
              <li>
                <Link to="https://www.limeleads.com/resources/">
                  <div className="footer-link">Blog</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/explore-our-data/">
                  <div className="footer-link">Explore our data</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/request-remove-data/">
                  <div className="footer-link">Request Remove Data</div>
                </Link>
              </li>
              <li>
                <Link to="https://www.limeleads.com/data-access-request/">
                  <div className="footer-link">Data Access Request</div>
                </Link>
              </li>
            </ul>
            <h3 className="text-white text-xl font-medium mb-4 mt-8">
              Contact info:
            </h3>
            <p>team@limeleads.com</p>
            <br />
            <div className="flex flex-col -mt-2">
              <p>LimeLeads LLC</p>
              <p>New York, USA</p>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="mt-8 pt-8 border-t border-gray-700 text-center">
        <p>&copy; {new Date().getFullYear()} Limeleads. All rights reserved.</p>
      </div>
    </footer>
  );
}
