import React, { useState, useEffect, useRef } from "react";
import { Input } from "../../atoms/input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../atoms/buttons";
import { useDispatch, useSelector } from "react-redux";
import { signup, fetchCompanyData } from "../../../redux/slices/authSlice";
import toast from "react-hot-toast";
import Spinner from "../../atoms/Spinner";
import { fetchUserDetail } from "../../../redux/slices/getUserDetailSlice";

const SignupForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyData, loading, error } = useSelector((state) => state.auth);
  const companyEmail = companyData?.data?.email;
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("slug");
  const priceId = searchParams.get("package"); // Getting priceId from URL
  const [id, setId] = useState(null);
  const apiCalled = useRef(false); // Ref to track if API was called
  const [isLoading, setIsLoading] = useState(false);
  // Form state
  const [formData, setFormData] = useState({
    email: companyId ? companyEmail : "",
    password: "",
    companyName: "", // Add company name field to form data
  });

  const token = localStorage.getItem("userToken");
  if (token) {
    window.location.href = "/onboard";
  }

  console.log("companyId", companyId);

  const fetchCompanyId = async () => {
    if (companyId && !apiCalled.current) {
      try {
        const result = await dispatch(fetchCompanyData(companyId));
        setId(result?.payload?.data?.company_id);
        apiCalled.current = true;
      } catch (error) {
        console.error("Failed to fetch company data:", error);
      }
    }
  };

  useEffect(() => {
    if (companyId && !companyData && !apiCalled.current) {
      fetchCompanyId();
    }
  }, [companyId, companyData]);

  // Form validation errors
  const [errors, setErrors] = useState({});

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.trim()) {
      newErrors.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Give a valid email address.";
    }
    if (!formData.password.trim()) {
      newErrors.password = "This field is required.";
    } else if (formData.password.length < 8) {
      newErrors.password = "Password must have at least 8 characters.";
    }
    // Validate company name if priceId is present
    if (priceId && !formData.companyName.trim()) {
      newErrors.companyName = "Company name is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle input change
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();

    if (validateForm()) {
      setIsLoading(true);

      const dataToSend = { ...formData };

      if (id) {
        dataToSend.company_id = id;
      }
      if (companyId) {
        dataToSend.slug = companyId;
      }
      if (companyId && companyEmail) {
        dataToSend.email = companyEmail;
      }

      // Include companyName and priceId if priceId is present
      if (priceId) {
        dataToSend.companyName = formData.companyName;
        dataToSend.priceId = priceId; // Include priceId in the data
      }

      try {
        const result = await dispatch(signup(dataToSend));

        if (result.meta.requestStatus === "fulfilled") {
          const token = result?.payload?.token;
          localStorage.setItem("userToken", token);
          if (token) {
            navigate("/onboard");
          }
          toast.success(
            "Signup successful! Check your email for verification."
          );
          setFormData({
            email: companyId ? companyEmail : "",
            password: "",
            companyName: "", // Reset companyName
          });
          setErrors({});
        } else {
          toast.error(result.payload?.message || "Signup failed");
        }
      } catch (error) {
        console.error("Signup failed:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="lg:w-1/2 max-w-md w-full flex flex-col items-start justify-center mx-auto lg:px-5">
      <h1 className=" text-start items-start text-[#1d201d] lg:text-6xl text-3xl font-bold leading-[68px]">
        Sign up
      </h1>
      <div className=" text-[#313d31] text-xl font-normal  my-4 leading-7">
        Please enter your details.
      </div>
      <form onSubmit={handleSubmit} className="w-full">
        {priceId && (
          <div className="flex flex-col items-start justify-center align-baseline w-full mb-4">
            <label htmlFor="companyName">Company Name</label>
            <Input
              id="companyName"
              name="companyName"
              type="text"
              placeholder="Your company name"
              value={formData.companyName} // Make sure this value is correct
              onChange={(e) => handleInputChange("companyName", e.target.value)} // Correct onChange handler
              className="!w-full text-[#313d31]"
              error={errors.companyName}
            />
          </div>
        )}

        <div className="flex flex-col items-start align-baseline w-full">
          <label htmlFor="Email">Email</label>
          <Input
            id="Email"
            name="email"
            type="email"
            placeholder="name@gmail.com"
            value={companyId ? companyEmail : formData.email}
            readOnly={!!companyId}
            onChange={(e) => handleInputChange("email", e.target.value)}
            className="!w-full text-[#313d31]"
            error={errors.email}
          />
        </div>

        <div className="flex flex-col items-start justify-center align-baseline w-full mt-4">
          <label htmlFor="password">Password</label>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="Your password"
            value={formData.password}
            onChange={(e) => handleInputChange("password", e.target.value)}
            className="!w-full text-[#313d31]"
            error={errors.password}
          />
        </div>

        <p className="text-[#313d31] text-sm font-normal mt-6 text-left">
          By submitting this form, you are agreeing to our{" "}
          <a
            href="https://www.limeleads.com/terms-of-service/"
            target="_blank"
            className="text-[#50c878] underline"
          >
            Terms of Service
          </a>{" "}
          <a
            href="https://www.limeleads.com/privacy-policy/"
            target="_blank"
            className="text-[#50c878] underline"
          >
            Privacy Policy
          </a>
          .
        </p>

        <Button
          className="bg-[#50c878] w-full text-center text-white text-xl font-medium leading-7 rounded justify-center items-center my-4"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : "Sign up"}
        </Button>
        <Link
          to="/"
          className="text-[#313d31] text-base font-normal flex justify-center w-full leading-normal"
        >
          Already have an account? &nbsp;
          <span className="text-[#50c878] text-base font-bold leading-normal">
            Login
          </span>
        </Link>
      </form>
    </div>
  );
};

export default SignupForm;
