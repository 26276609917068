import React, { useEffect } from "react";
import CreditActivity from "../components/organisms/home/CreditActivity";
import ActivityGraph from "../components/organisms/home/ActivityGraph";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreditHistory } from "../redux/slices/creditHistorySlice";
import RequestData from "../components/organisms/home/RequestData";

const Home = () => {
  const dispatch = useDispatch();

  const { creditHistory } = useSelector((state) => state.getCreditHistory);

  console.log("creditHistory", creditHistory?.data);

  useEffect(() => {
    dispatch(fetchCreditHistory());
  }, []);

  return (
    <div className="px-6 py-6 w-full">
      <h3 className="font-bold text-xl text-text-color mb-2">Home</h3>
      <p className="font-normal text-base text-dark-green">
        View your recent activity
      </p>

      {creditHistory?.data?.activities?.length === 0 &&
      creditHistory?.data?.creditHistory?.length === 0 ? (
        <div className="flex justify-center items-center h-[50vh]">
          <p className="text-2xl text-gray-500">No data found</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
          <CreditActivity data={creditHistory?.data} />
          <ActivityGraph data={creditHistory?.data} />
        </div>
      )}
      <div className="mt-8">
        <RequestData />
      </div>
    </div>
  );
};

export default Home;
