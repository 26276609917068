import React, { useEffect, useState } from "react";
import Billings from "../components/organisms/billing/Billings";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillingInfo } from "../redux/slices/billingSlice";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Billing = () => {
  const dispatch = useDispatch();
  const { billingInfo, loading, error } = useSelector((state) => state.billing);
  const location = useLocation();
  const navigate = useNavigate();
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const success = params.get("success");

    if (success && !toastShown) {
      toast.success("Subscription upgraded successfully");
      setToastShown(true);
      params.delete("success");
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [location.search, toastShown, navigate]);

  return (
    <div>
      <div className="px-6 py-6 w-full">
        <div className="mt-6">
          <Billings billingInfo={billingInfo} />
        </div>
      </div>
    </div>
  );
};

export default Billing;
