import { useParams } from "react-router-dom";
import Table from "../../molecules/Table";
import Counter from "../../atoms/Counter";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginAsCustomer,
  submitCount,
} from "../../../redux/slices/counterSlice";
import toast from "react-hot-toast";

export default function Exports({ customerDetails }) {
  const dispatch = useDispatch();
  const { customerData } = useSelector((state) => state.counter);
  const { id: customerId } = useParams();
  console.log("sdsdsdsdgsdgsdg", customerData?.token);
  // States
  const [addCount, setAddCount] = useState(0);
  const [removeCount, setRemoveCount] = useState(0);
  const customerEmail = customerDetails?.data?.customer?.email;

  const handleSubmit = async (actionType, amount) => {
    toast.dismiss();
    if (amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }

    try {
      const result = await dispatch(
        submitCount({
          customerId,
          amount,
          action: actionType,
        })
      );

      if (result.meta.requestStatus === "fulfilled") {
        if (actionType === "add") {
          toast.success("Customer credits added successfully");
          setAddCount(0);
        } else if (actionType === "deduct") {
          toast.success("Customer credits deducted successfully");
          setRemoveCount(0);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLoginAsCustomer = async () => {
    try {
      const result = await dispatch(loginAsCustomer({ customerId }));
      if (result.meta.requestStatus === "fulfilled") {
        const token = result.payload.token;
        sessionStorage.setItem("customerToken", token);
        window.location.href = `${window.location.origin}/database`;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const headers = ["Add credits", "Remove credits", "Login as"];
  const data = [
    [
      <div className="flex gap-1">
        <Counter count={addCount} setCount={setAddCount} />
        <button
          className="px-3 py-1 bg-lime-green hover:opacity-90 rounded text-white text-xs font-medium h-7"
          onClick={() => handleSubmit("add", addCount)}
        >
          Add
        </button>
      </div>,

      <div className="flex gap-1">
        <Counter count={removeCount} setCount={setRemoveCount} />
        <button
          className="px-3 py-1 bg-[#fb4747] hover:opacity-90 rounded text-white text-xs font-medium h-7"
          onClick={() => handleSubmit("deduct", removeCount)}
        >
          Remove
        </button>
      </div>,

      <button
        className="px-3 py-1 bg-lime-green hover:opacity-90 rounded text-white text-xs font-medium h-7"
        onClick={handleLoginAsCustomer}
      >
        Login as {customerEmail}
      </button>,
    ],
  ];

  return (
    <div className="w-full">
      <Table headers={headers} data={data} length={1} />
    </div>
  );
}
