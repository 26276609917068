import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "../../atoms/FormLabel";
import { Button } from "../../atoms/buttons";
import StepHeading from "../../atoms/StepHeading";
import { updateForm } from "../../../redux/slices/onBoardingSlice";
import Icons from "../../../assets/icons";

const AchieveStep = ({ onBack, onNext }) => {
  const dispatch = useDispatch();

  // Retrieve values from Redux state
  const achievementGoalFromRedux = useSelector(
    (state) => state.onBoarding.achievementGoal
  );
  const otherGoalFromRedux = useSelector(
    (state) => state.onBoarding.otherGoalText
  );

  // Local state management
  const [achievementGoal, setAchievementGoal] = useState([]);
  const [errors, setErrors] = useState({});
  const [otherGoal, setOtherGoal] = useState(otherGoalFromRedux); // Initialize with Redux value

  useEffect(() => {
    // Ensure achievementGoal is always an array
    setAchievementGoal(
      Array.isArray(achievementGoalFromRedux) ? achievementGoalFromRedux : []
    );
  }, [achievementGoalFromRedux]);

  useEffect(() => {
    // Set otherGoal from Redux whenever it changes
    setOtherGoal(otherGoalFromRedux);
  }, [otherGoalFromRedux]);

  const goals = [
    { id: "email-data", label: "Looking for email data to grow my business." },
    {
      id: "phone-numbers",
      label: "Looking for phone numbers to grow my business.",
    },
    { id: "other", label: "Other" },
  ];

  const handleGoalChange = (label) => {
    let updatedGoals;
    if (label === "Other") {
      // Toggle the "Other" goal
      if (achievementGoal.includes("")) {
        updatedGoals = achievementGoal.filter((goal) => goal !== "");
        setOtherGoal(""); // Clear the otherGoal state
      } else {
        updatedGoals = [...achievementGoal, ""];
      }
    } else {
      if (achievementGoal.includes(label)) {
        updatedGoals = achievementGoal.filter((goal) => goal !== label);
      } else {
        updatedGoals = [...achievementGoal, label];
      }
    }
    dispatch(updateForm({ achievementGoal: updatedGoals }));

    // Handle error state
    if (updatedGoals.length > 0) {
      setErrors((prev) => ({ ...prev, goal: "" }));
    }
  };

  const handleOtherGoalChange = (e) => {
    const value = e.target.value;
    setOtherGoal(value); // Update local state
    dispatch(updateForm({ otherGoalText: value })); // Update Redux state directly
  };

  const validateSelection = () => {
    const newErrors = {};
    if (!achievementGoal || achievementGoal.length === 0) {
      newErrors.goal = "Please select at least one goal.";
    }
    if (achievementGoal.includes("") && !otherGoal.trim()) {
      newErrors.otherGoal = "Please specify your other goal.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateSelection()) {
      onNext();
    }
  };

  const isOtherSelected = achievementGoal.includes("");

  return (
    <div>
      <StepHeading>Let's get your LimeLeads account set up</StepHeading>
      <div className="mb-8">
        <h2 className="text-[1rem] font-bold text-[#313D31] mb-4">
          What are you hoping to purpose with LimeLeads?
        </h2>
        <div className="space-y-2">
          {goals.map((goal) => (
            <div key={goal.label} className="flex items-center space-x-2">
              <input
                type="checkbox"
                id={goal.id}
                checked={
                  goal.label === "Other"
                    ? isOtherSelected
                    : achievementGoal.includes(goal.label)
                }
                onChange={() => handleGoalChange(goal.label)}
                className="hidden peer"
              />
              <label
                htmlFor={goal.id}
                className={`flex items-center justify-center w-5 h-5 rounded border-2 cursor-pointer transition-colors !ml-0
                  ${
                    (
                      goal.label === "Other"
                        ? isOtherSelected
                        : achievementGoal.includes(goal.label)
                    )
                      ? "bg-green-500 border-green-500"
                      : "bg-white border-gray-300"
                  }`}
              >
                {(goal.label === "Other"
                  ? isOtherSelected
                  : achievementGoal.includes(goal.label)) && (
                  <span className="text-white">
                    <Icons.checkBoxTick />
                  </span>
                )}
              </label>
              <FormLabel
                htmlFor={goal.id}
                className="text-sm font-normal text-[#313D31]"
              >
                {goal.label}
              </FormLabel>
            </div>
          ))}
        </div>
        {errors.goal && (
          <p className="text-red-500 text-sm mb-4">{errors.goal}</p>
        )}
        {isOtherSelected && (
          <div className="mt-4">
            <textarea
              value={otherGoal}
              onChange={handleOtherGoalChange}
              placeholder="Please specify your other goal"
              className="w-full max-w-[25rem] p-2 border rounded"
              rows="3"
              cols="10"
            />
            {errors.otherGoal && (
              <p className="text-red-500 text-sm mt-1">{errors.otherGoal}</p>
            )}
          </div>
        )}
      </div>
      <div className="flex items-center gap-3">
        <Button
          className="bg-[#1D201D] text-white font-medium py-2 px-6"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          className="bg-lime-green hover:bg-lime-green/90 text-white font-medium py-2 px-6"
          onClick={handleNext}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default AchieveStep;
