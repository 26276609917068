import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast"; // Import toast
import { POST } from "../../services/apiServices";

export const sendInvites = createAsyncThunk(
  "invite/sendInvites",
  async (emails, { rejectWithValue }) => {
    try {
      const response = await POST("/invite/create", {
        invitee_emails: emails,
      }); // Use invitee_emails key
      return response.data; // Assuming the API returns the data you need
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const inviteSlice = createSlice({
  name: "invite",
  initialState: {
    loading: false,
    error: null,
    success: false,
    invite: null,
  },
  reducers: {
    resetInviteState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendInvites.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendInvites.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.invite = action.payload;
      })
      .addCase(sendInvites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetInviteState } = inviteSlice.actions;

export default inviteSlice.reducer;
