import { useDispatch, useSelector } from "react-redux";
import CustomTable from "./Table";
import { useEffect } from "react";
import { fetchCompanyUser } from "../../redux/slices/getCompanyUserSlice";
import TableSkeleton from "./TableSkeleton";
import toast, { Toaster } from "react-hot-toast";
import { PATCH } from "../../services/apiServices";

export default function CompanyUser() {
  const dispatch = useDispatch();
  const { getCompanyUser, loading, error } = useSelector(
    (state) => state.getCompanyUser
  );
  const userDetail = useSelector(
    (state) => state?.userDetail?.userDetail?.data
  );

  const companyUser = getCompanyUser?.data?.customers || [];

  useEffect(() => {
    dispatch(fetchCompanyUser());
  }, []);

  const headers = ["#", "Name", "Email", "Permissions"];

  const toggleCompanyAdmin = async (userId) => {
    try {
      const response = await PATCH(`/customer/${userId}/toggle-company-admin`);

      toast.success("Company user status updated successfully");
      dispatch(fetchCompanyUser()); // Fetch updated company user list
    } catch (error) {
      if (error.response.status === 403) {
        return toast.error(
          error.response.data.message ||
            "You are not allowed to change the admin status of this user"
        );
      }
      toast.error(
        error.message ? error.message : "Error while toggleing company admin"
      );
    }
  };

  const data = companyUser?.map((search, index) => [
    index + 1,
    search?.name,
    search?.email,
    <div>
      Admin Permissions
      {search.id !== userDetail?.customer?.id &&
        search.id !== userDetail?.customer?.company?.created_by_customer_id &&
        (search.is_company_admin ? (
          <span
            className="ml-2 text-lime-green font-bold text-sm cursor-pointer"
            onClick={() => {
              toggleCompanyAdmin(search.id);
            }}
          >
            Change to Standard
          </span>
        ) : (
          <span
            className="ml-2 text-lime-green font-bold text-sm cursor-pointer"
            onClick={() => {
              toggleCompanyAdmin(search.id);
            }}
          >
            Change to Admin
          </span>
        ))}
    </div>,
  ]);

  if (error) {
    return <div>Error: {JSON.stringify(error)}</div>; // Error state
  }

  return (
    <div className=" bg-[#fbfdfb] min-h-screen">
      <div className="">
        <div class="text-[#1d201d] text-xl font-bold my-3 leading-7">
          Your company’s users
        </div>
      </div>
      {loading ? (
        <div className="px-4">
          <TableSkeleton rows={10} columns={6} header={false} />
        </div>
      ) : data?.length === 0 ? (
        <div className="flex justify-center items-center h-[50vh]">
          <p className="text-2xl text-gray-500">No data found</p>
        </div>
      ) : (
        <CustomTable headers={headers} data={data} length={data?.length} />
      )}
    </div>
  );
}
