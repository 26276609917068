import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../services/apiServices"; // Assuming you have a GET method in your apiServices

export const fetchCompanyPendingInvites = createAsyncThunk(
  "companyPendingInvites/fetchCompanyPendingInvites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GET("/invite"); // Call the API
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

const companyPendingInvitesSlice = createSlice({
  name: "companyPendingInvites",
  initialState: {
    companyPendingInviteData: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetCompanyPendingInvitesState: (state) => {
      state.loading = false;
      state.error = null;
      state.companyPendingInviteData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyPendingInvites.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanyPendingInvites.fulfilled, (state, action) => {
        state.loading = false;
        state.companyPendingInviteData = action.payload; // Store the fetched data
      })
      .addCase(fetchCompanyPendingInvites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetCompanyPendingInvitesState } =
  companyPendingInvitesSlice.actions;

export default companyPendingInvitesSlice.reducer;
