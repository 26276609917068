// src/data/optionsData.js

export const jobOptions = [
  { value: "Director of Operations", label: "Director of Operations" },
  { value: "Director of Sales", label: "Director of Sales" },
  { value: "Director of Marketing", label: "Director of Marketing" },
  { value: "Director of Finance", label: "Director of Finance" },
  {
    value: "Human Resources (HR) Manager",
    label: "Human Resources (HR) Manager",
  },
  { value: "Product Manager", label: "Product Manager" },
  {
    value: "Business Development Manager",
    label: "Business Development Manager",
  },
  { value: "Software Developer", label: "Software Developer" },
  { value: "Full Stack Developer", label: "Full Stack Developer" },
  { value: "Front-end Developer", label: "Front-end Developer" },
  { value: "Back-end Developer", label: "Back-end Developer" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  {
    value: "Quality Assurance (QA) Engineer",
    label: "Quality Assurance (QA) Engineer",
  },
  { value: "Customer Support Manager", label: "Customer Support Manager" },
  { value: "CEO", label: "CEO" },
  { value: "COO", label: "COO" },
  { value: "CFO", label: "CFO" },
  { value: "CMO", label: "CMO" },
  { value: "CTO", label: "CTO" },
  { value: "CIO", label: "CIO" },
  { value: "CPO", label: "CPO" },
  { value: "CHRO", label: "CHRO" },
  { value: "VP", label: "VP" },
];

export const jobTitleAbbreviations = {
  CEO: { label: "Chief Executive Officer", value: "Chief Executive Officer" },
  CFO: { label: "Chief Financial Officer", value: "Chief Financial Officer" },
  CMO: { label: "Chief Marketing Officer", value: "Chief Marketing Officer" },
  CTO: {
    label: "Chief Technology Officer",
    value: "Chief Technology Officer",
  },
  COO: { label: "Chief Operating Officer", value: "Chief Operating Officer" },
  CIO: {
    label: "Chief Information Officer",
    value: "Chief Information Officer",
  },
  CRO: { label: "Chief Revenue Officer", value: "Chief Revenue Officer" },
  VP: { label: "Vice President", value: "Vice President" },
  SVP: { label: "Senior Vice President", value: "Senior Vice President" },
  EVP: {
    label: "Executive Vice President",
    value: "Executive Vice President",
  },
  HR: { label: "Human Resources", value: "Human Resources" },
  GM: { label: "General Manager", value: "General Manager" },
  IT: { label: "Information Technology", value: "Information Technology" },
};

export const US_STATES = [
  { value: "Alabama", label: "Alabama", abbreviation: "AL" },
  { value: "Alaska", label: "Alaska", abbreviation: "AK" },
  { value: "Arizona", label: "Arizona", abbreviation: "AZ" },
  { value: "Arkansas", label: "Arkansas", abbreviation: "AR" },
  { value: "California", label: "California", abbreviation: "CA" },
  { value: "Colorado", label: "Colorado", abbreviation: "CO" },
  { value: "Connecticut", label: "Connecticut", abbreviation: "CT" },
  { value: "Delaware", label: "Delaware", abbreviation: "DE" },
  {
    value: "District of Columbia",
    label: "District of Columbia",
    abbreviation: "DC",
  },
  { value: "Florida", label: "Florida", abbreviation: "FL" },
  { value: "Georgia", label: "Georgia", abbreviation: "GA" },
  { value: "Hawaii", label: "Hawaii", abbreviation: "HI" },
  { value: "Idaho", label: "Idaho", abbreviation: "ID" },
  { value: "Illinois", label: "Illinois", abbreviation: "IL" },
  { value: "Indiana", label: "Indiana", abbreviation: "IN" },
  { value: "Iowa", label: "Iowa", abbreviation: "IA" },
  { value: "Kansas", label: "Kansas", abbreviation: "KS" },
  { value: "Kentucky", label: "Kentucky", abbreviation: "KY" },
  { value: "Louisiana", label: "Louisiana", abbreviation: "LA" },
  { value: "Maine", label: "Maine", abbreviation: "ME" },
  { value: "Maryland", label: "Maryland", abbreviation: "MD" },
  { value: "Massachusetts", label: "Massachusetts", abbreviation: "MA" },
  { value: "Michigan", label: "Michigan", abbreviation: "MI" },
  { value: "Minnesota", label: "Minnesota", abbreviation: "MN" },
  { value: "Mississippi", label: "Mississippi", abbreviation: "MS" },
  { value: "Missouri", label: "Missouri", abbreviation: "MO" },
  { value: "Montana", label: "Montana", abbreviation: "MT" },
  { value: "Nebraska", label: "Nebraska", abbreviation: "NE" },
  { value: "Nevada", label: "Nevada", abbreviation: "NV" },
  { value: "New Hampshire", label: "New Hampshire", abbreviation: "NH" },
  { value: "New Jersey", label: "New Jersey", abbreviation: "NJ" },
  { value: "New Mexico", label: "New Mexico", abbreviation: "NM" },
  { value: "New York", label: "New York", abbreviation: "NY" },
  { value: "North Carolina", label: "North Carolina", abbreviation: "NC" },
  { value: "North Dakota", label: "North Dakota", abbreviation: "ND" },
  { value: "Ohio", label: "Ohio", abbreviation: "OH" },
  { value: "Oklahoma", label: "Oklahoma", abbreviation: "OK" },
  { value: "Oregon", label: "Oregon", abbreviation: "OR" },
  { value: "Pennsylvania", label: "Pennsylvania", abbreviation: "PA" },
  { value: "Rhode Island", label: "Rhode Island", abbreviation: "RI" },
  { value: "South Carolina", label: "South Carolina", abbreviation: "SC" },
  { value: "South Dakota", label: "South Dakota", abbreviation: "SD" },
  { value: "Tennessee", label: "Tennessee", abbreviation: "TN" },
  { value: "Texas", label: "Texas", abbreviation: "TX" },
  { value: "Utah", label: "Utah", abbreviation: "UT" },
  { value: "Vermont", label: "Vermont", abbreviation: "VT" },
  { value: "Virginia", label: "Virginia", abbreviation: "VA" },
  { value: "Washington", label: "Washington", abbreviation: "WA" },
  { value: "West Virginia", label: "West Virginia", abbreviation: "WV" },
  { value: "Wisconsin", label: "Wisconsin", abbreviation: "WI" },
  { value: "Wyoming", label: "Wyoming", abbreviation: "WY" },
];

export const Countries = [
  { value: "United States", label: "United States" },
  { value: "Americas", label: "Americas" },
  { value: "North America", label: "North America" },
  { value: "EMEA", label: "EMEA" },
  { value: "Dallas/Fort Worth Area", label: "Dallas/Fort Worth Area" },
];

export const Companies = [
  { value: "Walmart", label: "Walmart" },
  { value: "Amazon", label: "Amazon" },
  { value: "Apple", label: "Apple" },
  { value: "Txlabz", label: "Txlabz" },
  { value: "ExxonMobil", label: "ExxonMobil" },
  { value: "Berkshire Hathaway", label: "Berkshire Hathaway" },
  { value: "UnitedHealth Group", label: "UnitedHealth Group" },
  { value: "CVS Health", label: "CVS Health" },
  {
    value: "Alphabet (Google's parent company)",
    label: "Alphabet (Google's parent company)",
  },
  { value: "McKesson", label: "McKesson" },
  { value: "Cigna", label: "Cigna" },
  { value: "Costco Wholesale", label: "Costco Wholesale" },
  { value: "AT&T", label: "AT&T" },
  { value: "Microsoft", label: "Microsoft" },
  { value: "Cardinal Health", label: "Cardinal Health" },
  { value: "Chevron", label: "Chevron" },
  { value: "Ford Motor", label: "Ford Motor" },
  { value: "AmerisourceBergen", label: "AmerisourceBergen" },
  { value: "JPMorgan Chase", label: "JPMorgan Chase" },
  { value: "General Motors", label: "General Motors" },
  { value: "Walgreens Boots Alliance", label: "Walgreens Boots Alliance" },
  { value: "Verizon Communications", label: "Verizon Communications" },
  { value: "Kroger", label: "Kroger" },
  { value: "Bank of America", label: "Bank of America" },
  { value: "Wells Fargo", label: "Wells Fargo" },
  { value: "Anthem", label: "Anthem" },
  { value: "Tesla", label: "Tesla" },
  { value: "Home Depot", label: "Home Depot" },
  { value: "Pfizer", label: "Pfizer" },
  { value: "Citigroup", label: "Citigroup" },
  { value: "Comcast", label: "Comcast" },
  { value: "Meta Platforms (Facebook)", label: "Meta Platforms (Facebook)" },
  { value: "Boeing", label: "Boeing" },
  { value: "Target", label: "Target" },
  { value: "Lowe's", label: "Lowe's" },
  { value: "Johnson & Johnson", label: "Johnson & Johnson" },
  { value: "IBM", label: "IBM" },
  { value: "Procter & Gamble", label: "Procter & Gamble" },
  { value: "Goldman Sachs", label: "Goldman Sachs" },
  { value: "Dell Technologies", label: "Dell Technologies" },
  { value: "PepsiCo", label: "PepsiCo" },
  { value: "Intel", label: "Intel" },
  { value: "Dow", label: "Dow" },
  { value: "FedEx", label: "FedEx" },
  { value: "Raytheon Technologies", label: "Raytheon Technologies" },
  { value: "Disney", label: "Disney" },
  { value: "Merck", label: "Merck" },
  { value: "Humana", label: "Humana" },
  { value: "AbbVie", label: "AbbVie" },
  { value: "Lockheed Martin", label: "Lockheed Martin" },
  { value: "General Electric", label: "General Electric" },
  { value: "Morgan Stanley", label: "Morgan Stanley" },
  { value: "Caterpillar", label: "Caterpillar" },
  { value: "Honeywell International", label: "Honeywell International" },
  { value: "Hewlett Packard Enterprise", label: "Hewlett Packard Enterprise" },
  {
    value: "Enterprise Products Partners",
    label: "Enterprise Products Partners",
  },
  { value: "Capital One Financial", label: "Capital One Financial" },
  { value: "Best Buy", label: "Best Buy" },
  { value: "Northrop Grumman", label: "Northrop Grumman" },
  { value: "Abbott Laboratories", label: "Abbott Laboratories" },
  { value: "Phillips 66", label: "Phillips 66" },
  { value: "Eli Lilly", label: "Eli Lilly" },
  { value: "Marathon Petroleum", label: "Marathon Petroleum" },
  { value: "Progressive", label: "Progressive" },
  { value: "Nike", label: "Nike" },
  { value: "Thermo Fisher Scientific", label: "Thermo Fisher Scientific" },
  { value: "Chubb", label: "Chubb" },
  {
    value: "United Parcel Service (UPS)",
    label: "United Parcel Service (UPS)",
  },
  { value: "Colgate-Palmolive", label: "Colgate-Palmolive" },
  { value: "Newmont Corporation", label: "Newmont Corporation" },
  { value: "ConocoPhillips", label: "ConocoPhillips" },
  { value: "Bristol-Myers Squibb", label: "Bristol-Myers Squibb" },
  { value: "Johnson Controls", label: "Johnson Controls" },
  { value: "Stryker", label: "Stryker" },
  { value: "Estee Lauder", label: "Estee Lauder" },
  { value: "3M", label: "3M" },
  { value: "American Express", label: "American Express" },
  { value: "Southern Company", label: "Southern Company" },
  { value: "Charter Communications", label: "Charter Communications" },
  { value: "Qualcomm", label: "Qualcomm" },
  { value: "Uber Technologies", label: "Uber Technologies" },
  { value: "General Dynamics", label: "General Dynamics" },
  { value: "Mastercard", label: "Mastercard" },
  { value: "Visa", label: "Visa" },
  { value: "Sherwin-Williams", label: "Sherwin-Williams" },
  { value: "Union Pacific", label: "Union Pacific" },
  { value: "Micron Technology", label: "Micron Technology" },
  { value: "PayPal", label: "PayPal" },
  { value: "Booking Holdings", label: "Booking Holdings" },
  { value: "CSX", label: "CSX" },
  { value: "Coca-Cola", label: "Coca-Cola" },
  { value: "Exelon", label: "Exelon" },
  { value: "Kinder Morgan", label: "Kinder Morgan" },
  { value: "Adobe", label: "Adobe" },
  { value: "Kellogg's", label: "Kellogg's" },
  { value: "Mondelez International", label: "Mondelez International" },
  { value: "Lumen Technologies", label: "Lumen Technologies" },
  { value: "Salesforce", label: "Salesforce" },
  { value: "HP Inc.", label: "HP Inc." },
  { value: "Gilead Sciences", label: "Gilead Sciences" },
  { value: "Bristol-Myers Squibb", label: "Bristol-Myers Squibb" },
];

export const Industries = [
  { value: "Accounting", label: "Accounting" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Airlines/Aviation", label: "Airlines/Aviation" },
  {
    value: "Alternative dispute resolution",
    label: "Alternative dispute resolution",
  },
  { value: "Alternative medicine", label: "Alternative medicine" },
];

export const Stacks = [
  { value: "CMS", label: "CMS" },
  { value: "Message Boards", label: "Message Boards" },
  { value: "Database Managers", label: "Database Managers" },
  { value: "Documentation Tools", label: "Documentation Tools" },
  { value: "Widgets", label: "Widgets" },
];
