import React from "react";
import UserManage from "../components/organisms/usermanage/UserManage";

const ManageUser = () => {
  return (
    <div>
      <div className="px-6 py-6 w-full">
        <h3 className="font-bold text-xl text-text-color mb-2">Manage Users</h3>
        <p className="font-normal text-base text-dark-green">
          Invite new users and manage permissions.
        </p>
        <div className="mt-8">
          <UserManage />
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
