import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    // Define the range of pages to show
    if (totalPages <= 4) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= 3) {
      startPage = 1;
      endPage = 4;
    } else if (currentPage >= totalPages - 2) {
      startPage = totalPages - 3;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 2;
    }

    // Always show the first page
    pageNumbers.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={`text-[#50c878] px-4 py-2 rounded border border-[#50c878] ${
          currentPage === 1 ? "bg-green-500 text-white" : ""
        }`}
      >
        1
      </button>
    );

    // Add ellipsis if there are more pages before the startPage
    if (startPage > 2) {
      pageNumbers.push(
        <span key="ellipsis-start" className="px-4 py-2">
          ...
        </span>
      );
    }

    // Create page number buttons
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`text-[#50c878] px-4 py-2 rounded border border-[#50c878] ${
              currentPage === i ? "bg-green-500 text-white" : ""
            }`}
          >
            {i}
          </button>
        );
      }
    }

    // Add ellipsis if there are more pages after the endPage
    if (endPage < totalPages) {
      pageNumbers.push(
        <span key="ellipsis-end" className="px-4 py-2">
          ...
        </span>
      );
    }

    // Always show the last page
    // if (totalPages > 1) {
    //   pageNumbers.push(
    //     <button
    //       key={totalPages}
    //       onClick={() => handlePageChange(totalPages)}
    //       className={`text-[#50c878] px-4 py-2 rounded border border-[#50c878] ${
    //         currentPage === totalPages ? "bg-green-500 text-white" : ""
    //       }`}
    //     >
    //       {totalPages}
    //     </button>
    //   );
    // }

    return pageNumbers;
  };

  return (
    <div className="flex justify-center mt-7 space-x-2">
      {currentPage > 1 && (
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 rounded border border-[#50c878] text-[#50c878]"
        >
          Back
        </button>
      )}

      {renderPageNumbers()}

      {currentPage < totalPages && (
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 rounded border border-[#50c878] text-[#50c878]"
        >
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;
