import { Link } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";

export default function Status() {
  const headers = [
    "Current monthly plan",
    "Next charge",
    "Next renewal of credits",
    "Purchase history",
    "Amount",
  ];
  const data = [
    [
      <div className="flex w-[25%] items-center gap-1">
        <Icons.TitleIcon />
        <div className="text-[#313d31] text-xs font-normal">No Plan</div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.CaleanderIcon />
        <div className="text-[#313d31] text-xs font-normal"> - </div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.clockIcon />
        <div className="text-[#313d31] text-xs font-normal">
          within 24 hrs of charge
        </div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.CaleanderIcon />
        <div className="text-[#313d31] text-xs font-normal"> Date</div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.DollorIcon />
        <div className="text-[#313d31] text-xs font-normal"> $</div>
      </div>,
    ],
  ];

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} length={1} />
    </div>
  );
}
