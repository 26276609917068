import React, { useState } from "react";
import { Button } from "../../atoms/buttons";
import { Input } from "../../atoms/input";
import FormLabel from "../../atoms/FormLabel";
import StepHeading from "../../atoms/StepHeading";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "../../../redux/slices/onBoardingSlice";

const PositionStep = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { position } = useSelector((state) => state.onBoarding);
  const [errors, setErrors] = useState({}); // To manage field errors

  const handleChange = (e) => {
    const { value } = e.target;
    dispatch(updateForm({ position: value })); // Update Redux state
    // Clear error if a position is entered
    setErrors((prev) => ({ ...prev, position: "" }));
  };

  // Validate before proceeding
  const validateForm = () => {
    const newErrors = {};
    if (!position) {
      newErrors.position = "Please enter your position in the company.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle next step
  const handleNext = () => {
    if (validateForm()) {
      onNext(); // Proceed to next step only if valid
    }
  };

  return (
    <div className="">
      <StepHeading>Let's get your LimeLeads account setup</StepHeading>
      <div className="max-w-md">
        <FormLabel htmlFor="position">Your position in the company?</FormLabel>
        <Input
          type="text"
          id="position"
          placeholder="CEO"
          className=""
          name="position"
          value={position}
          onChange={handleChange}
          error={errors.position}
        />
        <div className="flex items-center gap-3 mt-7">
          <Button
            className="bg-[#1D201D] text-white font-medium py-2 px-6"
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            className="bg-lime-green hover:bg-lime-green/90 text-white font-medium py-2 px-6"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PositionStep;
