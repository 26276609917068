import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "../../services/apiServices";

// Async thunk for creating a customer
export const createCustomer = createAsyncThunk(
  "customer/create",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await POST("/customer/admin/create", formData); // Call the API
      console.log("response", response);
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

const createCustomerSlice = createSlice({
  name: "createCustomer",
  initialState: {
    customer: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customer = action.payload;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default createCustomerSlice.reducer;
