// import React from "react";

// export const Input = ({
//   className,
//   required,
//   placeholderClassName,
//   error,
//   ...props
// }) => {
//   return (
//     <input
//       {...props}
//       className={`md:min-w-[26rem] h-[3rem] w-full border rounded-sm px-3 py-2 placeholder:text-[#B1BFB5] placeholder:font-light focus:outline-none ${className}`}
//       placeholder={props.placeholder}
//       required={required}
//     />
//   );
// };

// Input.displayName = "Input";

import React from "react";

export const Input = ({
  className,
  // required,
  placeholderClassName,
  error, // Add error prop to show error message
  ...props
}) => {
  return (
    <div className="w-full">
      <input
        {...props}
        className={`md:min-w-[26rem] h-[3rem] w-full border rounded-sm px-3 py-2 placeholder:text-[#B1BFB5] placeholder:font-light focus:outline-none ${className} ${
          error ? "border-red-500" : "border-[#DAE7DE]"
        }`}
        placeholder={props.placeholder}
        // required={required}
      />
      {error && <p className="text-red-500 text-sm mt-1 text-left">{error}</p>}{" "}
      {/* Display error message */}
    </div>
  );
};

Input.displayName = "Input";
