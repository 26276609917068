import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton styles

const TableSkeleton = ({ rows, columns, header }) => {
  return (
    <div>
      {/* Flex container for text and button in same line */}
      {header && (
        <div className="flex justify-between items-center mt-5 mb-5 w-full ">
          {/* Text section */}
          <div className="flex items-center">
            <span className="text-[#1d201d] text-xl font-bold leading-7 ">
              <Skeleton width={100} height={30} />
            </span>
          </div>
        </div>
      )}

      {/* Table skeleton */}
      <div className="shadow-card-shadow rounded-lg px-4">
        <div className="overflow-x-auto p-1.5">
          <table className="w-full rounded">
            <thead>
              <tr className="border-b w-full">
                {Array.from({ length: columns }).map((_, index) => (
                  <th
                    key={index}
                    className="py-4 text-left whitespace-nowrap text-sm text-[#313D31] font-medium leading-tight"
                  >
                    <Skeleton width={`100%`} height={20} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex} className="border-b">
                  {Array.from({ length: columns }).map((_, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="py-4 whitespace-nowrap text-sm text-[#313D31] font-normal"
                    >
                      <Skeleton width={`100%`} height={20} />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableSkeleton;
