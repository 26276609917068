import React, { useEffect, useState } from "react";
import { Button } from "../atoms/buttons";
import Spinner from "../atoms/Spinner";
import CreatableSelect from "react-select/creatable"; // Import CreatableSelect
import { useDispatch, useSelector } from "react-redux";
import { getIndustries } from "../../redux/slices/searchSlice";
import { sendRequestData } from "../../redux/slices/requestDataSlice";
import toast from "react-hot-toast";

const RequestDataPopup = ({ onClose, isLoading, selectIndustry }) => {
  const dispatch = useDispatch();
  console.log("selectIndustry", selectIndustry);
  const ind = selectIndustry.map((item) => ({
    label: item,
    value: item,
  }));
  const [formValues, setFormValues] = useState({
    industries: ind || [], // Array to hold selected industries
    description: "",
  });
  const [errors, setErrors] = useState({});
  const industries = useSelector((state) => state.search.industriesList); // Get industries from Redux state
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(getIndustries());
  }, [dispatch]);

  const industrieslabel = industries?.data?.map((item) => ({
    label: item?.label,
    value: item?.label,
  }));

  const validate = () => {
    let errors = {};

    if (formValues.industries.length === 0) {
      errors.industries = "Please select at least one industry.";
    }

    if (!formValues.description.trim()) {
      errors.description = "Description cannot be empty.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setFormValues({ ...formValues, industries: selectedOptions || [] });

    // Clear error message for the industries field
    if (errors.industries) {
      setErrors((prevErrors) => ({ ...prevErrors, industries: null }));
    }
  };

  const handleRequestData = async () => {
    if (!validate()) {
      return;
    }

    setIsSubmitting(true);

    const requestData = {
      industries: formValues.industries.map((option) => option.value), // Extract values from selected options
      description: formValues.description,
    };

    try {
      // Dispatch the sendRequestData thunk action
      const response = await dispatch(sendRequestData(requestData));
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Data request created successfully"); // Show success toast
        onClose(); // Close the popup after success
      }
    } catch (error) {
      toast.error(error?.message || "Failed to submit request."); // Show error toast
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[60]">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-xl font-bold mb-4">Request Data</h2>

        {/* Industry CreatableSelect Field */}
        <div className="mb-4">
          <label className="block mb-2">Industry</label>
          <CreatableSelect
            isMulti
            name="industries"
            options={industrieslabel}
            className={`basic-multi-select ${
              errors.industries ? "border-red-500" : ""
            }`}
            value={formValues.industries}
            classNamePrefix="select"
            onChange={handleSelectChange}
            placeholder="Select or create industries..."
          />
          {errors.industries && (
            <p className="text-red-500 text-sm mt-1">{errors.industries}</p>
          )}
        </div>

        {/* Description Field */}
        <div className="mb-4">
          <label className="block mb-2">Description</label>
          <textarea
            name="description"
            value={formValues.description}
            onChange={handleInputChange}
            className={`w-full p-2 border rounded ${
              errors.description ? "border-red-500" : ""
            }`}
            placeholder="Enter description"
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end">
          <Button onClick={onClose} className="mr-2 bg-gray-300 text-black">
            Cancel
          </Button>
          <Button
            onClick={handleRequestData}
            className="bg-green-500 text-white"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner size="small" className="mr-2" />
                Submitting...
              </>
            ) : (
              "Request Data"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RequestDataPopup;
