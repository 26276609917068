import React from 'react';
import { Button } from '../atoms/buttons';

const ConfirmationPopup = ({ plan, onConfirm, onCancel, isYearly }) => {
  const yearlyPrice = plan.priceShow * 12;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Confirm Plan Change</h2>
        <p className="mb-4">
          Are you sure you want to change your plan to:
        </p>
        <div className="mb-4">
          <p className="font-bold">{plan.title}</p>
          <p>
            ${isYearly ? yearlyPrice.toFixed(2) : plan.priceShow} / {isYearly ? 'year' : 'month'}
          </p>
          {isYearly && (
            <p className="text-sm text-gray-600">
              (${plan.priceShow} per month, billed annually)
            </p>
          )}
        </div>
        <div className="flex justify-end space-x-4">
          <Button onClick={onCancel} className="bg-gray-300 text-gray-800">
            Cancel
          </Button>
          <Button onClick={onConfirm} className="bg-green-500 text-white">
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
