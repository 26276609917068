import React, { useState } from "react";
import Icons from "../../assets/icons";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="container w-full md:h-[72px] flex justify-center items-center relative lg:shadow-none shadow lg:py-0 py-3">
      <div className="w-[80%] h-full flex justify-between items-center ml-6">
        <img
          src="/images/logo.png"
          class="h-8 w-[165px] hidden md:block object-contain"
        />
        <div className="hidden md:flex gap-5 font-sans font-normal leading-normal text-[#1d201d] text-base ">
          <Link to="https://www.limeleads.com/pricing/">
            <div className="link-style">Pricing</div>
          </Link>
          <Link to="https://www.limeleads.com/our-data/">
            <div className="link-style">Our Data</div>
          </Link>
          <Link to="https://www.limeleads.com/resources/">
            <div className="link-style">Resources</div>
          </Link>
          <Link to="https://www.limeleads.com/affiliate-program/">
            <div className="link-style">Affiliate</div>
          </Link>
          {/* <Link to="https://devlimeleads.wpengine.com/affiliate-program-2/#">
          <div>Solutions</div>
        </Link> */}
        </div>
        <div className="hidden md:flex items-center gap-5">
          <Link to="/">
            <div className="text-center text-[#1d201d] text-base font-normal font-sans leading-normal link-style">
              Login
            </div>
          </Link>
          <Link to="/signup">
            <button className="text-center text-white hover:text-[#50c878] text-base font-medium px-6 py-3 bg-[#50c878] border border-[#50c878] rounded justify-center items-center hover:bg-white">
              Start free trial
            </button>
          </Link>
        </div>
        <button className="md:hidden" onClick={toggleMenu}>
          {isMenuOpen ? <Icons.X /> : <Icons.Menu />}
        </button>
        <div
          className={`fixed inset-y-0 right-0 w-64 bg-white shadow-lg z-50 flex flex-col p-4 transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <button className="self-end mb-4" onClick={toggleMenu}>
            <Icons.X />
          </button>
          <div className="flex flex-col gap-4 font-sans leading-normal text-[#1d201d] text-base">
            <Link to="https://devlimeleads.wpengine.com/pricing/">
              <div>Pricing</div>
            </Link>
            <Link to="https://devlimeleads.wpengine.com/our-data/">
              <div>Our Data</div>
            </Link>
            <Link to="https://devlimeleads.wpengine.com/resources/">
              <div>Resources</div>
            </Link>
            <Link to="https://devlimeleads.wpengine.com/affiliate-program-2/">
              <div>Affiliate</div>
            </Link>
            {/* <Link to="https://devlimeleads.wpengine.com/affiliate-program-2/#">
            <div>Solutions</div>
          </Link> */}
          </div>
          <div className="mt-auto flex flex-col gap-4">
            <div className="text-center text-[#1d201d] text-base font-normal font-sans leading-normal">
              Login
            </div>
            <button className="text-center text-white text-base font-medium px-6 py-3 bg-[#50c878] rounded justify-center items-center">
              Start free trial
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
