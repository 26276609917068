import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/apiServices";

export const fetchBillingInfo = createAsyncThunk(
  "billing/fetchBillingInfo",
  async ({ interval }, { rejectWithValue }) => {
    try {
      const response = await GET(`/payment/products?interval=${interval}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBilling = createAsyncThunk(
  "billing/createBilling",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await POST("/payment/create", postData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBillingSubscription = createAsyncThunk(
  "billing/updateBillingSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await POST("/payment/create/portal-link");
      console.log("Update Billing Subscription Response:", response);
      
      if (response && response.data) {
        return response.data;
      } else if (response && response.url) {
        return { data: response.url };
      } else {
        throw new Error("No redirect URL provided in the response");
      }
    } catch (error) {
      console.error("Update Billing Subscription Error:", error);
      return rejectWithValue(error.message || "Failed to update subscription");
    }
  }
);

export const changeBillingSubscription = createAsyncThunk(
  "billing/changeBillingSubscription",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await POST("/payment/change-subscription", postData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const billingSlice = createSlice({
  name: "billing",
  initialState: {
    billingInfo: {
      monthly: [],
      yearly: [],
    },
    hasActivePlan: false,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBillingInfo.fulfilled, (state, action) => {
        state.loading = false;
        if (action.meta.arg.interval === "month") {
          state.billingInfo.monthly = action.payload.data;
        } else if (action.meta.arg.interval === "year") {
          state.billingInfo.yearly = action.payload.data;
        }
        
        state.hasActivePlan = state.hasActivePlan || action.payload.isActiveSubscription || false;
      })
      .addCase(fetchBillingInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createBilling.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBilling.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createBilling.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changeBillingSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeBillingSubscription.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeBillingSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBillingSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBillingSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(updateBillingSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default billingSlice.reducer;
