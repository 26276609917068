import React from "react";
import LoginForm from "../components/organisms/login/LoginForm";
import LeadGenration from "../components/organisms/login/LeadGenration";

const Login = () => {
  return (
    <div className="flex lg:flex-row flex-col gap-5 justify-center w-full min-h-[82vh] items-center text-center md:mt-10">
      <LoginForm />
      <LeadGenration />
    </div>
  );
};

export default Login;
