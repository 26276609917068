import React from "react";
import AccountSetting from "../components/organisms/settings/AccountUpdateForm";

const Accounts = () => {
  return (
    <div>
      <div className="px-6 py-6 w-full">
        <h3 className="font-bold text-xl text-text-color mb-2">
          Account Settings
        </h3>
        <div className="mt-8">
          <AccountSetting />
        </div>
      </div>
    </div>
  );
};

export default Accounts;
