import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../services/apiServices";

// Create an async thunk for fetching saved searches
export const fetchCompanyUser = createAsyncThunk(
  "fetchCompanyUser/fetchCompanyUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GET("/invite/customers"); // Call the API
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

const getCompanyUserSlice = createSlice({
  name: "getCompanyUser",
  initialState: {
    getCompanyUser: [], // Ensure this is defined
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.getCompanyUser = action.payload;
      })
      .addCase(fetchCompanyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the action and reducer
export default getCompanyUserSlice.reducer;
