import React, { useState } from "react";
import { Button } from "../../atoms/buttons";
import { Input } from "../../atoms/input";
import StepHeading from "../../atoms/StepHeading";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "../../../redux/slices/onBoardingSlice";

const CompanyNameStep = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { companyName } = useSelector((state) => state.onBoarding);
  const [errors, setErrors] = useState({}); // To manage field errors

  // Handle input change and update Redux store
  const handleChange = (e) => {
    const { value } = e.target;
    dispatch(updateForm({ companyName: value }));

    // Clear the error when user starts typing
    setErrors((prev) => ({ ...prev, companyName: "" }));
  };

  // Validate the form before proceeding
  const validateForm = () => {
    const newErrors = {};
    if (!companyName.trim()) {
      newErrors.companyName = "Company name is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle next step
  const handleNext = () => {
    if (validateForm()) {
      onNext(); // Proceed to next step only if valid
    }
  };

  return (
    <div className="">
      <StepHeading>Let's get your LimeLeads account setup</StepHeading>
      <div className="max-w-md">
        <label
          htmlFor="companyName"
          className="block text-[1rem] font-bold leading-[1.75rem] text-dark-green mb-0"
        >
          Your company name?
        </label>
        <Input
          type="text"
          id="companyName"
          placeholder="Apple"
          className=""
          name="companyName"
          value={companyName}
          onChange={handleChange}
          error={errors.companyName} // Pass error to the Input component
        />
        <div className="flex items-center gap-3 mt-7">
          <Button
            className="bg-[#1D201D] text-white font-medium py-2 px-6 "
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            className="bg-lime-green hover:bg-lime-green/90 text-white font-medium py-2 px-6"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyNameStep;
