import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "../../atoms/FormLabel";
import { Button } from "../../atoms/buttons";
import StepHeading from "../../atoms/StepHeading";
import { useNavigate } from "react-router-dom";
import {
  updateForm,
  updateFormData,
} from "../../../redux/slices/onBoardingSlice";
import toast from "react-hot-toast";

const HowDidStep = ({ onBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    name,
    companyName,
    website,
    numEmployees,
    position,
    achievementGoal,
    otherGoalText,
    heardFrom,
  } = useSelector((state) => state.onBoarding);

  const [error, setError] = useState(null); // Add state for validation error

  const howdid = [
    { id: "google-search", label: "Google search" },
    { id: "social-media", label: "Social media (LinkedIn, X)" },
    { id: "blog", label: "Blog" },
    { id: "referral", label: "Referral" },
    { id: "other", label: "Other" },
  ];

  const handleChange = (label) => {
    dispatch(updateForm({ heardFrom: label }));
    if (error) setError(null); // Clear error on valid selection
  };

  const handleSubmit = async () => {
    toast.dismiss();

    if (!heardFrom) {
      setError("Please select how you heard about LimeLeads.");
      return;
    }

    const achievements = []
      .concat(achievementGoal, otherGoalText)
      .filter(Boolean);

    const data = {
      name,
      companyName,
      website,
      numEmployees,
      position: position?.trim(),
      achievementGoal: achievements,
      otherGoalText,
      heardFrom,
    };

    try {
      const response = await dispatch(updateFormData(data)).unwrap();
      if (response) {
        window.location.href = "/dashboard";
        toast.success("Logged in successfully!");
      }
    } catch (error) {
      console.error("Error submitting form: ", error?.message);
      toast.error(error?.message);
    }
  };

  return (
    <div>
      <StepHeading>Let's get your LimeLeads account setup</StepHeading>
      <div className="mb-8">
        <h2 className="text-[1rem] font-bold text-[#313D31] mb-4">
          How did you hear about LimeLeads?
        </h2>
        <div className="space-y-2">
          {howdid.map((item) => (
            <div
              key={item.label}
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => handleChange(item.label)}
            >
              <div
                className={`w-[1.2rem] h-[1.2rem] rounded-full border-2 flex items-center justify-center ${
                  heardFrom === item.label
                    ? "border-lime-green"
                    : "border-[#6D886D]"
                }`}
              >
                {heardFrom === item.label && (
                  <div className="w-[0.7rem] h-[0.7rem] rounded-full bg-green-500" />
                )}
              </div>
              <FormLabel
                htmlFor={item.label}
                className="cursor-pointer text-sm font-normal text-[#313D31]"
              >
                {item.label}
              </FormLabel>
            </div>
          ))}
        </div>
        {error && (
          <p className="text-red-500 text-sm mt-2">{error}</p> // Show validation error
        )}
      </div>
      <div className="flex items-center gap-3">
        <Button
          className="bg-[#1D201D] text-white font-medium py-2 px-6"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          className="bg-lime-green hover:bg-lime-green/90 text-white font-medium py-2 px-6"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default HowDidStep;
