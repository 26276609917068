import React from "react";

// import SingleLead from "./SingleLead";
const LeadGenration = () => {
  // const leads = [
  //   {
  //     name: "Sam Berg",
  //     type: "Travel Agent",
  //     company: "Woodlake Travel",
  //     email: "Sam@gmail.com",
  //     phone: "(07) 5381 2404 ",
  //     image: "/assets/images/Name.png",
  //     location: "Houston, TX",
  //   },
  //   {
  //     name: "Bette Prochas",
  //     type: "Travel Agent",
  //     company: "Woodlake Travel",
  //     email: "Bette@gmail.com",
  //     phone: "(03) 5391 7904 ",
  //     image: "/assets/images/Bette.png",
  //     location: "Houston, TX",
  //   },
  //   {
  //     name: "Jared Dyson",
  //     type: "Travel Agent",
  //     company: "Dyson Travel",
  //     email: "Jared@gmail.com",
  //     phone: "(07) 4007 1553",
  //     image: "/assets/images/Jared.png",
  //     location: "Newton, NC",
  //   },
  //   {
  //     name: "Leslie Miskove",
  //     type: "Travel Agent",
  //     company: "Go Classy Tours",
  //     email: "Leslie@gmail.com",
  //     phone: "(02) 6110 8973 ",
  //     image: "/assets/images/Leslie.png",
  //     location: "Palm Har",
  //   },
  //   {
  //     name: "Roberta Albert",
  //     type: "Travel Agent",
  //     company: "Cultural Italy",
  //     email: "Albert@gmail.com",
  //     phone: "(08) 8388 3171 ",
  //     image: "/assets/images/Roberta.png",
  //     location: "San Dieg",
  //   },
  //   {
  //     name: "Roberta Burn",
  //     type: "Travel Agent",
  //     company: "Cultural Italy",
  //     email: "Burn@gmail.com",
  //     phone: "(03) 5356 2241",
  //     image: "/assets/images/Burn.png",
  //     location: "San Dieg",
  //   },
  // ];

  const trustedCompanies = [
    { name: "Google", logo: "/assets/images/Google.png" },
    { name: "Zeiss", logo: "/assets/images/Zeiss.png" },
    { name: "Amazon", logo: "/assets/images/Amazon.svg" },
    { name: "Microsoft", logo: "/assets/images/Mircosoft.png" },
    { name: "Zendesk", logo: "/assets/images/Zendesk.png" },
    { name: "Salesforce", logo: "/assets/images/Salesforce.svg" },
    { name: "Lyft", logo: "/assets/images/Lyft.svg" },
    { name: "Hubspot", logo: "/assets/images/CLGX.svg" },
    { name: "Sprinklr", logo: "/assets/images/NP.svg" },
    { name: "springbot", logo: "/assets/images/springbot.png" },
  ];
  return (
    <div className="flex flex-col h-full bg-[#f3fbf6]  justify-center lg:w-1/2 w-full py-7">
      <div className="lg:max-w-4xl w-full mx-auto px-1 lg:px-32">
        <div className=" text-center text-[#1d201d] md:text-[2rem] text-2xl font-bold  leading-10">
          Get Verified US-based Leads Data
        </div>
        <div className="leadinnd_data my-5">
          <img src={`/images/right-graphic.svg`} />
        </div>
      </div>
      <div className="lg:mt-20 mt-10">
        <h2 className="text-[#313d31] text-base font-bold text-center leading-tight mb-6">
          Trusted by thousands of companies
        </h2>
        <div className="lg:grid flex flex-wrap justify-center grid-cols-4 gap-4 md:grid-cols-5 px-3">
          {trustedCompanies.map((company, index) => (
            <div key={index} className="flex justify-center items-center">
              <img
                src={company.logo}
                alt={`${company.name} logo`}
                //   width={90}
                //   height={30}
                className="my-2"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LeadGenration;
