import React from "react";

const CreditActivity = ({ data }) => {
  // Function to format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div className="flex flex-col flex-1">
      <h3 className="text-left mb-2 font-bold text-xl text-text-color">
        Credit Activity
      </h3>
      <div className="bg-white p-5 rounded-lg shadow-card-shadow flex-1 max-h-[18.25rem] overflow-auto">
        <ul className="list-none p-0">
          {data &&
            data?.creditHistory?.map((activity, index) => (
              <>
                <li key={index} className="flex items-start mb-2.5">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/home/activity.png`}
                    className="h-6 w-6 mr-2 mt-1.5"
                    alt="activity icon"
                  />
                  <div className="flex flex-col items-start text-left leading-6">
                    <span className="text-sm font-normal text-dark-green mb-1">
                      {activity?.description}
                    </span>
                    <span className="text-sm font-normal leading-5 text-[#313D3199]">
                      <span className="text-sm font-normal leading-5 text-[#313D3199]">
                        {formatDate(activity?.created_at)}
                      </span>
                    </span>
                  </div>
                </li>
              </>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default CreditActivity;
