import React from "react";
import Icons from "../../assets/icons";

const YourCompany = ({ data }) => {
  return (
    <div>
      <div className="bg-white border border-[#DAE7DE] rounded-lg p-6 min-w-[20rem] w-fit">
        <div className="flex items-center justify-center flex-col mb-4">
          <Icons.Company />
          <h2 className="text-xl font-semibold text-[#1D201D]">Your Company</h2>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-dark-green">
              Company name
            </span>
            <span className="text-sm font-normal text-dark-green">
              {data?.name}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-dark-green">
              Number of users
            </span>
            <span className="text-sm font-normal text-dark-green ">
              {data?.num_employees}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourCompany;
