import { useState, useEffect } from "react";
import Slider from "../../ui/Slider";
import { Button } from "../../atoms/buttons";
import { useDispatch } from "react-redux";
import { createCredit } from "../../../redux/slices/creditSlice";
import Spinner from "../../atoms/Spinner";

export default function CreditForm({ creditInfo, loading }) {
  const dispatch = useDispatch();

  const [credits, setCredits] = useState(0);
  const [emailCredits, setEmailCredits] = useState("Unlimited");
  const [isLoading, setIsLoading] = useState(false); // New loading state for the button

  console.log("creditInfo", creditInfo);

  useEffect(() => {
    if (creditInfo?.data && creditInfo.data.length > 0) {
      setCredits(creditInfo.data[0].credits); // Set initial value from API when data is available
    }
  }, [creditInfo]);

  if (loading || !creditInfo || !creditInfo.data) {
    return <p>Loading...</p>;
  }

  const values = creditInfo.data.map((info) => info.credits); // [500, 1000, 2500, 5000]
  const labels = creditInfo.data.map((info) => `$${info.price}`); // ["$30", "$60", "$150", "$300"]

  const emailValues = [0, "Unlimited"];
  const emailLabels = ["", ""];

  const handleUpdateAccount = () => {
    setIsLoading(true); // Start loading

    const creditData = {
      price: creditInfo.data[values.indexOf(credits)].price, // Get the corresponding price
      credits: credits, // Use the selected credits
    };

    // Ensure dispatch returns a promise for chaining
    dispatch(createCredit(creditData))
      .then((response) => {
        if (response.payload && response.payload.data) {
          // Redirect to the Stripe checkout URL returned in response.payload.data
          window.location.href = response.payload.data;
        } else {
          console.log("Checkout URL not found in response");
        }
      })
      .catch((error) => {
        console.error("Error creating billing", error);
        // Handle error
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };
  return (
    <div className="mx-auto space-y-8">
      <div className="space-y-8">
        <div className="p-5 pb-[4rem] md:min-h-[12.3rem] rounded-lg shadow-card-shadow">
          <h2 className="text-[2rem] text-text-color font-bold">Credits</h2>
          <p className="text-base font-normal text-[#313D31]">
            To add credits, use the slider to choose how many you'd like. If you
            only want phone credits, leave the slider at 0.
          </p>
          <Slider
            value={credits}
            onValueChange={setCredits}
            values={values} // Dynamically set values
            labels={labels} // Dynamically set labels
          />
        </div>

        <div className="p-5 pb-[4rem] md:min-h-[12.3rem] rounded-lg shadow-card-shadow">
          <h2 className="text-[2rem] text-text-color font-bold">
            Email verification credits
          </h2>
          <p className="text-base font-normal text-[#313D31]">
            Email verification credits are unlimited.
          </p>
          <Slider
            value={emailCredits}
            onValueChange={setEmailCredits}
            values={emailValues} // Unlimited only
            labels={emailLabels} // No label needed for unlimited
            isDisabled={true}
          />
        </div>
      </div>

      <div className="flex justify-center" onClick={handleUpdateAccount}>
        <Button
          className="py-3 bg-[#50C878] hover:bg-green-600 text-white font-medium !mt-7"
          disabled={isLoading} // Disable button when loading
        >
          {isLoading ? <Spinner /> : "Buy credits"}
        </Button>
      </div>

      <div className="flex justify-between items-center pt-8 border-t pb-14">
        <div>
          <p className="text-sm text-muted-foreground">Summary</p>
          <h2 className="text-3xl font-bold">Total Amount</h2>
        </div>
        <div className="text-right flex items-center gap-3">
          {/* <button className="text-lime-green underline hover:underline">
            See price breakdown
          </button> */}
          <p className="text-3xl font-bold">
            {credits === 0
              ? "Select amount"
              : `${labels[values.indexOf(credits)]}`}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
