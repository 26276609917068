import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../components/atoms/buttons";
import CustomTable from "../components/molecules/Table";
import { fetchPastExports } from "../redux/slices/pastExportsSlice";
import { POST } from "../services/apiServices";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../components/molecules/TableSkeleton";
import LoaderAnimation from "../components/atoms/LoaderAnimation";
import { Tooltip } from "react-tooltip";
import Pagination from "../components/molecules/Pagination";

export default function PostExports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingExportId, setLoadingExportId] = useState(null);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { exports, loading, error } = useSelector((state) => state.pastExports);
  const isFirstLoad = useRef(true);
  const [totalPages, setTotalPages] = useState(null);
  const itemsPerPage = 10;

  const fetchExports = async () => {
    if (isFirstLoad.current) {
      isFirstLoad.current = true;
    }
    try {
      const exportsData = await dispatch(
        fetchPastExports({
          page: currentPage,
          limit: itemsPerPage,
        })
      );
      if (exportsData.payload && exportsData.payload.pagination) {
        console.log("exportsData.payload", exportsData.payload.pagination);
        setTotalPages(exportsData.payload.pagination.totalPages);
      }
    } catch (error) {
      console.error("Failed to fetch past exports:", error);
    }
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    }
  };
  console.log(totalPages);

  useEffect(() => {
    fetchExports();

    const intervalId = setInterval(() => {
      fetchExports();
    }, 15000);

    return () => clearInterval(intervalId);
  }, [dispatch, currentPage, itemsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setLoadingPagination(true);
  };

  useEffect(() => {
    if (currentPage) {
      const fetchCurrentPageExports = async () => {
        await fetchExports();
        setLoadingPagination(false);
      };

      fetchCurrentPageExports();
    }
  }, [currentPage]);

  const headers = [
    "#",
    "Exported on",
    "File name",
    "Number of contacts",
    "Net new contacts",
    "View original search",
    "Download",
  ];

  const prcessingHeaders = [
    "#",
    "Exported on",
    "File name",
    "Number of contacts",
    "View original search",
    "Status",
  ];

  const handleDownload = async (exportId, fileName) => {
    setLoadingExportId(exportId); // Set the export that is loading
    try {
      const response = await POST(
        `/export/download-order/${exportId}`,
        {},
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;

      const downloadFileName = fileName.endsWith(".csv")
        ? fileName
        : `${fileName}.csv`;

      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setLoadingExportId(null); // Reset the loading export after download
    }
  };

  const data = exports?.data?.map((exportItem, index) => [
    index + 1,
    new Date(exportItem.created_at).toLocaleDateString(),
    exportItem.original_filename,
    exportItem.num_leads,
    exportItem?.count,
    <Button
      key={`view-${exportItem.id}`}
      className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md mr-2"
      onClick={() => {
        navigate(`/database?past-export=${exportItem.id}`);
      }}
    >
      View original search
    </Button>,
    <Button
      key={`download-${exportItem.id}`}
      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md w-[100px] h-[35px]"
      onClick={() =>
        handleDownload(exportItem.id, exportItem.original_filename)
      }
      loading={loadingExportId === exportItem.id}
    >
      Download
    </Button>,
  ]);

  const processingData = exports?.pendingOrders?.map((pendingItem, index) => [
    index + 1,
    new Date(pendingItem.created_at).toLocaleDateString(),
    pendingItem.original_filename,
    pendingItem.original_num_leads,
    <Button
      key={`view-${pendingItem.id}`}
      className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
      onClick={() => {
        navigate(`/database?past-export=${pendingItem.id}`);
      }}
    >
      View original search
    </Button>,
    <span className="cursor-pointer">
      <div
        data-tooltip-id="loader-tooltip"
        data-tooltip-content="Processing..."
      >
        <LoaderAnimation />
      </div>
      <Tooltip id="loader-tooltip" />
    </span>,
  ]);

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb]">
      <div className="px-3">
        {loading && isFirstLoad.current ? (
          <div className="">
            <TableSkeleton rows={10} columns={6} header={false} />
          </div>
        ) : exports?.pendingOrders?.length > 0 ? (
          <>
            <div className="text-[#1d201d] text-xl font-bold my-3 leading-7">
              Currently processing exports
            </div>
            <CustomTable headers={prcessingHeaders} data={processingData} />
          </>
        ) : (
          ""
        )}
        <div className="text-[#1d201d] text-xl font-bold my-3 leading-7">
          Past Exports
        </div>
        <p className="text-base text-[#313d31] mb-2">
          Quickly access and download any of your past exports.
        </p>
        <p className="text-base text-[#313d31] mb-2">
          The contacts that you already exported will not be included in your
          next exports
        </p>
      </div>

      {loadingPagination ? (
        <div className="">
          <TableSkeleton rows={10} columns={6} header={false} />
        </div>
      ) : exports?.data?.length === 0 ? (
        <div className="flex justify-center items-center h-[50vh]">
          <p className="text-2xl text-gray-500">No past exports found</p>
        </div>
      ) : (
        <>
          <CustomTable headers={headers} data={data} />
          <div className="mb-10">
            {totalPages > 1 ? (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
