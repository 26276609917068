import React, { useState } from "react";
import { Button } from "../../atoms/buttons";
import Spinner from "../../atoms/Spinner";

const ConfirmPopup = ({ onClose, isLoading, handleDelete }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-xl font-bold mb-4">
          Are you sure you want to delete this customer?
        </h2>

        {/* Action Buttons */}
        <div className="flex justify-end">
          <Button onClick={onClose} className="mr-2 bg-gray-300 text-black">
            Cancel
          </Button>
          <Button
            className=" bg-[#fb4747] hover:opacity-90 rounded text-white   disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading}
            onClick={handleDelete}
          >
            {isLoading ? <Spinner /> : "Delete"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
