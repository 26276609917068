import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "../components/atoms/FormLabel";
import { Input } from "../components/atoms/input";
import { Button } from "../components/atoms/buttons";
import { createCustomer } from "../redux/slices/createCustomerSlice"; // Import the action
import Spinner from "../components/atoms/Spinner";
import toast from "react-hot-toast";

// Validation function
const validateForm = (formData) => {
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!formData.name.trim()) errors.name = "Name is required";
  if (!formData.email.trim()) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(formData.email.trim())) {
    errors.email = "Invalid email format";
  }
  if (!formData.companyName.trim())
    errors.companyName = "Company name is required";
  if (!formData.password.trim()) {
    errors.password = "Password is required";
  } else if (formData.password.trim().length < 8) {
    errors.password = "Password must be at least 8 characters";
  }

  return errors;
};

const CreateCustomer = () => {
  const dispatch = useDispatch(); // Initialize dispatch
  const { customer, loading, error } = useSelector(
    (state) => state.createCustomer
  ); // Get loading and error from state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const result = await dispatch(createCustomer(formData));

    if (result.meta.requestStatus === "fulfilled") {
      toast.success(result?.payload?.message);
      setFormData({
        name: "",
        email: "",
        companyName: "",
        password: "",
      });
      setErrors({});
    } else {
      toast.error(result.payload?.message);
    }
  };

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold leading-7">
          Create Customer
        </h1>
      </div>
      <form onSubmit={handleSubmit} action="" className="max-w-4xl">
        <div className="flex gap-6 mt-4">
          <div className="w-full">
            <FormLabel htmlFor="fullName">Full name</FormLabel>
            <Input
              type="text"
              id="fullName"
              placeholder="Mike Morgan"
              className="!w-full"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={errors.name}
            />
          </div>
          <div className="w-full">
            <FormLabel htmlFor="email">Email address</FormLabel>
            <Input
              type="email"
              id="email"
              placeholder="name@gmail.com"
              className="!w-full"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
            />
          </div>
        </div>
        <div className="flex gap-6 my-2 mt-5">
          <div className="w-full">
            <FormLabel htmlFor="companyName">Company name</FormLabel>
            <Input
              type="text"
              id="companyName"
              placeholder="Limeleads"
              className="!w-full"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              error={errors.companyName}
            />
          </div>
          <div className="w-full">
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              type="password"
              id="password"
              placeholder="Mike1234"
              className="!w-full"
              name="password"
              value={formData.password}
              onChange={handleChange}
              error={errors.password}
            />
          </div>
        </div>
        <Button
          className="bg-[#50c878] text-white py-3 px-5 mt-5"
          type="submit"
          disabled={loading} // Disable button while loading
        >
          {loading ? <Spinner /> : "Create Customer"}
        </Button>
        {/* Display error message */}
      </form>
    </div>
  );
};

export default CreateCustomer;
