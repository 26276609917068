import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import cities from "list-of-us-cities"; // Import the list of U.S. cities
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../assets/icons";
import {
  Companies,
  jobOptions,
  jobTitleAbbreviations,
  Stacks,
  US_STATES,
} from "../../data/optionsData";
import {
  clearFilters,
  getIndustries,
  setCity,
  setCompanyName,
  setExcludeCity,
  setExcludeCompanyName,
  setExcludeIndustries,
  setExcludeKeywords,
  setExcludeState,
  setExcludeZip,
  setFilters,
  setIndustry,
  setJobTitles,
  setSelectExcludeSic,
  setSelectSic,
  setState,
  setZip,
  updateFilters,
  setTechStack,
} from "../../redux/slices/searchSlice";
import AdditionalFilters from "./AdditionalFilters";
import TechData from "../../assets/data/tech_stack_keys.json";
import FilterItem from "./FilterItem";
import Popup from "../ui/Popup";
import { Input } from "../atoms/input";
import { Button } from "../atoms/buttons";
import toast from "react-hot-toast";
import { GET, POST } from "../../services/apiServices";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { IndustriesList } from "../../utils/industries";
import RequestDataPopup from "./RequestDataPopup";

const SearchOptions = forwardRef(
  ({ toggleTable, onSaveFilters, setSelectedIds }, ref) => {
    const [showContent, setShowContent] = useState(true); // State to control visibility of content
    const [openItem, setOpenItem] = useState(null); // Track the open item in the accordion
    const [openExcludeField, setOpenExcludeField] = useState(null); // Track the open exclude field
    const dispatch = useDispatch(); // Redux dispatch function
    // const industries = useSelector((state) => state.search.industriesList);
    const [industries, setindustries] = useState(IndustriesList);
    const [showPopup, setShowPopup] = useState(false);
    const [saveCriteriaName, setSaveCriteriaName] = useState("");
    const navigate = useNavigate();
    // Destructure relevant state from Redux
    const {
      tech_stack,
      jobTitles,
      excludeKeywords,
      companyName,
      state,
      excludeState,
      excludeCompanyName,
      filters,
      industry,
      sic,
      excludeSic,
      excludeIndustries,
      city,
      excludeCity,
      zip,
      exlcudeZip,
      loading,
      error,
    } = useSelector((state) => state.search);
    console.log("filters.data", jobTitles, filters.data);
    // Consolidate local state for selected filters
    const [selectedFilters, setSelectedFilters] = useState({
      tech_stack: tech_stack,
      jobTitles: jobTitles,
      excludedJobs: excludeKeywords,
      selectedCompany: companyName,
      excludeCompany: excludeCompanyName,
      selectIndustry: industry,
      exludeIndustry: excludeIndustries,
      selectSicCode: sic,
      selectedExludeSicCode: excludeSic,
      selectedState: state,
      excludeSelectedState: excludeState,
      selectedCity: city,
      excludeSelectedCity: excludeCity,
      selectedZip: zip,
      excludeSelectedZip: exlcudeZip,
      minEmployees: 1,
      maxEmployees: 10000,
      minRevenue: 100000,
      maxRevenue: 1000000000,
      emailGradeA: true,
      emailGradeB: true,
    });
    const [localFilters, setLocalFilters] = useState({
      tech_stack: tech_stack,
      jobTitles: jobTitles,
      excludedJobs: excludeKeywords,
      selectedCompany: companyName,
      excludeCompany: excludeCompanyName,
      selectIndustry: industry,
      exludeIndustry: excludeIndustries,
      selectSicCode: sic,
      selectedExludeSicCode: excludeSic,
      selectedState: state,
      excludeSelectedState: excludeState,
      selectedCity: city,
      excludeSelectedCity: excludeCity,
      selectedZip: zip,
      excludeSelectedZip: exlcudeZip,
      minEmployees: 1,
      maxEmployees: 10000,
      minRevenue: 100000,
      maxRevenue: 1000000000,
      emailGradeA: true,
      emailGradeB: true,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [showSelectedValues, setShowSelectedValues] = useState(false);
    const [showExcludedValues, setShowExcludedValues] = useState(false);

    const [search, setsearch] = useSearchParams();
    const searchId = search?.get("search");
    const pastExportId = search?.get("past-export");
    const location = useLocation(); // Hook to get the current location

    // Prepare industries label for dropdown
    const industrieslabel = industries?.map((item) => ({
      label: item?.label,
      value: item?.label,
    }));

    // Get all cities for the city filter
    const getAllCities =
      cities &&
      cities.map((city) => ({
        label: city,
        value: city,
      }));

    // Fetch industries on component mount
    // useEffect(() => {
    //   dispatch(getIndustries());
    // }, [dispatch]);

    // Clear filters when the component unmounts or the location changes
    // useEffect(() => {
    //   return () => {
    //     if (!searchId) {
    //       dispatch(clearFilters());
    //     }
    //     // Clears Redux filters on unmount or route change
    //   };
    // }, [location, dispatch]);

    // Handle filter changes and dispatch to Redux
    const handleFilterChange = (name, value) => {
      dispatch(setFilters({ [name]: value }));
    };

    // Generic handler for changing selected values
    const handleChange = (
      newTitles,
      setSelected,
      dispatchAction,
      isExclude = false
    ) => {
      const formattedTitles = newTitles.map((title) => title.label || title); // Format titles
      const combinedTitles = [...new Set(formattedTitles)]; // Remove duplicates

      setSelected(combinedTitles); // Update local state

      dispatch(dispatchAction(combinedTitles)); // Dispatch to Redux
      if (isExclude) {
        setShowExcludedValues(true);
      } else {
        setShowSelectedValues(true);
      }
    };

    // Specific handlers for each filter type
    const handleStateChange = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({ ...prev, selectedState: titles })),
        setState
      );

    const handleExclusiveStateChange = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({
            ...prev,
            excludeSelectedState: titles,
          })),
        setExcludeState,
        true
      );

    const handleCityChange = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({ ...prev, selectedCity: titles })),
        setCity
      );

    const handleExcliveCityChange = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({
            ...prev,
            excludeSelectedCity: titles,
          })),
        setExcludeCity,
        true
      );

    const handleCompanyNameChange = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({ ...prev, selectedCompany: titles })),
        setCompanyName,
        true
      );

    const handleExclusiveCompanyName = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({ ...prev, excludeCompany: titles })),
        setExcludeCompanyName,
        true
      );

    const handleIndustryChange = (newTitles) => {
      handleChange(
        newTitles,
        (titles) => {
          setSelectedFilters((prev) => ({ ...prev, selectIndustry: titles }));
        },
        setIndustry
      );
    };

    const handleTechChange = (newTitles) => {
      handleChange(
        newTitles,
        (titles) => {
          setSelectedFilters((prev) => ({ ...prev, tech_stack: titles }));
        },
        setTechStack
      );
    };

    const handleExclusiveIndustry = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({ ...prev, exludeIndustry: titles })),
        setExcludeIndustries,
        true
      );

    const handleSicCode = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({ ...prev, selectSicCode: titles })),
        setSelectSic
      );

    const handleExclusiveSicCode = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({
            ...prev,
            selectedExludeSicCode: titles,
          })),
        setSelectExcludeSic,
        true
      );

    const handleZipCode = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({ ...prev, selectedZip: titles })),
        setZip
      );

    const handleExclusiveZipCode = (newTitles) =>
      handleChange(
        newTitles,
        (titles) =>
          setSelectedFilters((prev) => ({
            ...prev,
            excludeSelectedZip: titles,
          })),
        setExcludeZip,
        true
      );

    // Toggle visibility of content
    const toggleContent = () => {
      setShowContent(!showContent);
    };

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const getFilterValues = () => {
      const isEmpty = (value) => value === "" || value?.length == 0;
      const getNonEmptyValue = (value, fallback = []) =>
        isEmpty(value) ? fallback : value;

      const formatState = (states) =>
        getNonEmptyValue(states).map((state) => {
          const foundState = US_STATES?.find((s) => s.value === state);
          return foundState ? foundState.abbreviation : state;
        });

      const dataToSend = {
        keywords: {
          title_full: selectedFilters?.jobTitles?.map((i) => i?.label || i),
          company: getNonEmptyValue(selectedFilters.selectedCompany),
        },
        tech_stack: getNonEmptyValue(selectedFilters.tech_stack),
        zip: getNonEmptyValue(selectedFilters.selectedZip),
        industry: getNonEmptyValue(selectedFilters.selectIndustry),
        sic: getNonEmptyValue(selectedFilters.selectSicCode),
        state: formatState(selectedFilters.selectedState),
        city: getNonEmptyValue(selectedFilters.selectedCity),
        exclusion_keywords: {
          title_full: selectedFilters?.excludedJobs?.map((i) => i?.label || i),
          company: getNonEmptyValue(selectedFilters.excludeCompany),
        },
        exclude_industry: getNonEmptyValue(selectedFilters.exludeIndustry),
        exclude_sic: getNonEmptyValue(selectedFilters.selectedExludeSicCode),
        exclude_state: getNonEmptyValue(selectedFilters.excludeSelectedState),
        exclude_city: getNonEmptyValue(selectedFilters.excludeSelectedCity),
        exclude_zip: getNonEmptyValue(selectedFilters.excludeSelectedZip),
        email_grade: [
          selectedFilters.emailGradeA ? "A" : "",
          selectedFilters.emailGradeB ? "B" : "",
        ].filter(Boolean),
        revenue: {
          min: selectedFilters.minRevenue,
          max: selectedFilters.maxRevenue,
        },
        employee_count: {
          min: selectedFilters.minEmployees,
          max: selectedFilters.maxEmployees,
        },
      };

      return dataToSend;
    };

    // Prepare data to send when saving criteria

    const handleRunExampleSearch = async () => {
      setShowSelectedValues(true);
      setShowExcludedValues(true);

      setSelectedFilters((prev) => ({
        ...prev,
        selectIndustry: ["Prepackaged Software"],
        jobTitles: [
          { label: "President", value: "President" },
          { label: "Owner", value: "Owner" },
        ],
        excludedJobs: [{ label: "Vice", value: "Vice" }],
      }));

      const dataToSend = {
        keywords: {
          title_full: ["President", "Owner"],
          company: [],
        },
        zip: [],
        industry: ["Prepackaged Software"],
        sic: [],
        state: [],
        city: [],
        exclusion_keywords: {
          title_full: ["Vice"],
          company: [],
        },
        exclude_industry: [],
        exclude_sic: [],
        exclude_state: [],
        exclude_city: [],
        exclude_zip: [],
        email_grade: [
          filters.emailGradeA ? "A" : "",
          filters.emailGradeB ? "B" : "",
        ].filter(Boolean), // Filter out empty grades
        revenue: {
          min: filters.minRevenue,
          max: filters.maxRevenue,
        },
        employee_count: {
          min: filters.minEmployees,
          max: filters.maxEmployees,
        },
        tech_stack: [],
      };
      setLocalFilters(dataToSend);
      onSaveFilters(dataToSend);

      // dispatch(updateFilters(dataToSend));
      const response = await dispatch(updateFilters(dataToSend));
      if (response?.payload?.savedSearch) {
        const newSearchId = response.payload.savedSearch;
        if (searchId !== newSearchId) {
          navigate(`/database?search=${newSearchId}`);
        }
        setHasFetchedDetails(newSearchId); // Update to the new saved search ID
      } else {
        toggleTable();
      }
    };
    const handlepageChange = (page) => {
      console.log("change page: " + page, localFilters);
      dispatch(updateFilters({ ...localFilters, currentPage: page }));
    };

    const getSaveSearchDetails = async () => {
      try {
        const response = await GET(`saved-searches/${searchId}`);
        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Handle 404 error
          toast.error(
            "Saved search not found. It may have been deleted or doesn't exist."
          );
          return null;
        }
        // Handle other errors
        toast.error("An error occurred while fetching saved search details.");
        console.error("Error fetching saved search details:", error);
        return null;
      }
    };

    const getPastExportDetails = async () => {
      const response = await GET(`export/order-details/${pastExportId}`);
      return response;
    };
    const [hasFetchedDetails, setHasFetchedDetails] = useState(null);

    const handleSaveCriteria = async () => {
      setShowSelectedValues(true);
      setShowExcludedValues(true);
      setSelectedIds();
      const dataToSend = getFilterValues();
      setLocalFilters(dataToSend);
      onSaveFilters(dataToSend);
      console.log("==??", dataToSend);

      const response = await dispatch(updateFilters(dataToSend));
      if (response?.payload?.savedSearch) {
        const newSearchId = response.payload.savedSearch;
        if (searchId !== newSearchId) {
          navigate(`/database?search=${newSearchId}`);
        }
        setHasFetchedDetails(newSearchId); // Update to the new saved search ID
      } else {
        toggleTable();
      }
    };
    console.log("selectedFilters", selectedFilters);
    useEffect(() => {
      const fetchDetails = async () => {
        const details = await getSaveSearchDetails(searchId);
        if (details) {
          const searchParams =
            details.savedSearch?.original_search_params || {};
          setShowSelectedValues(true);
          setShowExcludedValues(true);
          console.log("searchParams", searchParams);
          const updatedFilters = {
            ...searchParams,
            jobTitles:
              searchParams?.keywords?.title_full?.map((title) => ({
                label: title,
                value: title,
              })) || [],
            excludedJobs:
              searchParams?.exclusion_keywords?.title_full?.map((title) => ({
                label: title,
                value: title,
              })) || [],
            selectedCompany: searchParams?.keywords?.company || [],
            excludeCompany: searchParams?.exclusion_keywords?.company || [],
            selectIndustry: searchParams?.industry || [],
            selectSicCode: searchParams?.sic || [],
            excludeIndustry: searchParams?.exclude_industry || [],
            selectedExcludeSicCode: searchParams?.exclude_sic || [],
            selectedState: searchParams?.state || [],
            excludeSelectedState: searchParams?.exclude_state || [],
            selectedCity: searchParams?.city || [],
            excludeSelectedCity: searchParams?.exclude_city || [],
            selectedZip: searchParams?.zip || [],
            excludeSelectedZip: searchParams?.exclude_zip || [],
            tech_stack: searchParams?.tech_stack || [],
            emailGradeB: searchParams?.email_grade?.includes("B"),
            emailGradeA: searchParams?.email_grade?.includes("A"),
            minRevenue: searchParams?.revenue?.min || "",
            maxRevenue: searchParams?.revenue?.max || "",
            minEmployees: searchParams?.employee_count?.min || "",
            maxEmployees: searchParams?.employee_count?.max || "",
          };
          console.log("searchParams", updatedFilters, searchParams);
          setSelectedFilters(updatedFilters);
          onSaveFilters(searchParams);

          dispatch(
            updateFilters({
              ...searchParams,
              savedSearch: Number(searchId),
            })
          );
          toggleTable();
        } else {
          // Handle case when details are not found
          navigate("/"); // Redirect to home page or show an error message
        }
      };

      if (searchId !== hasFetchedDetails) {
        fetchDetails();
      }
    }, [searchId, hasFetchedDetails]);

    useEffect(() => {
      if (pastExportId) {
        const getDetails = async () => {
          toggleTable();
          const details = await getPastExportDetails(pastExportId);
          const searchParams = details && details?.original_search_params;
          setShowSelectedValues(true);
          setShowExcludedValues(true);

          const updatedFilters = {
            ...searchParams,
            jobTitles:
              searchParams?.keywords?.title_full?.map((title) => ({
                label: title,
                value: title,
              })) || [],
            excludedJobs:
              searchParams?.exclusion_keywords?.title_full?.map((title) => ({
                label: title,
                value: title,
              })) || [],
            selectedCompany: searchParams?.keywords?.company || [],
            excludeCompany: searchParams?.exclusion_keywords?.company || [],
            selectIndustry: searchParams?.industry || [],
            selectSicCode: searchParams.sic || [],
            exludeIndustry: searchParams?.exclude_industry || [],
            selectedExludeSicCode: searchParams?.exclude_sic || [],
            selectedState: searchParams?.state || [],
            excludeSelectedState: searchParams?.exclude_state || [],
            selectedCity: searchParams?.city || [],
            excludeSelectedCity: searchParams?.exclude_city || [],
            selectedZip: searchParams?.zip || [],
            excludeSelectedZip: searchParams?.exclude_zip || [],
            tech_stack: searchParams?.tech_stack || [],
            emailGradeB: searchParams?.email_grade?.includes("B"),
            emailGradeA: searchParams?.email_grade?.includes("A"),
            minRevenue: searchParams?.revenue?.min || "",
            maxRevenue: searchParams?.revenue?.max || "",
            minEmployees: searchParams?.employee_count?.min || "",
            maxEmployees: searchParams?.employee_count?.max || "",
          };

          setSelectedFilters(updatedFilters);
          onSaveFilters(updatedFilters);

          dispatch(updateFilters(updatedFilters));
        };
        getDetails();
      }
    }, [pastExportId]);

    // Pass function to the parent component
    useImperativeHandle(ref, () => ({
      handleSaveCriteria,
      handleRunExampleSearch,
      handlepageChange,
    }));

    // Clear all filters and reload the page
    const handleClearFilters = () => {
      // Reset local state
      setSelectedFilters({
        jobTitles: [],
        excludedJobs: [],
        selectedCompany: [],
        excludeCompany: [],
        selectIndustry: [],
        selectSicCode: [],
        exludeIndustry: [],
        selectedExludeSicCode: [],
        selectedState: [],
        excludeSelectedState: [],
        selectedCity: [],
        excludeSelectedCity: [],
        tech_stack: [],
      });

      // Reset filters in Redux
      dispatch(clearFilters());
      // Reload the page
      window.location.href = window.location.pathname;
    };

    // Check if screen is mobile
    React.useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setShowContent(true);
        }
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    function handleChangeSelectedJobTitle(selectedTitles = [], meta) {
      let _jobTitles = [];

      selectedTitles?.map((item, index) => {
        _jobTitles.push(item);

        if (
          jobTitleAbbreviations[item?.label] &&
          selectedTitles[index + 1]?.label !=
            jobTitleAbbreviations[item?.label]?.label &&
          selectedTitles?.length >= selectedFilters?.jobTitles?.length
        ) {
          _jobTitles.push(jobTitleAbbreviations[item?.label]);
        }
      });

      setSelectedFilters((prev) => ({
        ...prev,
        jobTitles: _jobTitles,
      }));
    }

    // function handleChangeSelectedTechStack(selectedTitles = [], meta) {
    //   let _techStack = [];

    //   selectedTitles?.map((item, index) => {
    //     _techStack.push(item);

    //     if (
    //       TechData[item?.label] &&
    //       selectedTitles[index + 1]?.label != TechData[item?.label]?.label &&
    //       selectedTitles?.length >= selectedFilters?.tech_stack?.length
    //     ) {
    //       _techStack.push(TechData[item?.label]);
    //     }
    //   });

    //   setSelectedFilters((prev) => ({
    //     ...prev,
    //     tech_stack: _techStack,
    //   }));
    // }

    function handleChangeExcludedJobTitle(selectedTitles = [], meta) {
      let _jobTitles = [];

      selectedTitles?.map((item, index) => {
        _jobTitles.push(item);

        if (
          jobTitleAbbreviations[item?.label] &&
          selectedTitles[index + 1]?.label !=
            jobTitleAbbreviations[item?.label]?.label &&
          selectedTitles?.length >= selectedFilters?.excludedJobs?.length
        ) {
          _jobTitles.push(jobTitleAbbreviations[item?.label]);
        }
      });

      setSelectedFilters((prev) => ({
        ...prev,
        excludedJobs: _jobTitles,
      }));
    }

    const optionMenuItems = [
      {
        id: "JOB",
        name: "Job Title",
        icon: `${process.env.PUBLIC_URL}/images/searchOption/job.png`,
        count: [
          ...selectedFilters?.jobTitles,
          ...selectedFilters?.excludedJobs,
        ]?.length?.toString(),
        items: (
          <FilterItem
            label="Job title"
            options={jobOptions}
            placeholder="Enter job title"
            excludeLabel="Job title"
            excludeField="JOB"
            value={
              typeof selectedFilters?.jobTitles?.[0] == "string"
                ? selectedFilters?.jobTitles?.map((i, _) => ({
                    label: i,
                    value: i,
                  }))
                : selectedFilters?.jobTitles
            }
            excludeValue={
              typeof selectedFilters?.excludedJobs?.[0] == "string"
                ? selectedFilters?.excludedJobs?.map((i, _) => ({
                    label: i,
                    value: i,
                  }))
                : selectedFilters?.excludedJobs
            }
            setOpenExcludeField={setOpenExcludeField}
            openExcludeField={openExcludeField}
            onChange={(selectedTitles) => {
              handleChangeSelectedJobTitle(selectedTitles);
            }}
            onExcludeChange={(excludedTitles) => {
              handleChangeExcludedJobTitle(excludedTitles);
            }}
          />
        ),
      },
      {
        id: "Location",
        name: "Location",
        icon: `${process.env.PUBLIC_URL}/images/searchOption/location.png`,
        count: `${
          (showSelectedValues
            ? selectedFilters?.selectedState?.length +
              selectedFilters?.selectedCity?.length
            : 0) +
          (showExcludedValues
            ? selectedFilters?.excludeSelectedState?.length +
              selectedFilters?.excludeSelectedCity?.length
            : 0)
        }`,
        items: (
          <>
            <FilterItem
              label="State"
              options={US_STATES}
              placeholder="Enter State"
              excludeLabel="State"
              excludeField="State"
              setOpenExcludeField={setOpenExcludeField}
              openExcludeField={openExcludeField}
              value={
                showSelectedValues
                  ? selectedFilters.selectedState &&
                    selectedFilters.selectedState?.map((value) => {
                      const locationExists = US_STATES.find(
                        (state) =>
                          state.abbreviation.toLowerCase() ===
                          value.toLowerCase()
                      );
                      if (locationExists) {
                        return {
                          value: locationExists.value,
                          label: locationExists.label,
                        };
                      } else {
                        return {
                          value: value,
                          label: value,
                        };
                      }
                    })
                  : []
              }
              excludeValue={
                showExcludedValues
                  ? selectedFilters.excludeSelectedState &&
                    selectedFilters.excludeSelectedState?.map((value) => {
                      const locationExists = US_STATES.find(
                        (state) =>
                          state.abbreviation.toLowerCase() ===
                          value.toLowerCase()
                      );
                      if (locationExists) {
                        return {
                          value: locationExists.value,
                          label: locationExists.label,
                        };
                      } else {
                        return {
                          value: value,
                          label: value,
                        };
                      }
                    })
                  : []
              }
              onChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  selectedState: selectedTitles,
                }));
                handleStateChange(selectedTitles); // Ensure Redux store is updated
              }}
              onExcludeChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  excludeSelectedState: selectedTitles,
                }));
                handleExclusiveStateChange(selectedTitles); // Ensure Redux store is updated
              }}
            />
            {/* City Filter */}
            <FilterItem
              label="City"
              options={getAllCities}
              placeholder="Enter City"
              excludeLabel="City"
              excludeField="City"
              setOpenExcludeField={setOpenExcludeField}
              openExcludeField={openExcludeField}
              value={
                showSelectedValues
                  ? selectedFilters.selectedCity &&
                    selectedFilters.selectedCity?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              excludeValue={
                showExcludedValues
                  ? selectedFilters.excludeSelectedCity &&
                    selectedFilters.excludeSelectedCity?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              onChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  selectedCity: selectedTitles,
                }));
                handleCityChange(selectedTitles);
              }}
              onExcludeChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  excludeSelectedCity: selectedTitles,
                }));
                handleExcliveCityChange(selectedTitles);
              }}
            />
            {/* Zip */}
            <FilterItem
              label="Zip Code"
              placeholder="Enter zip code"
              excludeLabel="Zip Code"
              excludeField="Zip"
              options={[]}
              openExcludeField={openExcludeField}
              setOpenExcludeField={setOpenExcludeField}
              value={
                showSelectedValues
                  ? selectedFilters?.selectedZip?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              excludeValue={
                showExcludedValues
                  ? selectedFilters.excludeSelectedZip &&
                    selectedFilters.excludeSelectedZip?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              onChange={(selectedZips) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  selectedZip: selectedZips,
                }));
                handleZipCode(selectedZips); // Ensure Redux store is updated
              }}
              onExcludeChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  excludeSelectedZip: selectedTitles,
                }));
                handleExclusiveZipCode(selectedTitles); // Ensure Redux store is updated
              }}
            />
          </>
        ),
      },
      {
        id: "Industry",
        name: "Industry",
        icon: `${process.env.PUBLIC_URL}/images/searchOption/industry.png`,
        count: `${
          (showSelectedValues
            ? (Array.isArray(selectedFilters?.selectIndustry)
                ? selectedFilters.selectIndustry.length
                : 0) +
              (Array.isArray(selectedFilters?.selectSicCode)
                ? selectedFilters.selectSicCode.length
                : 0)
            : 0) +
          (showExcludedValues
            ? (Array.isArray(selectedFilters?.exludeIndustry)
                ? selectedFilters.exludeIndustry.length
                : 0) +
              (Array.isArray(selectedFilters?.selectedExludeSicCode)
                ? selectedFilters.selectedExludeSicCode.length
                : 0)
            : 0)
        }`,
        items: (
          <>
            <FilterItem
              // parentClass={"custom-placeholder"}
              label="Industry"
              options={industrieslabel}
              placeholder="Enter keywords or select"
              excludeLabel="Industry"
              excludeField="Industry"
              setOpenExcludeField={setOpenExcludeField}
              openExcludeField={openExcludeField}
              value={
                showSelectedValues
                  ? selectedFilters.selectIndustry?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              excludeValue={
                showExcludedValues
                  ? selectedFilters.exludeIndustry &&
                    selectedFilters.exludeIndustry?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              onChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  selectIndustry: selectedTitles,
                }));
                handleIndustryChange(selectedTitles); // Ensure Redux store is updated
              }}
              onExcludeChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  exludeIndustry: selectedTitles,
                }));
                handleExclusiveIndustry(selectedTitles); // Ensure Redux store is updated
              }}
            />
            <FilterItem
              label="SIC Code"
              placeholder="Enter SIC Code"
              excludeLabel="SIC Code"
              excludeField="SIC"
              options={[]}
              openExcludeField={openExcludeField}
              setOpenExcludeField={setOpenExcludeField}
              value={
                showSelectedValues
                  ? selectedFilters?.selectSicCode?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              excludeValue={
                showExcludedValues
                  ? selectedFilters.selectedExludeSicCode &&
                    selectedFilters.selectedExludeSicCode?.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : []
              }
              onChange={(siccodes) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  selectSicCode: siccodes,
                }));
                handleSicCode(siccodes);
              }}
              onExcludeChange={(selectedTitles) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  selectedExludeSicCode: selectedTitles,
                }));
                handleExclusiveSicCode(selectedTitles);
              }}
            />
          </>
        ),
      },
      {
        id: "Company Name",
        name: "Company Name",
        icon: `${process.env.PUBLIC_URL}/images/searchOption/company.png`,
        count: `${
          (showSelectedValues ? selectedFilters?.selectedCompany?.length : 0) +
          (showExcludedValues ? selectedFilters?.excludeCompany?.length : 0)
        }`,
        items: (
          <FilterItem
            label={`Company name`}
            options={Companies}
            placeholder="Enter companies..."
            excludeLabel="Company name"
            excludeField="Company name"
            value={
              // showSelectedValues
              true
                ? selectedFilters.selectedCompany &&
                  selectedFilters.selectedCompany?.map((value) => ({
                    value: value,
                    label: value,
                  }))
                : []
            }
            excludeValue={
              showExcludedValues
                ? selectedFilters.excludeCompany &&
                  selectedFilters.excludeCompany?.map((value) => ({
                    value: value,
                    label: value,
                  }))
                : []
            }
            setOpenExcludeField={setOpenExcludeField}
            openExcludeField={openExcludeField}
            onChange={(selectedTitles) => {
              setSelectedFilters((prev) => ({
                ...prev,
                selectedCompany: selectedTitles,
              }));
              handleCompanyNameChange(selectedTitles);
            }}
            onExcludeChange={(selectedTitles) => {
              setSelectedFilters((prev) => ({
                ...prev,
                excludeCompany: selectedTitles,
              }));
              handleExclusiveCompanyName(selectedTitles);
            }}
          />
        ),
      },
      {
        name: "Additional Filters",
        icon: `${process.env.PUBLIC_URL}/images/searchOption/filter.png`,
        items: (
          <AdditionalFilters
            filters={selectedFilters}
            onFilterChange={handleFilterChange}
            Stacks={TechData}
            showSelectedValues={showSelectedValues}
            setShowSelectedValues={setShowSelectedValues}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            tech_stack={tech_stack}
            handleTechChange={handleTechChange}
          />
        ),
      },
    ];

    const saveSearchCriteria = async (data) => {
      const response = await POST("/saved-searches", data);
      return response.data;
    };

    const handleSaveCriteriaName = async () => {
      toast.dismiss();
      if (saveCriteriaName?.trim()?.length > 0) {
        setShowPopup(false);
        const dataToSend = getFilterValues();
        const response = await saveSearchCriteria({
          description: saveCriteriaName,
          originalSearchParams: dataToSend,
        });
        toast.success("Search criteria saved successfully");
        navigate("/saved-searches");
      } else {
        toast.error("Please enter search criteria name");
      }
    };

    //disabled clear criteria button
    const disabledClearCriteria = () => {
      if (
        selectedFilters?.jobTitles?.length > 0 ||
        selectedFilters?.excludedJobs?.length > 0 ||
        selectedFilters?.selectedCompany?.length > 0 ||
        selectedFilters?.excludeCompany?.length > 0 ||
        selectedFilters?.selectIndustry?.length > 0 ||
        selectedFilters?.exludeIndustry?.length > 0 ||
        selectedFilters?.selectSicCode?.length > 0 ||
        selectedFilters?.selectedExludeSicCode?.length > 0 ||
        selectedFilters?.selectedState?.length > 0 ||
        selectedFilters?.excludeSelectedState?.length > 0 ||
        selectedFilters?.selectedCity?.length > 0 ||
        selectedFilters?.excludeSelectedCity?.length > 0 ||
        selectedFilters?.selectedZip?.length > 0 ||
        selectedFilters?.excludeSelectedZip?.length > 0 ||
        selectedFilters?.tech_stack?.length > 0 ||
        selectedFilters?.minEmployees !== 1 ||
        selectedFilters?.maxEmployees !== 10000 ||
        selectedFilters?.minRevenue !== 100000 ||
        selectedFilters?.maxRevenue !== 1000000000 ||
        selectedFilters?.emailGradeA !== true ||
        selectedFilters?.emailGradeB !== true
      ) {
        return false;
      }
      return true;
    };
    return (
      <div>
        <div
          className={`border border-[#DAE7DE] ${
            showContent
              ? "md:w-[18rem] w-full"
              : "w-[90px] flex flex-col items-center "
          }`}
        >
          <h3
            className={`w-full text-xl md:flex hidden items-center justify-${
              showContent ? "between" : "center"
            } border-b border-[#DAE7DE] py-[1.25rem] px-[1rem] font-bold text-[#1D201D] `}
          >
            {showContent && (
              <span className="hidden md:block">Search option</span>
            )}
            <span
              onClick={toggleContent}
              className=" font-bold cursor-pointer inline-block"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/${
                  showContent
                    ? "arrow-close.png"
                    : "searchOption/siderbar-close.png"
                }`}
                className="h-6 w-6"
                alt="toggle"
              />
            </span>
          </h3>
          <Accordion
            type="single"
            collapsible
            onValueChange={setOpenItem}
            className="w-full"
          >
            {optionMenuItems?.map((item, index) => (
              <AccordionItem
                key={index}
                value={`item-${index}`}
                className={`border-b ${
                  openItem === `item-${index}`
                    ? "border border-[#50c878]"
                    : "border-[#DAE7DE]"
                }`}
              >
                <AccordionTrigger
                  className="w-full"
                  onClick={() => setShowContent(true)}
                >
                  <div className="flex items-center justify-center text-[#313D31] text-base py-[1.25rem] px-[1rem]">
                    <img src={item.icon} className="h-6 w-6" alt={item.name} />

                    {showContent && (
                      <div className="flex-1 flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <div>
                            <span
                              className={`ml-2 text-base ${
                                openItem === `item-${index}` && "text-[#50c878]"
                              }`}
                            >
                              {item.name}
                            </span>
                          </div>
                          <span className="text-[#919191]">
                            <span
                              className={`text-[#919191] ${
                                openItem === `item-${index}` &&
                                "!text-[#50c878]"
                              }`}
                            >
                              {item?.count ? `(${item.count})` : ""}
                            </span>
                          </span>
                        </div>
                        <div
                          className={`transition-all duration-300 ${
                            openItem === `item-${index}` ? "rotate-180" : ""
                          }`}
                        >
                          <Icons.DownArrow
                            color={
                              openItem === `item-${index}`
                                ? "#50c878"
                                : "#919191"
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionTrigger>
                {showContent && (
                  <AccordionContent className="text-sm text-gray-600  px-[1rem]">
                    {item?.items}
                  </AccordionContent>
                )}
              </AccordionItem>
            ))}
          </Accordion>

          <div
            className={`${
              showContent ? "mt-5" : ""
            } flex flex-col py-[1.25rem] px-[1rem] gap-2`}
          >
            <button
              className="search-btn bg-[#50C878] text-white py-2 px-4 rounded mb-2 flex gap-1 items-center justify-center"
              onClick={() => {
                handleSaveCriteria();
                toggleTable();
              }}
              disabled={loading}
            >
              <Icons.SearchIcon size="18" />
              {showContent && <span className="text-sm">Search</span>}
            </button>

            <button
              className="clear-criteria-btn text-[#FB4747] py-2 px-4 rounded border border-[#FB4747] mb-2 flex items-center justify-center disabled:cursor-not-allowed disabled:text-[#9ca3af] disabled:border-[#9ca3af]"
              disabled={disabledClearCriteria()}
              onClick={handleClearFilters}
            >
              {/* <img
                src={`${process.env.PUBLIC_URL}/images/searchOption/cancel.png`}
                className={`h-5 w-5 ${!showContent ? "" : "mr-2"}`}
                alt="clear"
              /> */}
              <div className={` ${!showContent ? "" : "mr-2"}`}>
                <Icons.X
                  color={disabledClearCriteria() ? "#9ca3af" : "#fb4747"}
                  width="1.25rem"
                  height="1.25rem"
                />
              </div>
              {showContent && (
                <span className="text-sm">Clear all criteria</span>
              )}
            </button>
            <button
              disabled={!filters?.data || filters?.data?.length < 1}
              className=" text-lime-green py-2 px-4 rounded mb-2 flex gap-2 items-center justify-center disabled:cursor-not-allowed disabled:text-[#9ca3af]"
              onClick={() => {
                toggleTable();
                setShowPopup(true);
              }}
            >
              <Icons.saveIcon
                color={
                  !filters?.data || filters?.data?.length < 1 ? "#9ca3af" : ""
                }
              />
              {showContent && (
                <span className="text-sm">Save search criteria</span>
              )}
            </button>
          </div>

          <Popup
            open={showPopup}
            onClose={() => {
              setShowPopup(false);
            }}
            title="Save search criteria"
          >
            <div className="flex flex-col gap-3 items-end">
              <Input
                value={saveCriteriaName}
                onChange={(e) => setSaveCriteriaName(e.target.value)}
                type="text"
                placeholder="Enter search criteria name"
              />
              <Button
                className="bg-lime-green text-white"
                onClick={handleSaveCriteriaName}
              >
                Save
              </Button>
            </div>
          </Popup>
        </div>
        {!disabledClearCriteria() && (
          <p
            className="text-center text-[#FB4747] text-sm mt-[1rem] w-fit mx-auto hover:underline cursor-pointer"
            onClick={handleOpenModal}
          >
            {showContent ? (
              "Can't find lead? Request data"
            ) : (
              <Icons.InfoIcon size="16" />
            )}
          </p>
        )}
        {isModalOpen && (
          <RequestDataPopup
            onClose={handleCloseModal}
            selectIndustry={selectedFilters?.selectIndustry}
          />
        )}
      </div>
    );
  }
);

export default SearchOptions;
