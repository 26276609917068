import React from "react";
import CreatableSelect from "react-select/creatable"; // Import CreatableSelect to allow new tag creation
const CustomSelect = ({
  options,
  isMultiple,
  onChange,
  placeholder,
  height,
  value,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,

      minHeight: height ? height : "",
      cursor: "pointer",
      borderColor: state.isFocused ? "#50c878" : base.borderColor, // Change border color on focus
      boxShadow: state.isFocused ? "0 0 0 1px #50c878" : base.boxShadow, // Optional: Add a box shadow on focus
      "&:hover": {
        borderColor: state.isFocused ? "#50c878" : base.borderColor, // Ensure border stays blue on hover when focused
      },
    }),
    option: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "#f7f7f7",
      },
      ":active": {
        backgroundColor: "#f7f7f7",
      },
      "&:focus": {
        backgroundColor: "#f7f7f7",
      },
      backgroundColor: state.isFocused ? "#f7f7f7" : base.backgroundColor,

      // color: state.isSelected ? "white" : "black",
      // backgroundColor: state.isSelected ? "#50c878" : "white",
    }),
  };
  return (
    <CreatableSelect
      options={options}
      isMulti={isMultiple}
      onChange={onChange}
      value={value}
      className="basic-single"
      classNamePrefix="select"
      placeholder={placeholder}
      styles={customStyles}
      formatCreateLabel={(inputValue) => inputValue} // Customize the label when creating a new value
      createOptionPosition="first" // Newly created options will appear first
    />
  );
};
export default CustomSelect;
