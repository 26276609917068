import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding leading 0
  const day = String(date.getDate()).padStart(2, "0"); // Adding leading 0
  return `${year}-${month}-${day}`;
};

const ActivityGraph = ({ data }) => {
  // Ensure activities data is available and format date
  const activityData = data?.activities?.map((item) => ({
    ...item,
    week_start: formatDate(item.week_start), // Convert date to "YYYY-MM-DD"
  }));

  return (
    <div className="flex-1">
      <h3 className="text-left mb-2 font-bold text-xl text-text-color">
        Activity
      </h3>
      <div className="bg-white p-5 rounded-lg shadow-card-shadow">
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={activityData}
            barSize={50}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            barCategoryGap="20%"
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="week_start" className="text-xs" axisLine={false} />
            <YAxis className="text-xs" axisLine={false} />
            <Tooltip />
            <Bar dataKey="Credits used" fill="#66c987" radius={[4, 4, 0, 0]}>
              <LabelList
                dataKey="count"
                position="insideTop"
                fill="#fff"
                className="text-xs"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ActivityGraph;
