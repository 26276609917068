import { Link } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import TableSkeleton from "../../molecules/TableSkeleton";

export default function BasicInformation({ customerDetails, loading }) {
  const customerBasicInfo = customerDetails?.data?.customer;

  const getTimeAgo = (createdAt) => {
    const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "week", seconds: 604800 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
    ];

    if (!createdAt) return "Unknown";

    const createdDate = new Date(createdAt);
    const seconds = Math.floor((new Date() - createdDate) / 1000);
    const interval = intervals.find((i) => seconds >= i.seconds);

    if (interval) {
      const count = Math.floor(seconds / interval.seconds);
      return `Almost ${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
    }

    return "Just now";
  };

  const createdAt = customerDetails?.data?.customer?.created_at;
  const timeAgo = getTimeAgo(createdAt);

  const headers = [
    "Name",
    "Email",
    "Intercom",
    "Stripe",
    "Signed up",
    "Exports",
    "Credits",
    "LTV",
  ];
  const data = [
    [
      <div className="flex w-[25%] items-center gap-1">
        <Icons.UserIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {customerBasicInfo?.name}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.EmailWhite />
        <div className="text-[#313d31] text-xs font-normal">
          {customerBasicInfo?.email}
        </div>
      </div>,
      <Link
        to="/onboard"
        class="text-[#50c878] text-xs font-medium  underline leading-none"
      >
        View in intercom
      </Link>,
      "-",
      <div className="flex gap-1 items-center">
        <Icons.CaleanderIcon />
        <div className="text-[#313d31] text-xs font-normal">{timeAgo}</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.DowloadIcon />
        <div className="text-[#313d31] text-xs font-normal"> 1</div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.DollorIcon />
        <div className="text-[#313d31] text-xs font-normal"> 10</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.DollorIcon />
        <div className="text-[#313d31] text-xs font-normal"> $0.00</div>
      </div>,
    ],
  ];

  return (
    <div className="w-full ">
      {loading ? (
        <TableSkeleton rows={1} columns={6} />
      ) : (
        <Table headers={headers} data={data} length={data?.length} />
      )}
    </div>
  );
}
