import React from 'react'
import Icons from '../../assets/icons'

const Popup = ({ children, title, open, onClose }) => {
    return (open && (
        <div className='fixed inset-0 z-[9990] flex justify-center items-center'>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-[1]" />
            <div className='bg-white p-4 rounded-lg relative z-[2] md:max-w-[40rem] w-[85%]'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-xl font-bold'>{title}</h1>
                    <button className='text-xl font-bold' onClick={onClose}>
                        <Icons.CloseIcon />
                    </button>
                </div>
                <div className='mt-4'>
                {children}
                </div>
            </div>
        </div>
    ))
}

export default Popup