import { Link, useParams } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { useEffect, useState } from "react";
import { GET } from "../../../services/apiServices";
import Pagination from "../../molecules/Pagination";

export default function Export() {
  const [searchData, setSearchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { id: customerId } = useParams();
  const getStatusList = async (page) => {
    const response = await GET(
      `/export/customer/${customerId}?page=${page || currentPage}`
    );
    setSearchData(response?.data);
  };
  useEffect(() => {
    getStatusList();
  }, []);
  console.log(searchData);
  const handlePageCahnge = async (page) => {
    setCurrentPage(page);
    getStatusList(page);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth; you can omit it if not needed
    });
  };
  // database?past-export=25378
  const headers = [
    "Number of Leads",
    "File Name",
    "Exported At",
    "View Export Search",
  ];
  const data = searchData?.map((customer, index) => [
    <div className="flex w-[25%] items-center gap-1">
      <div className="text-[#313d31] text-xs font-medium">
        {customer?.num_leads}
      </div>
    </div>,
    <div className="flex gap-1 items-center">
      <div className="text-[#313d31] text-xs font-medium">
        {" "}
        {customer?.original_filename}{" "}
      </div>
    </div>,

    <div className="flex gap-1 items-center">
      <Icons.CaleanderIcon />
      <div className="text-[#313d31] text-xs font-medium">
        {" "}
        {new Date(customer?.created_at).toDateString()}
      </div>
    </div>,
    <Link
      to={`/database?past-export=${customer.id}`}
      className="text-[#50c878] text-xs font-medium underline leading-none"
    >
      View detail
    </Link>,
  ]);

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} />
      {searchData?.pagination?.totalPages > 1 ? (
        <div className="mb-10">
          <Pagination
            currentPage={currentPage}
            totalPages={searchData?.pagination?.totalPages}
            onPageChange={handlePageCahnge}
          />
        </div>
      ) : null}
    </div>
  );
}
