import { Link } from "react-router-dom";
import Icons from "../assets/icons";
import Table from "../components/molecules/Table";
import { Button } from "../components/atoms/buttons";

export default function CreateSuppersion() {
  const headers = [
    "Suppression Type",
    <div>
      Required Header{" "}
      <span className="text-[#777977] text-xs font-normal  leading-none">
        (Caps Sensitive){" "}
      </span>
    </div>,
    "Create",
  ];
  const data = [
    [
      <div className="flex gap-1 items-center">
        <Icons.EmailWhite />
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          Email exclusion list
        </div>
      </div>,
      <div className="flex items-center gap-1">
        <Icons.EmailWhite />
        <div className="text-[#313d31] text-xs font-normal">“email”</div>
      </div>,

      <Button
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
        onClick={() => console.log("View clicked")}
      >
        Upload “email”
      </Button>,
      ,
    ],
  ];

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className=" items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
          Create Suppression Lists
        </h1>
        <div class="text-[#313d31] text-xl font-normal  leading-7">
          Upload CSV
        </div>
      </div>
      <Table headers={headers} data={data} length="2" />
      <Button
        className="bg-green-500 hover:bg-green-600 text-xl font-medium text-white px-10 py-2 rounded-md mt-5"
        onClick={() => console.log("View clicked")}
      >
        Back to suppression lists
      </Button>
    </div>
  );
}
