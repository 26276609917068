import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../atoms/buttons";
import StepHeading from "../../atoms/StepHeading";
import { updateForm } from "../../../redux/slices/onBoardingSlice";

const CompanySizeStep = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { numEmployees } = useSelector((state) => state.onBoarding);
  const [errors, setErrors] = useState({}); // To manage field errors

  // Map display names to their numerical values
  const sizeMapping = {
    "1-10 employees": 10,
    "10 - 50 employees": 50,
    "50 - 100 employees": 101,
    "100+ employees": 100,
    "200+ employees": 200,
  };

  // Handle button click to update selected size in Redux store
  const handleSizeClick = (size) => {
    dispatch(updateForm({ numEmployees: sizeMapping[size] }));
    // Clear error if a selection is made
    setErrors((prev) => ({ ...prev, numEmployees: "" }));
  };

  // Validate before proceeding
  const validateForm = () => {
    const newErrors = {};
    if (!numEmployees) {
      newErrors.numEmployees = "Please select your company size.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle next step
  const handleNext = () => {
    if (validateForm()) {
      onNext(); // Proceed to next step only if valid
    }
  };

  return (
    <div className="">
      <StepHeading>Let's get your LimeLeads account setup</StepHeading>
      <div className="flex flex-wrap gap-2  max-w-[45rem]">
        {Object.keys(sizeMapping).map((size) => (
          <Button
            key={size}
            variant="outline"
            className={`border border-lime-green text-dark-green hover:bg-green-50 font-medium ${
              numEmployees === sizeMapping[size] ? "bg-green-50" : "bg-white"
            }`}
            onClick={() => handleSizeClick(size)}
          >
            {size}
          </Button>
        ))}
      </div>
      {errors.numEmployees && (
        <p className="text-red-500 text-sm mb-7 mt-2">{errors.numEmployees}</p>
      )}{" "}
      {/* Error message */}
      <div className="flex items-center gap-3 mt-7">
        <Button
          className="bg-[#1D201D] text-white font-medium py-2 px-6"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          className="bg-lime-green hover:bg-lime-green/90 text-white font-medium py-2 px-6"
          onClick={handleNext}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CompanySizeStep;
