import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../atoms/buttons";
import toast from "react-hot-toast";
import { deleteLead } from "../../redux/slices/pastExportsSlice";

const DeleteDataPopUp = ({ handleCancel }) => {
  const [formValues, setFormValues] = useState({
    email: "",
    isCompany: null,
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRadioChange = (e) => {
    const value = e.target.value === "company";
    setFormValues((prevValues) => ({
      ...prevValues,
      isCompany: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formValues.email) {
      formErrors.email = "Email is required.";
    }
    if (formValues.isCompany === null) {
      formErrors.isCompany = "Please select Company or Person.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleDelete = () => {
    if (validateForm()) {
      const payload = {
        email: formValues.email,
        isCompany: formValues.isCompany,
      };

      dispatch(deleteLead(payload))
        .unwrap()
        .then((response) => {
          toast.success("Data deleted successfully");
          handleCancel();
        })
        .catch((error) => {
          toast.error(`Failed to delete data: ${error.message || error}`);
        });
    }
  };

  return (
    <div className="w-full flex flex-col gap-5 justify-start items-start px-2">
      <p className="font-sans font-[700] text-[#1D201D] text-[20px]">
        Delete Data
      </p>
      <div className="w-full">
        <div>
          <label className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="isCompany"
              value="company"
              onChange={handleRadioChange}
              className="mr-2 w-[16px] h-[16px] cursor-pointer"
              style={{ accentColor: "rgb(16 156 63)" }}
            />
            Company
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="isCompany"
              value="person"
              onChange={handleRadioChange}
              className="mr-2 w-[16px] h-[16px] cursor-pointer"
              style={{ accentColor: "rgb(16 156 63)" }}
            />
            Person
          </label>
          {errors.isCompany && (
            <p className="text-red-500 text-sm mt-1">{errors.isCompany}</p>
          )}
        </div>
      </div>
      <div className="mb-4 w-full">
        <label className="block mb-2 font-sans font-[700] text-[#1D201D] text-base">
          Email
        </label>
        <input
          type="email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
          className={`w-full h-[40px] p-2 border rounded focus:outline-none ${
            errors.email ? "border-red-500" : ""
          }`}
          placeholder="Enter Email..."
        />
        {errors.email && (
          <p className="text-red-500 text-sm mt-1">{errors.email}</p>
        )}
      </div>
      <div className="flex w-full justify-end">
        <Button
          onClick={handleCancel}
          className="mr-2 bg-white w-[75px] h-[36px] border border-[#50C878] text-[#50C878] font-sans font-medium text-[13px]"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          className="bg-[#FB4747] text-white font-sans font-medium text-[13px] w-[104px] h-[36px]"
        >
          Delete Data
        </Button>
      </div>
    </div>
  );
};

export default DeleteDataPopUp;
