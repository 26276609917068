// store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice"; // Auth slice (to be created)
import onBoardingSlice from "./slices/onBoardingSlice";
import { combineReducers } from "redux";
import savedSearchesReducer from "./slices/savedSearchesSlice"; // Import the savedSearchesReducer
import getUserDetailSlice from "./slices/getUserDetailSlice";
import getCompanyUserSlice from "./slices/getCompanyUserSlice";
import inviteSlice from "./slices/inviteSlice";
import companyPendingInvitesSlice from "./slices/companyPendingInvitesSlice"; // Import the new company pending invites slice
import filtersSlice from "./slices/searchSlice";
import getAllCustomersSlice from "./slices/getAllCustomersSlice";
import createCustomerSlice from "./slices/createCustomerSlice";
import billingSlice from "./slices/billingSlice";
import creditSlice from "./slices/creditSlice";
import counterSlice from "./slices/counterSlice";
import pastExportsReducer from "./slices/pastExportsSlice";
import creditHistorySlice from "./slices/creditHistorySlice";
import requestDataSlice from "./slices/requestDataSlice";
import customerStatsReducer from './slices/customerStatsSlice';

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  onBoarding: onBoardingSlice,
  savedSearches: savedSearchesReducer,
  userDetail: getUserDetailSlice,
  getCompanyUser: getCompanyUserSlice,
  invite: inviteSlice, // Existing invite reducer
  companyPendingInvites: companyPendingInvitesSlice, // Add the new company pending invites reducer
  search: filtersSlice,
  //Admin
  customers: getAllCustomersSlice,
  createCustomer: createCustomerSlice,
  billing: billingSlice,
  credit: creditSlice,
  counter: counterSlice,
  pastExports: pastExportsReducer,
  getCreditHistory: creditHistorySlice,
  request: requestDataSlice,
  customerStats: customerStatsReducer,
});

// Configure persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Only persist auth slice
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for all actions
    }),
});

export const persistor = persistStore(store);
