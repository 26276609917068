import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, PATCH } from "../../services/apiServices";

// Create an async thunk for fetching saved searches
export const fetchUserDetail = createAsyncThunk(
  "getUserDetail/fetchUserDetail",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GET("/customer/details"); // Call the API
      const num_credits = response?.data?.customer?.company?.num_credits;
      if (num_credits) {
        localStorage.setItem("credits", num_credits);
      }

      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

//Update User Account
export const updateUserDetail = createAsyncThunk(
  "updateUserDetail/updateUserDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PATCH("/customer/update", data); // Call the API
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

//Update User Profile Image
export const updateProfileImage = createAsyncThunk(
  "updateUserDetail/updateProfileImage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PATCH("/customer/update-profile-image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getUserDetailSlice = createSlice({
  name: "userDetail",
  initialState: {
    userDetail: [], // Ensure this is defined
    loading: false,
    error: null,
    credit: 0,
  },
  reducers: {
    setCredit: (state, { payload }) => {
      return { ...state, credit: payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetail = action.payload;
        state.credit = action?.payload?.data?.customer?.company?.num_credits;
      })
      .addCase(fetchUserDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUserDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetail = action.payload;
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        // You might want to update the user details here if the API returns updated user data
        // state.userDetail = action.payload;
      });
  },
});

// Export the action and reducer
export const { setCredit } = getUserDetailSlice.actions;
export default getUserDetailSlice.reducer;
