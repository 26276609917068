import React, { useState } from "react";
import { Button } from "./buttons";
import RequestDataPopup from "../molecules/RequestDataPopup";

const RequestDataCard = ({ updates }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex-1">
      <h3 className="text-left font-bold text-xl text-text-color mb-4">
        Request Data
      </h3>
      <div className="bg-white p-5 rounded-lg shadow-card-shadow h-[23rem]">
        {updates.map((update, index) => (
          // <div key={index} className="mb-6 text-left">
          //   <p className="text-[#313D31] font-bold text-sm mb-2">
          //     {update.description}
          //   </p>
          //   <Button
          //     className="bg-green-500 text-white font-bold py-2 px-4 rounded mt-3"
          //     onClick={handleOpenModal}
          //   >
          //     Request Data
          //   </Button>
          // </div>
          <div key={index} className="mb-6 text-left">
            <p className="text-[#313D31] font-bold text-sm mb-2">
              {update.description}
            </p>
            <div className="flex justify-center mt-3">
              {" "}
              {/* Centering only the button */}
              <Button
                className="bg-green-500 text-white font-bold py-2 px-4 rounded"
                onClick={handleOpenModal}
              >
                Request Data
              </Button>
            </div>
          </div>
        ))}
        {isModalOpen && <RequestDataPopup onClose={handleCloseModal} />}
      </div>
    </div>
  );
};

export default RequestDataCard;
