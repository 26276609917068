import { Link } from "react-router-dom";
import Icons from "../assets/icons";
import Table from "../components/molecules/Table";

export default function GdprList() {
  const headers = [
    "Email",
    "Domain",
    "Nmae",
    "State",
    "Invalidated leads",
    "Action",
  ];
  const data = [
    [
      <div className="flex gap-1 items-center">
        <Icons.EmailWhite />
        <div className="text-[#313d31] text-xs font-normal"> Sam@gmail.com</div>
      </div>,
      <div className="flex items-center gap-1">
        <Icons.DomainIcon />
        <div className="text-[#313d31] text-xs font-normal">myphoner.com</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.UserIcon />
        <div className="text-[#313d31] text-xs font-normal">Sam Berg</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.BankIcon />
        <div className="text-[#313d31] text-xs font-normal"> Alaska</div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.EmailWhite />
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          jepee@myphoner.com
        </div>
      </div>,
      <Link
        to="/admin/gdprlist/1"
        class="text-[#50c878] text-xs font-medium  underline leading-none"
      >
        Show
      </Link>,
    ],
  ];

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
          View GDPR Delete Request
        </h1>
      </div>
      <Table headers={headers} data={data} />
    </div>
  );
}
