import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TabButton from "../../atoms/TabButton";
import YourCompany from "../../molecules/YourCompany";
import InviteUsers from "../../molecules/InviteUsers";
import CompanyPendingInvites from "../../molecules/CompanyPendingInvite";
import { useSelector } from "react-redux";
import CompanyUser from "../../molecules/CompanyUser";

export default function UserManage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetail, loading, error } = useSelector(
    (state) => state.userDetail
  );

  const yourCompanyData = userDetail?.data?.customer?.company;

  // Extract tab from URL, fallback to 'company' if no tab specified
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get("tab") || "company";

  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const tabs = [
    {
      label: "Your company",
      id: "company",
      content: <YourCompany data={yourCompanyData} />,
    },
    {
      label: "Your company's users",
      id: "companyUsers",
      content: <CompanyUser />,
    },
    {
      label: "Invite users",
      id: "inviteUsers",
      content: <InviteUsers />,
    },
    {
      label: "Your company's pending invites",
      id: "pendingInvites",
      content: <CompanyPendingInvites />,
    },
  ];

  // Update URL when the tab changes
  useEffect(() => {
    navigate(`?tab=${selectedTab}`, { replace: true });
  }, [selectedTab, navigate]);

  return (
    <div>
      <div className="mb-6">
        <nav className="flex space-x-6 border-b overflow-auto">
          {tabs.map((tab) => (
            <TabButton
              key={tab.id}
              label={tab.label}
              isActive={selectedTab === tab.id}
              onClick={() => setSelectedTab(tab.id)}
            />
          ))}
        </nav>
      </div>
      {tabs.map(
        (tab) => selectedTab === tab.id && <div key={tab.id}>{tab.content}</div>
      )}
    </div>
  );
}
