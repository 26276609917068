import { useDispatch, useSelector } from "react-redux";
import { Button } from "../components/atoms/buttons";
import Table from "../components/molecules/Table";
import {
  fetchSavedSearches,
  deleteSavedSearch,
} from "../redux/slices/savedSearchesSlice";
import { useEffect, useState } from "react";
import Spinner from "../components/atoms/Spinner";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../components/molecules/TableSkeleton";
import Pagination from "../components/molecules/Pagination";

export default function SavedSearches() {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { searches, loading, error, pagination } = useSelector(
    (state) => state.savedSearches
  );

  const navigate = useNavigate();
  const savedSearchesData = Array.isArray(searches) ? searches : [];

  // Local state to manage loading for each delete action
  const [deletingId, setDeletingId] = useState(null);

  const headers = [
    "#",
    "Saved on",
    "Description",
    "Net new contacts",
    "View search",
    "Delete",
  ];

  const truncateDescription = (description, maxLength = 20) => {
    if (description.length <= maxLength) return description;
    return description.slice(0, maxLength) + "...";
  };

  const data = savedSearchesData.map((search, index) => [
    index + 1,
    new Date(search.created_at).toLocaleDateString(),
    search.description,
    <Button
      className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
      onClick={() => console.log("Net new clicked for", search.id)}
    >
      {`${search?.count} net new`}
    </Button>,
    <Button
      className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
      onClick={() => {
        navigate(`/database?search=${search.id}`);
      }}
    >
      View saved search
    </Button>,
    <Button
      className={
        "relative text-white px-4 py-2 rounded-md bg-red-500 hover:bg-red-600"
      }
      onClick={() => handleDelete(search.id)}
      disabled={deletingId === search.id}
    >
      {deletingId === search.id ? <Spinner /> : "Delete"}{" "}
    </Button>,
  ]);

  const handleDelete = (searchId) => {
    setDeletingId(searchId); // Set the ID of the search being deleted
    dispatch(deleteSavedSearch(searchId)).then(() => {
      setDeletingId(null); // Reset deletingId after deletion
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchSavedSearches(page));
  };
  useEffect(() => {
    handlePageChange(1);
  }, [dispatch]);

  //if savedSearchesData.length ===0 and loading is false and page number is greater than 1 set page number to -1
  useEffect(() => {
    if (savedSearchesData.length === 0 && !loading && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      dispatch(fetchSavedSearches(currentPage - 1));
    }
  }, [savedSearchesData, loading]);

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb]">
      <div className="text-[#1d201d] text-xl font-bold  leading-7 mb-2">
        Saved searches
      </div>
      <p className="text-base text-[#313d31] mb-4">
        Find searches that you've saved in the past.
      </p>
      {loading ? (
        // Render spinner or skeleton when loading
        <div className="">
          <TableSkeleton rows={10} columns={6} header={false} />
        </div>
      ) : savedSearchesData?.length === 0 ? (
        <div className="flex justify-center items-center h-[60vh]">
          <p className="text-2xl text-gray-500">No data found</p>
        </div>
      ) : (
        <>
          <Table headers={headers} data={data} length={data?.length} />
          {pagination?.totalPages > 1 ? (
            <div className="mb-10">
              <Pagination
                currentPage={currentPage}
                totalPages={pagination?.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
