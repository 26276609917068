import { createSlice } from "@reduxjs/toolkit";

const paddleSlice = createSlice({
  name: "paddle",
  initialState: {
    paddle: null,
  },
  reducers: {
    setPaddle: (state, action) => {
      state.paddle = action.payload;
    },
    getPaddle: (state) => {
      return state.paddle;
    },
  },
});

export const { setPaddle, getPaddle } = paddleSlice.actions;
export default paddleSlice.reducer;
