import React, { useState } from "react";
import { Button } from "../atoms/buttons";
import toast from "react-hot-toast";
import { store } from "../../redux/store";
import axios from "axios";
import { IMPORT_LEADS_URL } from "../../services/apiServices";

const ImportLeadsPopup = ({ handleCancel }) => {
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Added loading state

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
      setErrors("");
    } else {
      setErrors("Only CSV files are allowed.");
      setFile(null);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 content
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUpload = async () => {
    if (!file) {
      setErrors("Please select a CSV file to upload.");
      return;
    }

    setIsLoading(true); // Set loading state
    try {
      const base64Data = await convertToBase64(file);
      const payload = { file: base64Data };
      const token = store.getState()?.auth?.data?.token;

      const response = await axios.post(IMPORT_LEADS_URL, payload, {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          toast.loading(`File upload progress: ${progress}%`, {
            id: "upload-toast",
          });
        },
      });
      toast.dismiss("upload-toast");
      toast.success(response.data?.message);
      handleCancel();
    } catch (error) {
      toast.dismiss("upload-toast");
      toast.error(
        error.response?.data?.message ?? "Error converting file to Base64."
      );
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div className="w-full flex flex-col gap-5 justify-start items-start px-2">
      <p className="font-sans font-[700] text-[#1D201D] text-[20px]">
        Import Leads
      </p>
      <div className="mb-4 w-full">
        <label className="block mb-2 font-sans font-[700] text-[#1D201D] text-base">
          Upload CSV
        </label>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="w-full h-[40px] p-2 border rounded focus:outline-none"
        />
        {errors && <p className="text-red-500 text-sm mt-1">{errors}</p>}
      </div>
      <div className="flex w-full justify-end">
        <Button
          onClick={() => {
            setFile(null);
            handleCancel();
          }}
          className="mr-2 bg-white w-[75px] h-[36px] border border-[#50C878] text-[#50C878] font-sans font-medium text-[13px]"
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          className={`bg-[#50C878] text-white font-sans font-medium text-[13px] w-[104px] h-[36px] ${
            !file || isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!file || isLoading} // Disable button during loading
        >
          {isLoading ? "Uploading..." : "Upload"} {/* Loading text */}
        </Button>
      </div>
    </div>
  );
};

export default ImportLeadsPopup;
