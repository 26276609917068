import { Link, useParams } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { useEffect, useState } from "react";
import { GET } from "../../../services/apiServices";
import Pagination from "../../molecules/Pagination";

export default function Status() {
  // const [searchData, setSearchData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const { id: customerId } = useParams();
  // const getStatusList = async (page) => {
  //   const response = await GET(
  //     `/customer/purchase-records/${customerId}?page=${page || currentPage}`
  //   );
  //   setSearchData(response?.data);
  // };
  // useEffect(() => {
  //   getStatusList();
  // }, []);
  // const handlePageCahnge = async (page) => {
  //   setCurrentPage(page);
  //   getStatusList(page);
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth", // This makes the scroll smooth; you can omit it if not needed
  //   });
  // };
  // const headers = ["Purchased Credits", "Interval", "Purchased At", "Amount"];
  // const data = searchData?.purchaseRecords?.map((customer, index) => [
  //   <div className="flex w-[25%] items-center gap-1">
  //     <div className="text-[#313d31] text-xs font-medium">
  //       {customer?.num_credits_purchased}
  //     </div>
  //   </div>,
  //   <div className="flex gap-1 items-center">
  //     {/* <Icons.CaleanderIcon /> */}
  //     <div className="text-[#313d31] text-xs font-medium">
  //       {" "}
  //       {customer?.interval}{" "}
  //     </div>
  //   </div>,

  //   <div className="flex gap-1 items-center">
  //     <Icons.CaleanderIcon />
  //     <div className="text-[#313d31] text-xs font-medium">
  //       {new Date(customer.created_at).toDateString()}
  //     </div>
  //   </div>,

  //   <div className="flex gap-1 items-center">
  //     <Icons.DollorIcon />
  //     <div className="text-[#313d31] text-xs font-medium">
  //       {" "}
  //       $ {customer?.amount_in_cents / 100}{" "}
  //     </div>
  //   </div>,
  // ]);

  // Disabling this feature for now, we will enable it after paddle migration completes
  // return (
  //   <div className="w-full ">
  //     <Table headers={headers} data={data} />
  //     {searchData?.pagination?.totalPages > 1 ? (
  //       <div className="mb-10">
  //         <Pagination
  //           currentPage={currentPage}
  //           totalPages={searchData?.pagination?.totalPages}
  //           onPageChange={handlePageCahnge}
  //         />
  //       </div>
  //     ) : null}
  //   </div>
  // );

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb]">
      <div className="flex flex-col justify-center items-center h-[60vh]">
        <div className="mb-4">
          <Icons.CompanyInvite />
        </div>
        <p className="text-lg text-[#313D31] text-center mb-6 px-[18rem]">
          This feature is temporarily disabled as we are in the process of
          migrating our payment system to Paddle. Once the migration is
          complete, you'll be able to view detailed subscription and payment
          status information here. We appreciate your patience during this
          transition.
        </p>
      </div>
    </div>
  );
}
