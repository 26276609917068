import { useCallback, useEffect } from "react";
import { loadRecaptcha } from "../utils/load-recaptcha";

const useRecaptcha = () => {
  /**
   * Execute reCAPTCHA v3 and return the token.
   */
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  useEffect(() => {
    if (siteKey) {
      loadRecaptcha(siteKey);
    }
  }, [siteKey]);
  const executeRecaptcha = useCallback(async (action = "submit") => {
    if (!window.grecaptcha) {
      console.warn("reCAPTCHA has not been loaded");
      return "";
    }
    try {
      const token = await window.grecaptcha.execute(siteKey, { action });
      return token;
    } catch (err) {
      console.error("reCAPTCHA error:", err);
      return "";
    }
  }, []);

  return { executeRecaptcha };
};

export default useRecaptcha;
