// import React, { useEffect, useState } from "react";
// import { useSearchParams } from "react-router-dom";
// import { fetchCompanyData } from "../redux/slices/authSlice";
// import { useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";

// const Invite = () => {
//   const dispatch = useDispatch();
//   const { id: companyId } = useParams();

//   const fetchCompanyId = async () => {
//     if (companyId) {
//       try {
//         // Dispatch the fetchCompanyData action and wait for the response
//         const result = await dispatch(fetchCompanyData(companyId));
//         console.log("result", result);
//       } catch (error) {
//         console.error("Failed to fetch company data:", error);
//       }
//     }
//   };
//   useEffect(() => {
//     fetchCompanyId();
//   }, [companyId, dispatch]);

//   return <div>Invite</div>;
// };

// export default Invite;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchCompanyData } from "../redux/slices/authSlice";
import { useDispatch } from "react-redux";

const Invite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: companyId } = useParams();
  const [loading, setLoading] = useState(true);

  const fetchCompanyId = async () => {
    if (companyId) {
      try {
        // Dispatch the fetchCompanyData action and wait for the response
        const result = await dispatch(fetchCompanyData(companyId));
        console.log("result111", result);

        // Assuming the result has a 'success' property
        if (result.payload && result.payload.success) {
          // Navigate to signup if response is true
          navigate(`/signup/?slug=${companyId}`);
        }
      } catch (error) {
        console.error("Failed to fetch company data:", error);
      } finally {
        // Set loading to false regardless of success or error
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchCompanyId();
  }, [companyId, dispatch]);

  if (loading) {
    return <div>Loading...</div>; // Display loader while fetching data
  }

  return <div>Invite</div>;
};

export default Invite;
