import React, { useEffect, useState } from "react";
import { Input } from "../../atoms/input";
import { Button } from "../../atoms/buttons";
import FormLabel from "../../atoms/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDetail,
  updateUserDetail,
  updateProfileImage,
} from "../../../redux/slices/getUserDetailSlice";
import toast from "react-hot-toast";
import { updatePassword } from "../../../redux/slices/authSlice";
import { FaCamera } from "react-icons/fa";

const AccountUpdateForm = () => {
  const dispatch = useDispatch();
  const { userDetail, loading } = useSelector((state) => state.userDetail);
  const userData = userDetail?.data?.customer;

  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isImageSelected, setIsImageSelected] = useState(false);

  useEffect(() => {
    if (userData) {
      setFormData({
        name: userData.name || "",
        email: userData.email || "",
      });
      setPreviewImage(
        userData.profile_image
          ? `${process.env.REACT_APP_IMAGE_URL}/${userData.profile_image}`
          : null
      );
    }
  }, [userData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      await dispatch(updateUserDetail(formData)).unwrap();
      toast.success("Account updated successfully");
      dispatch(fetchUserDetail()); // Fetch updated user details
    } catch (error) {
      toast.error("Failed to update account");
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsUpdatingPassword(true);
    try {
      await dispatch(updatePassword(passwordData)).unwrap();
      toast.success("Password updated successfully");
      setPasswordData({ currentPassword: "", newPassword: "" });
    } catch (error) {
      toast.error(error.message || "Failed to update password");
    } finally {
      setIsUpdatingPassword(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setIsImageSelected(true);
    }
  };

  const handleImageUpload = async () => {
    if (!profileImage) return;

    setIsUpdatingImage(true);
    const formData = new FormData();
    formData.append("profile_image", profileImage);

    try {
      const response = await dispatch(updateProfileImage(formData)).unwrap();
      if (response && response.data && response.data.profile_image) {
        setPreviewImage(`${process.env.REACT_APP_IMAGE_URL}/${response.data.profile_image}`);
        toast.success("Profile image updated successfully");
        dispatch(fetchUserDetail()); // Fetch updated user details
      }
    } catch (error) {
      console.error("Failed to update profile image:", error);
      toast.error("Failed to update profile image");
    } finally {
      setIsUpdatingImage(false);
      setIsImageSelected(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      {/* <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Account Settings</h1> */}
      
      {/* Profile Image Section */}
      {/* <div className="mb-8 text-center">
        <div className="relative inline-block mb-4">
          <img
            src={previewImage || "https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"}
            alt="Profile"
            className="w-32 h-32 rounded-full object-cover"
          />
          <label htmlFor="profile-image" className="absolute bottom-0 right-0 bg-white p-2 rounded-full cursor-pointer">
            <FaCamera className="text-gray-600" />
          </label>
          <input
            type="file"
            id="profile-image"
            className="hidden"
            onChange={handleImageChange}
            accept="image/*"
          />
        </div>
        <Button
          onClick={handleImageUpload}
          className={`px-4 py-2 text-white font-medium rounded-md ${
            isUpdatingImage || !isImageSelected ? "bg-gray-400 cursor-not-allowed" : "bg-lime-green hover:bg-green-600"
          } transition-colors`}
          disabled={isUpdatingImage || !isImageSelected}
        >
          {isUpdatingImage ? "Uploading..." : "Upload Image"}
        </Button>
      </div> */}

      {/* Account Information Form */}
      <form onSubmit={handleSubmit} className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Account Information</h2>
        <div className="flex flex-wrap -mx-2 mb-4">
          <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
            <FormLabel htmlFor="name" className="text-gray-700">Name</FormLabel>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Your name"
              className="w-full placeholder:text-sm rounded-md border-gray-300 focus:border-lime-green focus:ring focus:ring-lime-green focus:ring-opacity-50"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="w-full md:w-1/2 px-2">
            <FormLabel htmlFor="email" className="text-gray-700">Email</FormLabel>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              className="w-full placeholder:text-sm rounded-md border-gray-300 focus:border-lime-green focus:ring focus:ring-lime-green focus:ring-opacity-50"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            type="submit"
            className={`px-4 py-2 text-white font-medium rounded-md ${
              isUpdating ? "bg-gray-400" : "bg-lime-green hover:bg-green-600"
            } transition-colors`}
            disabled={isUpdating}
          >
            {isUpdating ? "Updating..." : "Update Account"}
          </Button>
        </div>
      </form>

      {/* Change Password Form */}
      <form onSubmit={handlePasswordSubmit} className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Change Password</h2>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
            <FormLabel htmlFor="currentPassword" className="text-gray-700">Current Password</FormLabel>
            <Input
              type="password"
              id="currentPassword"
              name="currentPassword"
              placeholder="Current password"
              className="w-full placeholder:text-sm rounded-md border-gray-300 focus:border-lime-green focus:ring focus:ring-lime-green focus:ring-opacity-50"
              value={passwordData.currentPassword}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="w-full md:w-1/2 px-2">
            <FormLabel htmlFor="newPassword" className="text-gray-700">New Password</FormLabel>
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              placeholder="New password"
              className="w-full placeholder:text-sm rounded-md border-gray-300 focus:border-lime-green focus:ring focus:ring-lime-green focus:ring-opacity-50"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
            />
            {validationErrors.newPassword && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.newPassword}</p>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            type="submit"
            className={`px-3 py-2 text-white font-medium rounded-md ${
              isUpdatingPassword ? "bg-gray-400" : "bg-lime-green hover:bg-green-600"
            } transition-colors`}
            disabled={isUpdatingPassword}
          >
            {isUpdatingPassword ? "Updating..." : "Update Password"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountUpdateForm;
