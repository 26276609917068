import axios from "axios";
import { store } from "../redux/store";

// Define header keys used across the API calls
export const headerKeys = {
  AccessToken: "Authorization",
  ContentType: "Content-Type",
};

export const IMPORT_LEADS_URL =
  process.env.REACT_APP_ENV === "production"
    ? `https://import-leads-api.limeleads.com/api/v1/leads/import-leads`
    : `http://44.243.23.165:4000/api/v1/leads/import-leads`;

const customerToken = sessionStorage.getItem("customerToken");

// Create an axios instance with the base URL
const api = axios.create({
  // baseURL: "http://localhost:4005/api/v1",
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Function to set token externally and save it to localStorage
export function setToken(newToken) {
  // token = newToken;
  // localStorage.setItem("userToken", newToken); // Persist token in localStorage
}

// Function to remove token
export function removeToken() {
  // token = null;
  localStorage.clear();
  sessionStorage.clear();
}

// Interceptor to add token to request headers
api.interceptors.request.use((config) => {
  let token = customerToken || store.getState()?.auth?.data?.token;
  if (config.headers && token) {
    config.headers[headerKeys.AccessToken] = `Bearer ${token}`;
  } else {
    config.headers[headerKeys.ContentType] = "application/json";
  }
  return config;
});

// Handle responses and errors globally
api.interceptors.response.use(
  (response) => response.data, // Return only the response data
  (error) => {
    console.error("API error:", error);

    if (error.response) {
      console.log("Response error:", error.response, error.response.status);

      // Handle 401 Unauthorized (Token expired/invalid)
      if (error.response.status === 401) {
        removeToken();
        window.location.href = "/"; // Redirect to login
      }
    } else if (error.request) {
      console.log("No response received:", error.request);
    } else {
      console.log("Request setup error:", error.message);
    }
    return Promise.reject(error);
  }
);

// Utility function for POST requests
export function POST(url, body) {
  return api.post(url, body);
}

// Utility function for POST requests with multipart/form-data
export function POSTFILES(url, body) {
  return api.post(url, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

// Utility function for PATCH requests with multipart/form-data
export function PATCHFILES(url, body) {
  return api.patch(url, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

// Utility function for GET requests
export function GET(url, params) {
  return api.get(url, { params });
}

// Utility function for PATCH requests
export function PATCH(url, body) {
  return api.patch(url, body);
}

// Utility function for DELETE requests
export function DELETE(url) {
  return api.delete(url);
}
