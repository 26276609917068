import { useNavigate, useParams } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import Table from "../../molecules/Table";
import Counter from "../../atoms/Counter";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomerThunk,
  loginAsCustomer,
  submitCount,
} from "../../../redux/slices/counterSlice";
import toast from "react-hot-toast";
import ConfirmPopup from "./ConfirmPopup";

export default function AdminActions({ customerDetails }) {
  const dispatch = useDispatch();

  const { id: customerId } = useParams();
  const navigate = useNavigate();
  // States
  const [addCount, setAddCount] = useState(0);
  const [removeCount, setRemoveCount] = useState(0);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const customerEmail = customerDetails?.data?.customer?.email;
  const is_active = customerDetails?.data?.customer?.is_active;

  const handleSubmit = async (actionType, amount) => {
    toast.dismiss();
    if (amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }

    try {
      const result = await dispatch(
        submitCount({
          customerId,
          amount,
          action: actionType,
        })
      );

      if (result.meta.requestStatus === "fulfilled") {
        if (actionType === "add") {
          toast.success("Customer credits added successfully");
          setAddCount(0);
        } else if (actionType === "deduct") {
          toast.success("Customer credits deducted successfully");
          setRemoveCount(0);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteCustomer = async () => {
    try {
      setIsLoading(true);
      const result = await dispatch(deleteCustomerThunk({ customerId }));
      if (result.meta.requestStatus === "fulfilled") {
        navigate("/admin/customers");
      } else {
        console.log("result", result);
        toast.error(result?.payload?.message);
        setIsLoading(true);
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const handleLoginAsCustomer = async () => {
    try {
      const result = await dispatch(loginAsCustomer({ customerId }));
      if (result.meta.requestStatus === "fulfilled") {
        const token = result.payload.token;
        sessionStorage.setItem("customerToken", token);
        window.location.href = `${window.location.origin}/database`;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const headers = ["Add credits", "Remove credits", "Delete", "Login as"];
  const data = [
    [
      <div className="flex gap-1">
        <Counter count={addCount} setCount={setAddCount} />
        <button
          className="px-3 py-1 bg-lime-green hover:opacity-90 rounded text-white text-xs font-medium h-7"
          onClick={() => handleSubmit("add", addCount)}
        >
          Add
        </button>
      </div>,

      <div className="flex gap-1">
        <Counter count={removeCount} setCount={setRemoveCount} />
        <button
          className="px-3 py-1 bg-[#fb4747] hover:opacity-90 rounded text-white text-xs font-medium h-7"
          onClick={() => handleSubmit("deduct", removeCount)}
        >
          Remove
        </button>
      </div>,

      <button
        className="px-3 py-1 bg-[#fb4747] hover:opacity-90 rounded text-white text-xs font-medium h-7"
        onClick={() => setShowDeletePopup(true)}
      >
        Delete
      </button>,
      <button
        className={
          !is_active
            ? "px-3 py-1 bg-[#868282] hover:opacity-90 rounded text-white text-xs font-medium h-7"
            : "px-3 py-1 bg-lime-green hover:opacity-90 rounded text-white text-xs font-medium h-7"
        }
        onClick={handleLoginAsCustomer}
        disabled={!is_active}
      >
        Login as {customerEmail}
      </button>,
    ],
  ];

  return (
    <div className="w-full">
      <Table headers={headers} data={data} length={1} />
      {showDeletePopup && (
        <ConfirmPopup
          onClose={() => setShowDeletePopup(false)}
          handleDelete={deleteCustomer}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}
