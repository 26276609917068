import { useEffect, useState } from "react";
import { GET } from "../../../services/apiServices";
import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { Link, useParams } from "react-router-dom";
import Pagination from "../../molecules/Pagination";

export default function SavedSearches({ customerDetails }) {
  const [searchData, setSearchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { id: customerId } = useParams();
  const getSavedseracrhes = async (page) => {
    const response = await GET(
      `/saved-searches/customer/${customerId}?page=${page || currentPage}`
    );
    setSearchData(response?.data);
  };
  useEffect(() => {
    getSavedseracrhes();
  }, []);

  const headers = ["#", "Saved on", "Description", "View search"];
  const data = searchData?.savedSearches?.map((customer, index) => [
    <div className="flex w-[25%] items-center gap-3">
      <div className="text-[#313d31] text-xs font-normal">{customer?.id}</div>
    </div>,
    <div className="flex gap-1 items-center">
      <div className="text-[#313d31] text-xs font-normal">
        {new Date(customer.created_at).toDateString()}
      </div>
    </div>,
    <div className="flex gap-1 items-center">
      <div className="text-[#313d31] text-xs font-normal">
        {customer?.description}
      </div>
    </div>,
    <Link
      to={`/database?search=${customer.id}`}
      className="text-[#50c878] text-xs font-medium underline leading-none"
    >
      View detail
    </Link>,
  ]);
  const handlePageCahnge = async (page) => {
    setCurrentPage(page);
    getSavedseracrhes(page);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth; you can omit it if not needed
    });
  };
  return (
    <div className="w-full ">
      <Table headers={headers} data={data} />
      {searchData?.pagination?.totalPages > 1 ? (
        <div className="mb-10">
          <Pagination
            currentPage={currentPage}
            totalPages={searchData?.pagination?.totalPages}
            onPageChange={handlePageCahnge}
          />
        </div>
      ) : null}
    </div>
  );
}
