// src/Intercom.js
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const Intercom_APP_ID = "zdbbnjcs"; // Replace with your Intercom app ID

const Intercom = () => {
  const userDetail = useSelector(
    (state) => state.userDetail?.userDetail?.data?.customer
  );
  console.log("userDetail", userDetail);
  useEffect(() => {
    if (userDetail) {
      window.intercomSettings = {
        app_id: Intercom_APP_ID,
        user_id: userDetail.id.toString(),
        user_hash: userDetail.intercom_hash,
        name: userDetail.name,
        email: userDetail.email,
        created_at: new Date(userDetail.created_at).getTime(),
        company: {
          id: userDetail?.company?.id,
          name: userDetail?.company?.name,
          website: userDetail?.company?.website,
        },
      };
    } else {
      // Set Intercom settings
      window.intercomSettings = {
        app_id: Intercom_APP_ID,
      };
    }

    // Load the Intercom script
    const loadIntercom = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${Intercom_APP_ID}`;
      document.body.appendChild(script);

      script.onload = () => {
        if (window.Intercom) {
          window.Intercom("boot", window.intercomSettings);
        }
      };
    };

    // Only load Intercom if it's not already loaded
    if (!window.Intercom) {
      loadIntercom();
    } else {
      window.Intercom("update", window.intercomSettings);
    }

    return () => {
      // Cleanup: Remove the Intercom script when the component unmounts
      const scripts = document.querySelectorAll(
        `script[src="https://widget.intercom.io/widget/${Intercom_APP_ID}"]`
      );
      scripts.forEach((script) => script.remove());
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, []);

  return null; // This component does not render anything to the UI
};

export default Intercom;
