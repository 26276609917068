import React, { useState } from "react";
import { adminMenu, sidebarItems } from "../../data/menuItems";
import { NavLink } from "react-router-dom";
import Icons from "../../assets/icons";
import { useSelector } from "react-redux";
import { Modal, Button } from "antd";
import DeleteDataPopUp from "./DeleteDataPopUp";

const Sidebar = ({ isAdmin }) => {
  const { userDetail, loading: userDetailLoading } = useSelector(
    (state) => state.userDetail
  );

  const isCompanyAdmin = userDetail?.data?.customer?.is_company_admin;

  // Filter sidebar items based on isCompanyAdmin
  const filteredSidebarItems = isAdmin
    ? adminMenu
    : sidebarItems?.filter((item) => isCompanyAdmin || item.type !== "admin");

  // Modal visibility state
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Handlers to show and hide the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Handle the logic for "Delete Data" here
    console.log("Data deleted");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="w-[80px] md:w-[235px] p-4 flex flex-col border-r border-[#DAE7DE]">
      <div className="flex items-center justify-between">
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          className="h-8 w-[140px] hidden md:block object-contain"
        />
        <div className="block md:hidden">
          <Icons.LimeLogo />
        </div>
      </div>
      <div className="mt-10 flex flex-col items-center md:items-start">
        {filteredSidebarItems?.map((section, sectionIndex) => (
          <div key={sectionIndex} className=" w-full">
            <h3 className="text-[#1D201D99] text-[0.813rem] font-bold md:hidden items-center lg:text-left text-center">
              {section.labelMobile}
            </h3>
            <h3 className="text-[#1D201D99] text-[0.813rem] font-bold hidden md:block">
              {section.label}
            </h3>

            <div className="w-full">
              {section.items.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.url}
                  className="flex items-center no-underline w-full rounded-lg p-2 border border-transparent hover:border-[#a8e6c1] transition ease-linear my-2"
                  onClick={(e) => {
                    if (item.name === "Build a List") {
                      e.preventDefault();
                      window.location.href = "/dashboard";
                    }
                  }}
                >
                  <div className="flex items-center md:justify-start justify-center gap-2 w-full">
                    {item.icon}
                    <span className="text-[0.813rem] font-normal hidden md:block">
                      {item.name}
                    </span>
                  </div>
                </NavLink>
              ))}
              {isAdmin && (
                <div className=" w-full">
                  <h3 className="text-[#1D201D99] text-[0.813rem] font-bold md:hidden items-center lg:text-left text-center">
                    Data
                  </h3>
                  <h3 className="text-[#1D201D99] text-[0.813rem] font-bold hidden md:block">
                    Data
                  </h3>
                  <div
                    className="flex items-center no-underline w-full rounded-lg p-2 border border-transparent hover:border-[#a8e6c1] transition ease-linear my-2 cursor-pointer"
                    onClick={showModal}
                  >
                    <div className="flex items-center md:justify-start justify-center gap-2 w-full">
                      <Icons.deleteIcon />
                      <span className="text-[0.813rem] font-normal hidden md:block">
                        Delete Data
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Ant Design Modal for Delete Data */}
      <Modal
        visible={isModalVisible}
        footer={null}
        closeIcon={null}
        centered={true}
        // className="p-0 rounded-[8px] my-[50px]"
        onCancel={handleCancel}
      >
        <DeleteDataPopUp handleCancel={handleCancel} />
      </Modal>
    </div>
  );
};

export default Sidebar;
