import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "../../services/apiServices";

// Create an asynchronous thunk for sending request data
export const sendRequestData = createAsyncThunk(
  "request/sendRequestData",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await POST("/data-requests", {
        requestData, // Adjust the key based on your API requirements
      });
      return response.data; // Assuming the API returns the data you need
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a slice for managing request data state
const requestDataSlice = createSlice({
  name: "request",
  initialState: {
    loading: false,
    error: null,
    success: false,
    requestData: null, // Initialize requestData to null
  },
  reducers: {
    resetRequestState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.requestData = null; // Reset requestData as well
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendRequestData.pending, (state) => {
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(sendRequestData.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false when fulfilled
        state.success = true; // Set success to true
        state.requestData = action.payload; // Store the response data
      })
      .addCase(sendRequestData.rejected, (state, action) => {
        state.loading = false; // Set loading to false when rejected
        state.error = action.payload; // Store the error message
      });
  },
});

// Export the reset action for use in components
export const { resetRequestState } = requestDataSlice.actions;

// Export the reducer to be used in the store
export default requestDataSlice.reducer;
