import React, { useEffect } from "react";
import Sidebar from "../molecules/Sidebar";
import Header from "../molecules/Header";
import MainContent from "../molecules/MainContent";

const Dashboard = () => {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col flex-1 w-full min-w-0">
        <Header />
        <div className="flex md:flex-row flex-col flex-1">
          <MainContent />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
