import Icons from "../../assets/icons";
import { Button } from "../atoms/buttons";
import { useState } from "react"; // Import useState
import { useDispatch, useSelector } from "react-redux";
import { sendInvites } from "../../redux/slices/inviteSlice"; // Import the action
import toast from "react-hot-toast";

export default function InviteUsers() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.invite);
  const [emails, setEmails] = useState("");

  const handleSubmit = async () => {
    try {
      const emailArray = emails
        .split(/\s+/)
        .map((email) => email.trim())
        .filter((email) => email);

      if (emailArray.length === 0) {
        toast.error("Please provide at least one valid email.");
        return;
      }

      const result = await dispatch(sendInvites(emailArray));

      if (result.meta.requestStatus === "fulfilled") {
        toast.success("Invitations sent successfully!");
      } else if (result.meta.requestStatus === "rejected") {
        toast.error(result.payload?.message || "Failed to send invitations.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
      console.error("Error sending invites:", error);
    }
  };

  return (
    <div className="mx-auto">
      <h1 className="text-xl font-bold mb-6 text-[#1D201D]">Invite users</h1>
      <div className="shadow-card-shadow p-5 rounded-[4px]">
        <p className="text-xl font-bold text-[#313D31] mb-4">
          Bulk invite users by pasting emails, one per line.
        </p>
        <div className="flex md:flex-row flex-col gap-4">
          <textarea
            rows={12}
            value={emails} // Bind textarea value to state
            onChange={(e) => setEmails(e.target.value)} // Update state on change
            className="flex-1 h-full w-full border border-[#DAE7DE] px-4 py-1 rounded-[4px] placeholder:text-[#B1BFB5]"
            placeholder="Enter one email per line, e.g.&#10;john.doe@gmail.com&#10;mike.barron@gmail.com"
          />
          <div className="p-4 md:w-[463px] bg-green-50 border border-[#DAE7DE] rounded-[4px]">
            <div className="flex flex-col items-center gap-2 mb-4">
              <Icons.CompanyInvite />
              <h2 className="text-lg font-bold text-[#1D201D]">
                Inviting Users
              </h2>
            </div>
            <p className="text-sm text-center text-gray-600 mb-4">
              Hi there! Here, you can invite other users to your company. Some
              additional information:
            </p>
            <ul className="space-y-2">
              {[
                "Edit their permission levels once they join.",
                "Only users with your company's email domain can be invited.",
                "Contact us if you need more than 5 users.",
              ].map((text, i) => (
                <li key={i} className="flex items-start gap-1">
                  <Icons.GreenTick />
                  <span className="text-sm text-[#313D31]">{text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          className={`mt-6 ${
            loading ? "bg-gray-400" : "bg-lime-green hover:bg-green-600"
          } text-white`}
          onClick={handleSubmit} // Handle submit on button click
          disabled={loading} // Disable button when loading
        >
          {loading ? "Loading..." : "Create invite"} {/* Show loading text */}
        </Button>
      </div>
    </div>
  );
}
