import { Link, useParams } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { useEffect, useState } from "react";
import { GET } from "../../../services/apiServices";

export default function CustomPurchase() {
  const { id: customerId } = useParams();
  const [paymentLinks, setPaymentLinks] = useState([]);

  useEffect(() => {
    GET(`/customer/custom-payment-link/${customerId}`)
      .then((response) => {
        setPaymentLinks(response.data);
      })
      .catch((error) => {
        console.log("Error fetching discounts", error);
      });
  }, []);
  const headers = [
    "Existing custom purchase",
    "Price",
    "No. of credits",
    "Expires on",
  ];
  const data = paymentLinks.map((link, i) => {
    return [
      <a
        href={link.url}
        key={i}
        class="text-[#50c878] text-xs font-medium  underline leading-none"
        target="_blank"
      >
        View Link
      </a>,
      <div className="flex gap-1 items-center">
        <Icons.DollorIcon />
        <div className="text-[#313d31] text-xs font-normal"> ${link.price}</div>
      </div>,
      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {parseInt(link.metadata.credits)}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.CaleanderIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          {new Date(link.expires_at * 1000).toLocaleString()}
        </div>
      </div>,
    ];
  });

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} />
    </div>
  );
}
