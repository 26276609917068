import React, { useState } from "react";
import "./Slider.css";

const Slider = ({
  value,
  onValueChange,
  values,
  labels,
  isDisabled = false,
}) => {
  const [sliderValue, setSliderValue] = useState(value || values[0]);

  const handleSliderChange = (e) => {
    const index = parseInt(e.target.value, 10);
    setSliderValue(values[index]);
    if (onValueChange) onValueChange(values[index]);
  };

  const getSliderPercentage = () => {
    return (values.indexOf(sliderValue) / (values?.length - 1)) * 100;
  };

  return (
    <div className="w-full mx-auto mt-4">
      <div className="relative">
        {/* Slider */}
        <input
          type="range"
          min="0"
          max={values.length - 1}
          value={values.indexOf(sliderValue)}
          onChange={handleSliderChange}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          style={{
            background: `linear-gradient(to right, #50C878 ${getSliderPercentage()}%, #F4FBF6 0%)`, // Green progress bar
          }}
          disabled={isDisabled}
        />

        {/* Labels for the slider */}
        <div className="relative w-full flex justify-between">
          {values.map((val, idx) => (
            <div
              key={idx}
              className="absolute md:text-base text-xs font-normal text-[#313D31]"
              style={{
                left: `${(idx / (values.length - 1)) * 100}%`,
                transform: `translateX(${
                  idx === 0 ? "0" : idx === values.length - 1 ? "-100%" : "-50%"
                })`,
                // paddingLeft: idx === 0 ? "0px" : "0", // Padding left for the first index
                // paddingRight: idx === values.length - 1 ? "1rem" : "0",
              }}
            >
              <div className="text-center">
                <span>{val}</span>
                {labels[idx] && (
                  <div className="md:text-base text-xs font-normal text-[#313D31]">
                    ({labels[idx]})
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
