import React, { useState } from "react";
import { Button } from "../../atoms/buttons";
import { Input } from "../../atoms/input";
import StepHeading from "../../atoms/StepHeading";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "../../../redux/slices/onBoardingSlice";

const CompanyUrlStep = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const { website } = useSelector((state) => state.onBoarding);
  const [errors, setErrors] = useState({}); // To manage field errors

  // Handle input change and update Redux store
  const handleChange = (e) => {
    const { value } = e.target;
    dispatch(updateForm({ website: value }));

    // Clear the error when user starts typing
    setErrors((prev) => ({ ...prev, website: "" }));
  };

  // Validate the form before proceeding
  const validateForm = () => {
    const newErrors = {};
    if (!website.trim()) {
      newErrors.website = "Company URL is required.";
    } else if (!isValidUrl(website)) {
      newErrors.website = "Please enter a valid URL.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Helper function to check if the URL is valid
  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*\\/?$"
    );
    return urlPattern.test(url);
  };

  // Handle next step
  const handleNext = () => {
    if (validateForm()) {
      onNext(); // Proceed to next step only if valid
    }
  };

  return (
    <div className="">
      <StepHeading>Let's get your LimeLeads account setup</StepHeading>
      <div className="max-w-md">
        <label
          htmlFor="companyUrl"
          className="block text-[1rem] font-bold leading-[1.75rem] text-dark-green mb-0"
        >
          Company URL/website?
        </label>
        <Input
          type={"url"}
          id="companyUrl"
          placeholder="apple.com"
          className=""
          name="companyUrl"
          value={website}
          onChange={handleChange}
          error={errors.website} // Pass error to the Input component
        />
        <div className="flex items-center gap-3 mt-7">
          <Button
            className="bg-[#1D201D] text-white font-medium py-2 px-6"
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            className="bg-lime-green hover:bg-lime-green/90 text-white font-medium py-2 px-6"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyUrlStep;
