import React from "react";
import IntegrationSetting from "../components/organisms/integrationSetting/IntergrationSetting";

const Integration = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="px-6 py-6 w-full">
        <h3 className="font-bold text-2xl text-gray-800 mb-2">Integrations</h3>
        <p className="font-normal text-lg text-gray-600">
          LimeLeads integrates seamlessly with useful tools.
        </p>
      </div>
      
      <div className="flex items-center justify-center px-4 py-12">
        <div className="text-center p-8 bg-white rounded-lg shadow-md max-w-lg w-full">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            New Integrations Coming Soon
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            We're working on exciting new integrations to enhance your experience.
            Stay tuned for updates!
          </p>
        </div>
      </div>

      <div className="mt-7 px-6 pb-6">
        <IntegrationSetting />
      </div>
    </div>
  );
};

export default Integration;
