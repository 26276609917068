import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../services/apiServices";

// Create an async thunk for fetching credit history
export const fetchCreditHistory = createAsyncThunk(
  "fetchCreditHistory/fetchCreditHistory",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GET("/credit-history"); // Call the API for credit history
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

const getCreditHistorySlice = createSlice({
  name: "getCreditHistory",
  initialState: {
    creditHistory: [], // Ensure this is defined
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreditHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.creditHistory = action.payload;
      })
      .addCase(fetchCreditHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the action and reducer
export default getCreditHistorySlice.reducer;
