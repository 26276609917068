import Sidebar from "../molecules/Sidebar";
import Header from "../molecules/Header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Layout = ({ children, width }) => {
  const { userDetail } = useSelector((state) => state.userDetail);
  const navigate = useNavigate();
  const isCompanyPresent = userDetail?.data?.customer;
  console.log(
    "isCompanyPresent",
    isCompanyPresent && !isCompanyPresent?.company
  );

  useEffect(() => {
    if (isCompanyPresent && !isCompanyPresent?.company) {
      navigate("/onboard");
    }
  }, [isCompanyPresent, navigate]);
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-auto">
        <Header />
        <div className={`${width ? "w-full" : "flex flex-1"}`}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
