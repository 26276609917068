import { Link } from "react-router-dom";
import Icons from "../assets/icons";
import Table from "../components/molecules/Table";
import { Button } from "../components/atoms/buttons";

export default function GdprDetail() {
  const headers = ["Email", "Domain", "Nmae", "State"];
  const data = [
    [
      <div className="flex gap-1 items-center">
        <Icons.EmailWhite />
        <div className="text-[#313d31] text-xs font-normal"> Sam@gmail.com</div>
      </div>,
      <div className="flex items-center gap-1">
        <Icons.DomainIcon />
        <div className="text-[#313d31] text-xs font-normal">myphoner.com</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.UserIcon />
        <div className="text-[#313d31] text-xs font-normal">Sam Berg</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.BankIcon />
        <div className="text-[#313d31] text-xs font-normal"> Alaska</div>
      </div>,
    ],
  ];

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mt-5 mb-2 ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">Detail</h1>
      </div>
      <div className="">
        <div className="w-full max-w-md  bg-white shadow-md rounded-lg overflow-hidden">
          <div className="p-6">
            <dl className="space-y-4">
              {[
                { label: "Email", value: "jeppe@myphoner.com" },
                { label: "Domain", value: "myphoner.com" },
                { label: "Name", value: "Sam Berg" },
                { label: "State", value: "Alaska" },
              ].map(({ label, value }) => (
                <div key={label} className="flex justify-between">
                  <dt className="text-[#313d31] text-base font-medium  leading-normal">
                    {label}
                  </dt>
                  <dd className="text-right text-[#313d31] text-sm font-normal  leading-tight">
                    {value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
          Found similar leads (1)
        </h1>
      </div>
      <Table headers={headers} data={data} showChecked={true} length={1} />
      <div className="flex justify-end items-center mt-5 mb-2 w-full px-4 ">
        <div className="flex gap-5">
          <Button className="py-3 bg-[#50C878] hover:bg-green-600 text-white font-medium ">
            Back
          </Button>
          <Button className="py-3 bg-[#fb4747] hover:bg-green-600 text-white font-medium ">
            Invalidate selected
          </Button>
        </div>
      </div>
    </div>
  );
}
