import { useDispatch, useSelector } from "react-redux";
import CustomTable from "./Table";
import {
  fetchCompanyPendingInvites,
  resetCompanyPendingInvitesState,
} from "../../redux/slices/companyPendingInvitesSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import TableSkeleton from "./TableSkeleton";

export default function CompanyPendingInvites() {
  const dispatch = useDispatch();
  const { companyPendingInviteData, loading, error } = useSelector(
    (state) => state.companyPendingInvites
  ); // Access invite data state

  console.log(
    "companyPendingInviteData",
    companyPendingInviteData?.data?.invites
  );

  const companyInvitePendingData = companyPendingInviteData?.data?.invites;

  useEffect(() => {
    dispatch(fetchCompanyPendingInvites()); // Fetch invite data on component mount
    return () => {
      dispatch(resetCompanyPendingInvitesState()); // Reset state on unmount
    };
  }, [dispatch]);

  if (error) {
    return <div>Error: {error}</div>; // Error state
  }

  const headers = [
    "#",
    "Invitee email",
    "Created on",
    "Invite link (Please email or slack this to them)",
  ];
  const data =
    companyInvitePendingData &&
    companyInvitePendingData?.map((invites, index) => [
      index + 1,
      invites?.invitee_email,
      new Date(invites.createdAt).toLocaleDateString(),

      <Link
        to={`/signup/?slug=${invites?.slug}`}
      >{`https://app/limeleads.com/invite/${invites?.slug}`}</Link>,
    ]);

  return (
    <div className=" bg-[#fbfdfb] min-h-screen">
      <div className="">
        <div class="text-[#1d201d] text-xl font-bold my-3 leading-7">
          Your company’s pending invites
        </div>
      </div>
      {loading ? (
        <div className="px-4">
          <TableSkeleton rows={10} columns={6} header={false} />
        </div>
      ) : data?.length === 0 ? (
        <div className="flex justify-center items-center h-[50vh]">
          <p className="text-2xl text-gray-500">No data found</p>
        </div>
      ) : (
        <CustomTable headers={headers} data={data} length={data?.length} />
      )}
    </div>
  );
}
