import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "../../services/apiServices"; // Assume your POST method is defined here

// Async thunk to handle sending the transaction
export const submitCount = createAsyncThunk(
  "transaction/submitCount",
  async ({ customerId, amount, action }, { rejectWithValue }) => {
    try {
      // Making a POST request to send transaction data to the backend
      const response = await POST("customer/admin/add-deduct-credit", {
        customerId,
        amount,
        action,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginAsCustomer = createAsyncThunk(
  "login/asCustomer",
  async ({ customerId }, { rejectWithValue }) => {
    try {
      const response = await POST(
        `customer/admin/login-as-customer/${customerId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the transaction slice
const counterSlice = createSlice({
  name: "counter",
  initialState: {
    loading: false,
    error: null,
    success: false,
    customerData: null,
  },
  reducers: {
    // Reset the state
    resetTransactionState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsCustomer.fulfilled, (state, action) => {
        state.customerData = action.payload;
        state.status = "success";
      })
      .addCase(loginAsCustomer.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(submitCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitCount.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(submitCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reset action
export const { resetTransactionState } = counterSlice.actions;

// Export the reducer to be used in the store
export default counterSlice.reducer;
