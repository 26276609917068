import React from "react";
import RequestDataCard from "../../atoms/RquestDataCard";
import VideoCard from "../../molecules/VideoCard";

const RequestData = () => {
  const updates = [
    {
      description:
        "Couldn’t find relevant data for your business? We are here to help!",
    },
    // Add more updates as needed
  ];

  return (
    <div className="">
      <div className="flex items-center lg:flex-row flex-col gap-4 ">
        <RequestDataCard updates={updates} />
        <VideoCard />
      </div>
    </div>
  );
};

export default RequestData;
