// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  background-color: #22c55e; /* Green thumb */
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  background-color: #22c55e; /* Green thumb */
  height: 16px;
  width: 16px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Slider.css"],"names":[],"mappings":"AAAA;EAEE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,yBAAyB,EAAE,gBAAgB;EAC3C,kBAAkB;AACpB;;AAEA;EACE,yBAAyB,EAAE,gBAAgB;EAC3C,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB","sourcesContent":["input[type=\"range\"]::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  height: 16px;\n  width: 16px;\n  background-color: #22c55e; /* Green thumb */\n  border-radius: 50%;\n}\n\ninput[type=\"range\"]::-moz-range-thumb {\n  background-color: #22c55e; /* Green thumb */\n  height: 16px;\n  width: 16px;\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
