import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  createBilling,
  changeBillingSubscription,
  fetchBillingInfo,
  updateBillingSubscription,
} from "../../../redux/slices/billingSlice";
import Spinner from "../../atoms/Spinner";
import { Button } from "../../atoms/buttons";
import ConfirmationPopup from "../../molecules/ConfirmationPopup";

const Billings = () => {
  const dispatch = useDispatch();
  const [isYearly, setIsYearly] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectingPlan, setSelectingPlan] = useState(null);
  const [manageLoading, setManageLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const billingInfo = useSelector((state) => state.billing.billingInfo);
  const hasActivePlan = useSelector((state) => state.billing.hasActivePlan);
  const monthlyPlans = billingInfo.monthly || [];
  const yearlyPlans = billingInfo.yearly || [];

  useEffect(() => {
    const savedPlanType = localStorage.getItem("billingPlanType");
    if (savedPlanType === "yearly") {
      setIsYearly(true);
    }
    fetchBillingData();
  }, []);

  const fetchBillingData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        dispatch(fetchBillingInfo({ interval: "month" })),
        dispatch(fetchBillingInfo({ interval: "year" }))
      ]);
    } catch (error) {
      toast.error("Failed to fetch billing information.");
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    setIsYearly((prev) => {
      const newIsYearly = !prev;
      localStorage.setItem(
        "billingPlanType",
        newIsYearly ? "yearly" : "monthly"
      ); // Save selection
      return newIsYearly;
    });
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    if (hasActivePlan) {
      setShowConfirmation(true);
    } else {
      handleConfirmPlanChange(plan);
    }
  };

  const handleConfirmPlanChange = async (planToConfirm) => {
    const plan = planToConfirm || selectedPlan;
    setSelectingPlan(plan.priceId);
    setShowConfirmation(false);
    const postData = { priceId: plan.priceId };
    try {
      let result;
      if (hasActivePlan) {
        result = await dispatch(changeBillingSubscription(postData)).unwrap();
        window.location.reload();
      } else {
        result = await dispatch(createBilling(postData)).unwrap();
        if (result.data) {
          window.location.href = result.data;
        } else {
          throw new Error("No redirect URL provided");
        }
      }
    } catch (error) {
      console.error("Error handling billing action:", error);
      toast.error(
        error.message || "Failed to process billing action. Please try again."
      );
    } finally {
      setSelectingPlan(null);
    }
  };

  const handleChangeSubscription = async () => {
    setManageLoading(true);
    try {
      const result = await dispatch(updateBillingSubscription()).unwrap();
      console.log("Update Billing Subscription Result:", result);

      let redirectUrl;
      if (typeof result === 'string') {
        redirectUrl = result;
      } else if (result && result.data) {
        redirectUrl = result.data;
      } else if (result && result.url) {
        redirectUrl = result.url;
      }

      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        throw new Error("No valid redirect URL provided");
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
      toast.error("Failed to update subscription. Please try again.");
    } finally {
      setManageLoading(false);
    }
  };

  const currentPlans = isYearly ? yearlyPlans : monthlyPlans;

  // Filter plans to include only those with available features
  const plansWithFeatures = currentPlans.filter(plan => plan.features && plan.features.length > 0);

  // Sort filtered plans to ensure "Pro" is first, then "Business," then "Enterprise"
  const sortedPlans = [...plansWithFeatures].sort((a, b) => {
    const order = ["Pro", "Business", "Enterprise"];
    return order.indexOf(a.title) - order.indexOf(b.title);
  });

  return (
    <div className="">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-4xl font-bold">Billing</h2>
          {hasActivePlan && (
            <Button
              className="bg-green-500 text-white font-bold py-2 px-4 rounded"
              onClick={handleChangeSubscription}
              disabled={manageLoading}
            >
              {manageLoading ? <Spinner /> : "Manage Billing"}
            </Button>
          )}
        </div>

        <p className="mt-4 text-lg">Select the right plan for your business</p>

        {/* Centered Toggle Monthly/Yearly */}
        <div className="flex items-center justify-center mt-6">
          <span
            className={`text-lg ${!isYearly ? "font-bold" : "font-normal"}`}
          >
            Monthly
          </span>
          <label className="relative inline-flex items-center mx-3 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isYearly}
              onChange={handleToggle}
              disabled={loading}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
          </label>
          <span className={`text-lg ${isYearly ? "font-bold" : "font-normal"}`}>
            Yearly{" "}
            <span className="text-green-600 bg-[#F4F9F5] px-2 py-1 rounded text-sm">
              35% OFF
            </span>
          </span>
        </div>
      </div>

      {/* Pricing Plans */}
      <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6 max-w-7xl mx-auto">
        {loading ? (
          // Skeleton loader
          <>
            {[1, 2, 3].map((index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow p-6 animate-pulse"
              >
                <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
                <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
                <div className="h-10 bg-gray-200 rounded mb-4"></div>
                <div className="space-y-2">
                  {[1, 2, 3, 4].map((i) => (
                    <div
                      key={i}
                      className="h-4 bg-gray-200 rounded w-full"
                    ></div>
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          sortedPlans?.map((plan, index) => (
            <div
              key={index}
              className={`${plan.bgColor} rounded-lg shadow p-6 relative`}
            >
              {plan.isCurrentPlan && (
                <span
                  className={`bg-[#50C878] px-4 py-1 rounded-bl-lg absolute top-0 right-0 font-bold text-white`}
                >
                  Currently Active
                </span>
              )}
              <div className="mt-8">
                <div className="flex items-center justify-between">
                  <img
                    src={plan.image}
                    alt={plan.title}
                    className="w-[2.5rem]"
                  />
                  {plan.mostPopular && (
                    <div className="text-white font-bold bg-[#50C878] px-2 py-1 rounded">
                      {plan.mostPopular}
                    </div>
                  )}
                </div>
                <h3 className={`${plan.textColor} text-2xl font-bold mt-4`}>
                  {plan.title}
                </h3>
                <p className={`${plan.textColor} mt-1`}>{plan.subtitle}</p>
                <div className="flex items-center gap-2 mt-8">
                  <div className={`${plan.textColor} text-2xl font-bold`}>
                    ${plan.priceShow} <span> / </span>
                    <span className={plan.textColor}>month</span>
                  </div>
                </div>
                <button
                  className={`${plan.buttonColor} ${
                    plan.buttonTextColor
                  } py-2 px-4 rounded w-full mt-4 flex items-center justify-center ${
                    plan.isCurrentPlan ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => handleSelectPlan(plan)}
                  disabled={
                    selectingPlan === plan.priceId || plan.isCurrentPlan
                  }
                >
                  {selectingPlan === plan.priceId ? (
                    <Spinner size="small" color="white" />
                  ) : plan.isCurrentPlan ? (
                    "Current Plan"
                  ) : hasActivePlan ? (
                    "Update Plan"
                  ) : (
                    plan.buttonText
                  )}
                </button>
                <ul className="mt-4 space-y-2 text-sm">
                  {plan?.features?.map((feature, i) => (
                    <li key={i} className={`${plan.textColor} font-normal`}>
                      <div className="flex gap-2">
                        <img
                          src={plan.icon}
                          alt="Feature icon"
                          className="w-[1.25rem] h-[1.25rem]"
                        />
                        {feature}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))
        )}
      </div>

      {showConfirmation && selectedPlan && (
        <ConfirmationPopup
          plan={selectedPlan}
          onConfirm={() => handleConfirmPlanChange()}
          onCancel={() => setShowConfirmation(false)}
          isYearly={isYearly}
        />
      )}
    </div>
  );
};

export default Billings;
