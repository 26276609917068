import React from "react";
import Header from "../molecules/AuthHeader";
import Footer from "../molecules/Footer";

const LoginLayout = ({ children }) => {
  return (
    <>
      <div className="">
        <Header />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default LoginLayout;
