import React from "react";
import FormLabel from "../components/atoms/FormLabel";
import { Input } from "../components/atoms/input";
import { Button } from "../components/atoms/buttons";
import CustomSelect from "../components/atoms/CustomSelect";
import FileUploader from "../components/atoms/FileUploader";
import Icons from "../assets/icons";

const CreateCustomePurchase = () => {
  const headers = [
    { label: "ID", value: "id" },
    { label: "Created", value: "created" },
    { label: "Search", value: "search" },
    { label: "Title", value: "title" },
    { label: "Description", value: "description" },
    { label: "No. of lead IDs", value: "no._of_lead_ids" },
    { label: "Last synced", value: "last_synced" },
  ];

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
          Create custom purchase link
        </h1>
      </div>
      <form action="" className="max-w-4xl">
        <div className="flex gap-6 mt-4">
          <div className="w-full">
            <FormLabel htmlFor="fullName">Customer Email</FormLabel>
            <Input
              type="email"
              id="email"
              placeholder="zuyur@poly-swarm.com"
              className="mb-5 !w-full"
            />
          </div>
          <div className="w-full">
            <FormLabel htmlFor="Title">Price in dollar</FormLabel>
            <Input
              type="text"
              id="Title"
              placeholder="$480"
              className="mb-5 !w-full"
            />
          </div>
        </div>
        <div className="flex gap-6 my-2">
          <div className="w-full">
            <FormLabel htmlFor="Title">No. of credits</FormLabel>
            <Input
              type="text"
              id="Title"
              placeholder="900"
              className="mb-5 !w-full"
            />
          </div>
          <div className="w-full">
            <FormLabel htmlFor="Title">
              Expires in x daysExpires in x days
            </FormLabel>
            <Input
              type="text"
              id="Title"
              placeholder="3"
              className="mb-5 !w-full"
            />
          </div>
        </div>
        <div>
          <Button
            className="bg-green-500 hover:bg-green-600 text-xl font-medium text-white px-10 py-2 rounded-md mt-5"
            onClick={() => console.log("View clicked")}
          >
            Create custom purchase link
          </Button>
        </div>
        <div>
          <Button
            className="bg-transparent hover:bg-green-600 text-xl font-medium border border-lime-green text-lime-green px-10 py-2 rounded-md mt-5"
            onClick={() => console.log("View clicked")}
          >
            Create custom purchase link
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateCustomePurchase;
