import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../components/atoms/buttons";
import { Input } from "../components/atoms/input";
import Spinner from "../components/atoms/Spinner";
import { resetPassword } from "../redux/slices/authSlice";
import toast from "react-hot-toast";

export default function ResetPassword() {
  const [email, setEmail] = useState(""); // Manage email state
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const { loading, success } = useSelector((state) => state.auth);

  const handleInputChange = (name, value) => {
    if (name === "email") setEmail(value);
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!email.trim()) {
      newErrors.email = "This field is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "This field is invalid";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle the form submission for resetting the password
  const handleForgetPassword = async () => {
    toast.dismiss();
    // dispatch(resetPassword(email)); // Dispatch the resetPassword action
    if (validateForm()) {
      try {
        const result = dispatch(resetPassword(email)); // Dispatch the resetPassword action
        if (result.meta.requestStatus === "fulfilled") {
          toast.success(
            "A password reset link has been sent to your email address. Please check your inbox and follow the instructions to reset your password."
          );
          setEmail("");
        } else {
          toast.error("Login failed. Please check your credentials.");
        }
      } catch (err) {
        console.error("Login failed:", err);
      }
    }
  };

  return (
    <div className="min-h-[80vh] flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg w-full space-y-8">
        <div>
          <h1 className="text-start md:items-start text-[#1d201d] lg:text-6xl text-3xl font-bold leading-[68px] w-full">
            Reset password
          </h1>
          <p className="text-[#313d31] text-xl font-normal my-4 leading-7">
            Please enter your email.
          </p>
        </div>
        <div className="mt-8 space-y-4">
          <div className="flex flex-col items-start align-baseline w-full">
            <label htmlFor="email">Email address</label>
            <Input
              type="email"
              id="email"
              placeholder="name@gmail.com"
              className="w-full  text-[#313d31]"
              value={email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              error={error.email}
            />
          </div>

          <Button
            className="bg-[#50c878] w-full text-center text-white text-xl font-medium leading-7 rounded justify-center items-center my-4"
            onClick={handleForgetPassword}
            disabled={loading} // Disable button when loading
          >
            {loading ? <Spinner /> : "Email me a reset link!"}
          </Button>
        </div>
      </div>
    </div>
  );
}
