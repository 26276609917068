import React from "react";
import FormLabel from "../components/atoms/FormLabel";
import { Input } from "../components/atoms/input";
import { Button } from "../components/atoms/buttons";
import CustomSelect from "../components/atoms/CustomSelect";
import FileUploader from "../components/atoms/FileUploader";
import Icons from "../assets/icons";

const CreateDBSearch = () => {
  const headers = [
    { label: "ID", value: "id" },
    { label: "Created", value: "created" },
    { label: "Search", value: "search" },
    { label: "Title", value: "title" },
    { label: "Description", value: "description" },
    { label: "No. of lead IDs", value: "no._of_lead_ids" },
    { label: "Last synced", value: "last_synced" },
  ];

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
          Create Custom Database Searches
        </h1>
      </div>
      <form action="" className="max-w-4xl">
        <div className="flex gap-6 mt-4">
          <div className="w-full">
            <FormLabel htmlFor="fullName">List type</FormLabel>
            <Input
              type="text"
              id="fullName"
              placeholder="LimeLeads ID"
              className="mb-5 !w-full"
            />
          </div>
          <div className="w-full">
            <FormLabel htmlFor="Email address">Required Header</FormLabel>
            <CustomSelect
              className="mb-5 w-full"
              height={"3rem"}
              isMultiple={true}
              options={headers}
            />
          </div>
        </div>
        <div className="flex gap-6 my-2">
          <div className="w-full">
            <FormLabel htmlFor="Title">Title</FormLabel>
            <Input
              type="text"
              id="Title"
              placeholder="Type here..."
              className="mb-5 !w-full"
            />
          </div>
          <div className="w-full">
            <FormLabel htmlFor="upload">Upload CSV</FormLabel>
            <FileUploader />
          </div>
        </div>
        <div className="w-full">
          <FormLabel htmlFor="upload">Description</FormLabel>
          <textarea className="w-full border rounded-sm" rows={10} />
        </div>

        <div class="flex gap-1 my-2 ">
          <div className="mt-[0.3px]">
            <Icons.noteIcon />
          </div>
          <div>
            <span className="text-[#1d201d] text-sm font-bold  leading-tight">
              NOTE: &nbsp;
            </span>
            <span className="text-[#313d31] text-sm font-normal  leading-tight">
              {" "}
              When creating, the platform will process the work in the
              background. You'll have to refresh until the list appears and the
              "last synced" column gets updated. For lists with 1M+ results this
              can take up to 10 minutes.
            </span>
          </div>
        </div>

        <Button
          className="bg-[#50c878] text-white py-3 px-8 mt-4"
          type="submit"
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default CreateDBSearch;
