import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../components/atoms/buttons";
import { Input } from "../components/atoms/input";
import Spinner from "../components/atoms/Spinner";
import toast from "react-hot-toast";
import { createPassword } from "../redux/slices/authSlice";

export default function CreatePassword() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  // const token = searchParams.get("token")?.replace(/\s/g, "+");
  const token = searchParams.get("password-token")?.replace(/\s/g, "+");
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({});

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError((prev) => ({ ...prev, [name]: "" })); // Clear error when user types
  };

  const validateForm = () => {
    const newErrors = {};
    const { newPassword, confirmPassword } = formData;

    if (!newPassword.trim()) {
      newErrors.newPassword = "This field is required.";
    } else if (newPassword.length < 6) {
      newErrors.newPassword = "Password must be at least 6 characters long.";
    }

    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = "This field is required.";
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (validateForm() && token) {
      try {
        const result = await dispatch(
          createPassword({ reset_token: token, password: formData.newPassword })
        ).unwrap();
        toast.success(
          "Your password has been successfully reset. You can now log in with your new password."
        );
        navigate("/");
      } catch {
        toast.error("Failed to reset password. Please try again.");
      }
    } else {
    }
  };

  return (
    <div className="min-h-[80vh] flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8">
        <h1 className="text-start text-[#1d201d] lg:text-6xl text-3xl font-bold">
          Create password
        </h1>
        <p className="text-[#313d31] text-xl">
          Almost done. Enter your new password and you’re good to go.
        </p>
        <form className="mt-8 space-y-4" onSubmit={handleResetPassword}>
          <div className="flex flex-col">
            <label htmlFor="new-password">New Password</label>
            <Input
              type="password"
              id="new-password"
              name="newPassword"
              placeholder="Enter new password"
              value={formData.newPassword}
              onChange={(e) => handleChange("newPassword", e.target.value)}
              error={error.newPassword}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="confirm-password">Confirm Password</label>
            <Input
              type="password"
              id="confirm-password"
              name="confirmPassword"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={(e) => handleChange("confirmPassword", e.target.value)}
              error={error.confirmPassword}
            />
          </div>
          <Button
            className="bg-[#50c878] w-full text-white text-xl font-medium rounded my-4"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Create password"}
          </Button>
        </form>
      </div>
    </div>
  );
}
