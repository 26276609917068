import React from "react";
import Sidebar from "../molecules/Sidebar";
import Header from "../molecules/Header";

const AdminLayout = ({ children, width }) => {
  return (
    <div className="flex min-h-screen">
      <Sidebar isAdmin={true} />
      <div className="flex flex-col flex-1 overflow-auto">
        <Header />
        <div className={`${width ? "w-full" : "flex flex-1"}`}>{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;
