import { Link, useParams } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { Button } from "../../atoms/buttons";
import { useEffect, useState } from "react";
import { GET } from "../../../services/apiServices";

export default function Discount() {
  // const { id: customerId } = useParams();
  // const [discounts, setDiscounts] = useState([]);

  // useEffect(() => {
  //   GET(`/customer/discounts/${customerId}`)
  //     .then((response) => {
  //       setDiscounts(response.data);
  //     })
  //     .catch((error) => {
  //       console.log("Error fetching discounts", error);
  //     });
  // }, []);
  // console.log(
  //   "discounts",
  //   discounts.map((discount) => {
  //     return [];
  //   })
  // );
  // const headers = ["Discount code", "Name", "Discount %"];
  // const data = discounts.map((discount) => {
  //   return [
  //     <div
  //       className="flex w-[25%] items-center gap-1"
  //       key={discount?.couponDetails?.id}
  //     >
  //       <Icons.codeIcon />
  //       <div className="text-[#313d31] text-xs font-normal">
  //         {discount?.couponDetails?.id}
  //       </div>
  //     </div>,
  //     <div className="flex gap-1 items-center">
  //       <div className="text-[#313d31] text-xs font-normal">
  //         {discount?.couponDetails?.name}
  //       </div>
  //     </div>,
  //     <div className="flex gap-1 items-center">
  //       <Icons.discountIcon />
  //       <div className="text-[#313d31] text-xs font-normal">
  //         {discount?.couponDetails?.percent_off}
  //       </div>
  //     </div>,
  //   ];
  // });

  // return (
  //   <div className="w-full ">
  //     <Table headers={headers} data={data} length={false} />
  //   </div>
  // );

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb]">
      <div className="flex flex-col justify-center items-center h-[60vh]">
        <div className="mb-4">
          <Icons.CompanyInvite />
        </div>
        <p className="text-lg text-[#313D31] text-center mb-6 px-[18rem]">
          This feature is temporarily disabled as we are in the process of
          migrating our payment system to Paddle. Once the migration is
          complete, you'll be able to view detailed discount and coupon
          information here. We appreciate your patience during this transition.
        </p>
      </div>
    </div>
  );
}
