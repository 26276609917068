import React, { useState } from "react";
import { Button } from "../atoms/buttons";
import Spinner from "../atoms/Spinner";

const ExportPopup = ({
  onClose,
  onExport,
  searchParams,
  isLoading,
  unpurchasedContacts,
  selectedIds,
}) => {
  const [formValues, setFormValues] = useState({
    fileName: "",
    numberOfContacts: "",
    contactsPerCompany: "one",
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};

    // Only validate numberOfContacts and contactsPerCompany if no IDs are selected
    if (selectedIds.length === 0) {
      if (
        !formValues.numberOfContacts ||
        parseInt(formValues.numberOfContacts, 10) <= 0
      ) {
        errors.numberOfContacts = "Please enter a valid number of contacts.";
      }

      if (!formValues.contactsPerCompany) {
        errors.contactsPerCompany = "Please select contacts per company.";
      }
    }

    if (!formValues.fileName.trim()) {
      errors.fileName = "Filename cannot be empty.";
    }

    setErrors(errors);

    // If the errors object is empty, the form is valid
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const numericValue = value.replace(/[^0-9]/g, "");

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: name === "numberOfContacts" ? numericValue : value,
    }));

    // Remove the error for the field being updated
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  const handleExport = () => {
    if (!validate()) {
      return; // Prevent export if validation fails
    }

    const exportData = {
      searchParams: searchParams,
      exportParams: {
        contactsPerCompany: formValues.contactsPerCompany,
        numContactsToExport: parseInt(formValues.numberOfContacts, 10),
        filename: formValues.fileName,
      },
    };

    onExport(exportData);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg w-96">
        {unpurchasedContacts !== undefined && (
          <div className="mb-4 text-center text-black font-normal">
            There are{" "}
            <span className="font-bold">
              {unpurchasedContacts.toLocaleString()}
            </span>{" "}
            net new contacts you have not previously exported.
          </div>
        )}

        <h2 className="text-xl font-bold mb-4">Export Contacts</h2>

        <div className="mb-4">
          <label className="block mb-2">Filename</label>
          <input
            type="text"
            name="fileName"
            value={formValues.fileName}
            onChange={handleInputChange}
            className={`w-full p-2 border rounded ${
              errors.fileName ? "border-red-500" : ""
            }`}
            placeholder="limeleads-export"
          />
          {errors.fileName && (
            <p className="text-red-500 text-sm mt-1">{errors.fileName}</p>
          )}
        </div>

        {/* Number of Contacts Field */}
        {selectedIds.length === 0 && (
          <div>
            <div className="mb-4">
              <label className="block mb-2">Number of contacts</label>
              <input
                type="text"
                name="numberOfContacts"
                value={formValues.numberOfContacts}
                onChange={handleInputChange}
                className={`w-full p-2 border rounded ${
                  errors.numberOfContacts ? "border-red-500" : ""
                }`}
              />
              {errors.numberOfContacts && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.numberOfContacts}
                </p>
              )}
            </div>

            {/* Contacts per Company Field */}
            <div className="mb-4">
              <label className="block mb-2">Contacts per company</label>
              <div>
                <label className="inline-flex items-center mr-4">
                  <input
                    type="radio"
                    name="contactsPerCompany"
                    value="one"
                    checked={formValues.contactsPerCompany === "one"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  One contact
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="contactsPerCompany"
                    value="many"
                    checked={formValues.contactsPerCompany === "many"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Multiple contacts
                </label>
              </div>
            </div>
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex justify-end">
          <Button onClick={onClose} className="mr-2 bg-gray-300 text-black">
            Cancel
          </Button>
          <Button
            className="bg-green-500 text-white disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading || unpurchasedContacts < 1}
            onClick={handleExport}
          >
            {isLoading ? <Spinner /> : "Export"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExportPopup;
