import { Link } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";

export default function CustomPurchase() {
  const headers = [
    "Existing custom purchase",
    "Price",
    "No. of credits",
    "Expires on",
    "No. of times purchased",
  ];
  const data = [
    [
      <a
        href="http://app.limeleads.com/custom-purchase/5e6955ac"
        class="text-[#50c878] text-xs font-medium  underline leading-none"
      >
        http://app.limeleads.com/custom-purchase/5e6955ac
      </a>,
      <div className="flex gap-1 items-center">
        <Icons.DollorIcon />
        <div className="text-[#313d31] text-xs font-normal"> $480.00</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.DollorIcon />
        <div className="text-[#313d31] text-xs font-normal">900</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.CaleanderIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          April 20, 2024, 11:59 AM UTC
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.cartIcon />
        <div className="text-[#313d31] text-xs font-normal"> 0</div>
      </div>,
    ],
  ];

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} length={1} />
    </div>
  );
}
