import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../services/apiServices";

// Define the initial state
const initialState = {
  stats: {
    newSignups: 0,
    numLogins: 0,
    numDbV2Orders: 0,
  },
  loading: false,
  error: null,
};

export const fetchCustomerStats = createAsyncThunk(
  "customerStats/fetchCustomerStats",
  async ({ duration }, { rejectWithValue }) => {
    try {
      const response = await GET(`/customer/stats?time=${duration}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const customerStatsSlice = createSlice({
  name: "customerStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload;
      })
      .addCase(fetchCustomerStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default customerStatsSlice.reducer;
