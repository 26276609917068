import { Link } from "react-router-dom";
import Icons from "../assets/icons";
import Table from "../components/molecules/Table";

export default function CustomDBSearches() {
  const headers = [
    "#",
    "Created",
    "Search",
    "Title",
    "Description",
    "No. of lead IDs",
    "Last synced",
  ];
  const data = [
    [
      236,
      <div className="flex gap-1 items-center">
        <Icons.CaleanderIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          August 23, 2024, 7:00 PM
        </div>
      </div>,
      <div className="flex items-center gap-1 text-[#50c878]">
        <Icons.SearchIcon size="16" />
        <div className="text-[#313d31] text-xs font-normal">06ec9454fdb6</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.TitleIcon />
        <div className="text-[#313d31] text-xs font-normal">Jeremy 2</div>
      </div>,

      <div className="flex gap-1 items-center">
        <Icons.DescriptionIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          Start up consultant competitors
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.IdIcon />
        <div className="text-[#313d31] text-xs font-normal"> 15394</div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.syncedIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          August 23, 2024, 7:00 PM
        </div>
      </div>,
    ],
  ];

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mt-5 mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
          View Custom Database Searches
        </h1>
      </div>
      <Table headers={headers} data={data} />
    </div>
  );
}
