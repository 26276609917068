import React from "react";
import Icons from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Intercom_APP_ID } from "../organisms/intercom/Intercom";

const Stepsidebar = ({ currentStep, steps }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetail, loading: userDetailLoading } = useSelector(
    (state) => state.userDetail
  );

  const email = userDetail?.data?.customer?.email;

  const handleLogout = (e) => {
    e.stopPropagation();
    toast.dismiss(); // Dismiss any previous toasts
    localStorage.clear(); // Clear all local storage
    dispatch(logout());
    toast.success("Successfully logged out!"); // Show toast message
    window.intercomSettings = {
      app_id: Intercom_APP_ID,
    };
    navigate("/"); // Redirect to login page
  };

  return (
    <div className="md:w-80 w-full bg-lime-sidebar p-8 flex flex-col justify-between min-h-screen">
      {/* Top Section */}
      <div>
        <div className="flex items-center mb-12">
          <Icons.Logo />
        </div>
        <nav className="relative">
          {/* Main vertical line */}
          <div className="absolute left-4 top-4 bottom-4 w-px bg-light-green" />
          {steps.map((step, index) => (
            <div key={step.number} className="flex items-center mb-8 relative">
              {index !== 0 && (
                <div
                  className={`absolute left-4 -top-8 bottom-8 w-[2px] ${
                    index <= currentStep ? "bg-lime-green" : "bg-light-green"
                  }`}
                />
              )}
              {/* Circle for step number */}
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center mr-4 z-10 text-sm font-medium ${
                  index < currentStep
                    ? "bg-lime-green text-white"
                    : index === currentStep
                    ? "bg-lime-green text-white"
                    : "bg-white border border-light-green text-light-green"
                }`}
              >
                {index < currentStep ? <Icons.Checkmark /> : step.number}
              </div>
              {/* Step label */}
              <span
                className={`text-sm ${
                  index == currentStep
                    ? "text-dark-green font-bold"
                    : "font-normal text-dark-green"
                }`}
              >
                {step.text}
              </span>
            </div>
          ))}
        </nav>
      </div>

      {/* Bottom Section for Email and Logout */}
      <div className="flex flex-col items-start mt-10">
        <p className="text-sm text-dark-green mb-2">{email}</p>

        <button
          className="flex items-center gap-2 text-dark-green text-sm font-normal no-underline"
          onClick={handleLogout}
        >
          <Icons.LogOut />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Stepsidebar;
