import React, { useState } from "react";

export const Button = (props) => {
  return (
    <button
      {...props}
      className={`rounded-md px-4 py-2 flex justify-center items-center ${props.className}`}
      disabled={props.loading || props.disabled}
    >
      {props.loading ? <div className="spinner"></div> : props.children}
    </button>
  );
};
