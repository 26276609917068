import React from "react";

const StepHeading = ({ children, className = "" }) => {
  return (
    <h2
      className={`text-[2rem] font-bold leading-[2.5rem] text-[#1D201D] mb-6 ${className}`}
    >
      {children}
    </h2>
  );
};

export default StepHeading;
