import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/apiServices";
import { toast } from "react-hot-toast";

// Async thunk for login
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await POST("customer/login", credentials);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Thunk for signing up a user
export const signup = createAsyncThunk(
  "auth/signup",
  async (formData, thunkAPI) => {
    try {
      const response = await POST("customer/signup", formData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Thunk to fetch company data
export const fetchCompanyData = createAsyncThunk(
  "auth/fetchCompanyData",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await GET(`invite/${companyId}`);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Thunk to handle reset password API call
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await POST("/customer/forgot-password", { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk to handle create new password
export const createPassword = createAsyncThunk(
  "auth/createPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await POST("/customer/reset-password", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to handle password update
export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ currentPassword, newPassword }, { rejectWithValue }) => {
    try {
      const response = await POST("/customer/change-password", {
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data || "Failed to update password"
      );
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null,
    companyData: null,
  },
  reducers: {
    logout: (state) => {
      state.data = null;
      state.success = false;
    },
    resetState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login cases
      .addCase(login.pending, (state) => {
        // state.loading = true;
        // state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Login failed";
      })
      // Signup cases
      .addCase(signup.pending, (state) => {
        // state.loading = true;
        // state.error = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = "Signup failed";
      })
      // Fetch company data cases
      .addCase(fetchCompanyData.fulfilled, (state, action) => {
        state.companyData = action.payload;
      })
      .addCase(fetchCompanyData.rejected, (state, action) => {
        console.error(action.error.message);
      })
      // Reset password cases
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        toast.success(
          "A password reset link has been sent to your email address. Please check your inbox and follow the instructions to reset your password."
        );
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // toast.error("Failed to reset password.");
      })
      // Create password cases
      .addCase(createPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // toast.error("Failed to reset password.");
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions and the reducer
export const { logout, resetState } = authSlice.actions;
export default authSlice.reducer;
