import { Link } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { Button } from "../../atoms/buttons";

export default function Discount() {
  const headers = [
    "Discount code",
    "Monthly discount %",
    "On demand discount %",
    "Remove",
  ];
  const data = [
    [
      <div className="flex w-[25%] items-center gap-1">
        <Icons.codeIcon />
        <div className="text-[#313d31] text-xs font-normal"></div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.discountIcon />
        <div className="text-[#313d31] text-xs font-normal"> 0 %</div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.discountIcon />
        <div className="text-[#313d31] text-xs font-normal"> 0 %</div>
      </div>,
      <Button
        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
        onClick={() => console.log("Delete clicked")}
      >
        Delete
      </Button>,
    ],
  ];

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} length={1} />
    </div>
  );
}
