import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET, PATCH } from "../../services/apiServices";

// Define the initial state
const initialState = {
  customers: [],
  customerDetails: null,
  fraudStatus: false,
  loading: false,
  error: null,
};

// Create an async thunk for fetching customers
export const fetchAllCustomers = createAsyncThunk(
  "customers/fetchAll",
  async ({ page, limit, search, isPaying }, { rejectWithValue }) => {
    try {
      const searchParam = search ? `&search=${search}` : "";
      const isPayingParam = isPaying ? `&isPaying=${isPaying}` : "";
      const response = await GET(
        `/customer/all?page=${page}&limit=${limit}${searchParam}${isPayingParam}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Assuming you're already importing necessary dependencies
export const markAsFraudulent = createAsyncThunk(
  "customers/markAsFraudulent",
  async ({ fraud_status, customerId }, { rejectWithValue }) => {
    try {
      const response = await PATCH(
        `/customer/admin/fraud-status/${customerId}`, // Ensure the URL is correct
        { is_fraudulent: fraud_status } // Send the fraud status as a boolean under the correct key
      );
      return { fraud_status, customerId }; // Return the new fraud status and customer ID
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

// Create an async thunk for fetching customer details by ID
export const fetchCustomerDetails = createAsyncThunk(
  "customers/fetchDetails",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await GET(`/customer/details/${customerId}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const getAllCustomersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setFraudStatus: (state, action) => {
      state.fraudStatus = action.payload; // Update the fraud status directly
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload;
      })
      .addCase(fetchAllCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Handling fetch customer details
      .addCase(fetchCustomerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.customerDetails = null;
      })
      .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.customerDetails = action.payload;
      })
      .addCase(fetchCustomerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(markAsFraudulent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markAsFraudulent.fulfilled, (state, action) => {
        state.loading = false;
        state.fraudStatus = action.payload; // Update fraud status
      })
      .addCase(markAsFraudulent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the actions and reducer
export const { setFraudStatus } = getAllCustomersSlice.actions;
export default getAllCustomersSlice.reducer;
