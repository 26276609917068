import { useDispatch, useSelector } from "react-redux";
import { markAsFraudulent } from "../../../redux/slices/getAllCustomersSlice";
import { Button } from "../../atoms/buttons";
import { useParams } from "react-router-dom";

export default function Faudelnts() {
  const dispatch = useDispatch();
  const { fraudStatus, loading } = useSelector((state) => state.customers); // Assuming you want to read the fraud status from the store

  const { id: customerId } = useParams(); // Get the customerId from the route

  const handleMarkAsFraudulent = () => {
    dispatch(markAsFraudulent({ fraud_status: true, customerId: customerId })); // Replace 1 with the actual customer ID
  };

  const handleMarkAsNotFraudulent = () => {
    dispatch(markAsFraudulent({ fraud_status: false, customerId: customerId })); // Replace 1 with the actual customer ID
  };

  return (
    <div className="w-full ">
      <h3 className="font-bold text-xl text-text-color mb-2">
        Has this user been marked as fraudulent:{" "}
        {loading ? "Loading..." : String(fraudStatus?.fraud_status || false)}
      </h3>
      <div className="my-4">
        <Button
          className="bg-red-500 hover:bg-red-600 text-white w-[275px] py-2 rounded-md"
          onClick={handleMarkAsFraudulent}
        >
          Mark as fraudulent
        </Button>
      </div>
      <div>
        <Button
          className="bg-lime-green hover:opacity-90 text-white w-[275px] py-2 rounded-md"
          onClick={handleMarkAsNotFraudulent}
        >
          Mark as not fraudulent
        </Button>
      </div>
    </div>
  );
}
