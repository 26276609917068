import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "../../services/apiServices";

// Create an async thunk for updating the form
export const updateFormData = createAsyncThunk(
  "onBoarding/updateFormData",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await POST("/customer/onboard", formData); // Call the API
      console.log("response", response);
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

const onBoardingSlice = createSlice({
  name: "onBoarding",
  initialState: {
    name: "",
    companyName: "",
    website: "",
    numEmployees: "",
    position: "",
    achievementGoal: "",
    otherGoalText: "", // Added new field
    heardFrom: "",
    loading: false,
    error: null,
  },
  reducers: {
    // Your existing reducers
    updateForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateFormData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFormData.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful onboarding
      })
      .addCase(updateFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      });
  },
});

// Export the action and reducer
export const { updateForm } = onBoardingSlice.actions;
export default onBoardingSlice.reducer;
