import React, { useState } from "react";
import { Button } from "../../atoms/buttons";
import { Input } from "../../atoms/input";
import FormLabel from "../../atoms/FormLabel";
import StepHeading from "../../atoms/StepHeading";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "../../../redux/slices/onBoardingSlice";

const NameStep = ({ onNext, onBack, currentStep }) => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.onBoarding);
  const [errors, setErrors] = useState({}); // To manage field errors

  // Handle input change and update Redux store
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateForm({ [name]: value }));
    // Clear the error when user starts typing
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // Validate the form before proceeding
  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Full name is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle next step
  const handleNext = () => {
    if (validateForm()) {
      onNext(); // Proceed to next step only if valid
    } else {
    }
  };

  return (
    <div>
      <StepHeading>Let's get your LimeLeads account setup</StepHeading>
      <div className="max-w-md">
        <FormLabel htmlFor="name">Full name</FormLabel>
        <Input
          type="text"
          id="name"
          name="name"
          placeholder="John Mac"
          className=""
          value={name}
          onChange={handleChange}
          error={errors.name} // Pass error to the input field
          required
        />
        <div className="flex items-center">
          {currentStep > 0 && (
            <Button
              className="bg-white border border-lime-green text-lime-green font-medium py-2 px-6"
              onClick={onBack}
            >
              Back
            </Button>
          )}
          <Button
            className="bg-lime-green hover:bg-lime-green/90 text-white font-medium py-2 px-6 mt-7"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NameStep;
