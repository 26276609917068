import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../atoms/buttons";
import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { fetchCustomerDetails } from "../../../redux/slices/getAllCustomersSlice";

export default function SearchHistory() {
  const navigate = useNavigate();
  const { id: customerId } = useParams(); // Get the customerId from the roue

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCustomerDetails(customerId));
  }, []);

  const { customerDetails, loading, error } = useSelector(
    (state) => state.customers
  );

  console.log("searchHistory", customerDetails?.data?.customer?.searches ?? []);
  const headers = ["Search Key", "Searched At", "View Search"];
  const data = customerDetails?.data?.customer?.searches
    ? customerDetails?.data?.customer?.searches.map((search) => {
        return [
          <div className="flex w-[25%] items-center gap-1">
            <Icons.codeIcon />
            <div className="text-[#313d31] text-xs font-normal">
              {search.searchKey}
            </div>
          </div>,
          <div className="flex gap-1 items-center">
            <div className="text-[#313d31] text-xs font-normal">
              {new Date(search.searchedAt).toDateString()}
            </div>
          </div>,
          <Button
            className="px-3 py-1 bg-lime-green hover:opacity-90 rounded text-white text-xs font-medium h-7"
            onClick={() => {
              navigate(`/database?search=${search.searchKey}`);
            }}
          >
            View Search
          </Button>,
        ];
      })
    : [];

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} length={false} />
    </div>
  );
}
