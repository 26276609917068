import React from "react";

const Checkbox = ({ checked, onChange, id, className }) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className={`${className} custom-checkbox`}
      id={id}
    />
  );
};

export default Checkbox;
